import { LoadingButton } from '@mui/lab'
import { Box, Button, Dialog, Typography } from '@mui/material'
import { getIdToken } from 'firebase/auth'
import { CustomerSegmentService } from 'gen/proto/customer_segment/customer_segment_connectweb'
import { useAccount } from 'hooks/useAccount'
import { useAuthUser } from 'hooks/useAuthUser'
import { useCustomSnackbar } from 'hooks/useCustomSnackbar'
import { useGrpcClient } from 'hooks/useGrpcClient'
import { useSentryNotifier } from 'hooks/useSentryNotifier'
import { FC, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { getImagePath } from 'utils/imageUtil'

type Props = {
  open: boolean
  handleClose: () => void
  customerSegmentId: string
  tagName: string
  estimatedProcessingDurationInMinutes: number
}

export const DeactivateAutoSyncDialog: FC<Props> = ({ open, handleClose, customerSegmentId, tagName, estimatedProcessingDurationInMinutes }) => {
  const { t } = useTranslation()
  const authUser = useAuthUser()
  const { enqueueSnackbar } = useCustomSnackbar()
  const { notifySentry } = useSentryNotifier()
  const { shop } = useAccount()
  const customerSegmentService = useGrpcClient(CustomerSegmentService)
  const [isSubmitting, setIsSubmitting] = useState(false)

  const updateTagOperationSetting = async () => {
    setIsSubmitting(true)
    try {
      const token = await getIdToken(authUser!)
      await customerSegmentService.updateTagOperationSetting(
        {
          customerSegmentId: customerSegmentId,
          isActive: false,
          tagName: '',
          enableShopifySegmentCreation: false,
        },
        { headers: { Authorization: `Bearer ${token}` } }
      )
      enqueueSnackbar(t('features.customerSegments.detail.deactivateAutoSyncDialog.messageSaved'), { severity: 'success' })
      handleClose()
    } catch (err) {
      enqueueSnackbar(t('features.customerSegments.detail.deactivateAutoSyncDialog.messageFailed'), { severity: 'error' })
      notifySentry(err)
    } finally {
      setIsSubmitting(false)
    }
  }

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      maxWidth='xs'
      fullWidth
      PaperProps={{
        sx: { borderRadius: '8px' },
      }}
    >
      <Box padding='24px 24px 20px'>
        <Box display='flex' alignItems='center' marginBottom='16px'>
          <img src={getImagePath('shopify_logo.svg')} alt='Shopify Logo' width='20px' />
          <Typography fontSize='18px' marginLeft='6px'>
            {t('features.customerSegments.detail.deactivateAutoSyncDialog.title')}
          </Typography>
        </Box>
        <Typography fontSize='14px' color='text.secondary' marginBottom='20px'>
          {t('features.customerSegments.detail.deactivateAutoSyncDialog.description', {
            tagName: tagName,
            estimatedProcessingDurationInMinutes: estimatedProcessingDurationInMinutes,
          })}
        </Typography>
        <Box display='flex' justifyContent='flex-end' gap='12px'>
          <Button disabled={isSubmitting} onClick={handleClose} variant='outlined'>
            {t('features.customerSegments.detail.deactivateAutoSyncDialog.cancel')}
          </Button>
          <LoadingButton
            type='submit'
            disabled={shop.isDemo || isSubmitting} // Note: Demo accounts cannot remove tags
            loading={isSubmitting}
            loadingPosition='center'
            onClick={updateTagOperationSetting}
            variant='contained'
          >
            {t('features.customerSegments.detail.deactivateAutoSyncDialog.submit')}
          </LoadingButton>
        </Box>
      </Box>
    </Dialog>
  )
}
