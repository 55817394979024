import EditRoundedIcon from '@mui/icons-material/EditRounded'
import { Box, Button, Grid, Paper, Skeleton, Tooltip, Typography } from '@mui/material'
import { GuideIcon } from 'components/guideIcon'
import { CustomerSegmentState } from 'features/customerSegments/types/types'
import { FC, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { getImagePath } from 'utils/imageUtil'
import { formatDateTime, timestampToDayjs } from 'utils/timeUtil'
import { ActivateAutoSyncDialog } from './components/activateAutoSyncDialog'
import { DeactivateAutoSyncDialog } from './components/deactivateAutoSyncDialog'

type Props = {
  customerSegment: CustomerSegmentState | undefined
  currentTagRunning: boolean
  otherTagsRunning: boolean
}

export const Sync: FC<Props> = ({ customerSegment, currentTagRunning, otherTagsRunning }) => {
  const { t, i18n } = useTranslation()

  const [activateDialogOpen, setActivateDialogOpen] = useState(false)
  const [deactivateDialogOpen, setDeactivateDialogOpen] = useState(false)

  const tagProcessTimePerCustomerInMinutes = 18 / 50 / 60
  const estimatedProcessingDurationInMinutes = customerSegment?.metrics?.count
    ? Math.ceil(customerSegment.metrics.count * tagProcessTimePerCustomerInMinutes) + 3
    : 5 // 50 customers per 18 seconds plus 3 min for initializing gcp batch

  return (
    <>
      <Paper variant='outlined' sx={{ padding: '32px', borderRadius: '12px' }}>
        <Box display='flex' alignItems='center' marginBottom='20px'>
          <img src={getImagePath('shopify_logo.svg')} alt='shopify-logo' width='20px' />
          <Typography fontSize='16px' fontWeight='bold' marginLeft='6px'>
            {t('features.customerSegments.detail.sync.title')}
          </Typography>
          <GuideIcon guideType='SegmentSync' />
        </Box>

        <Box paddingX='8px'>
          {customerSegment && customerSegment.tagOperationSetting ? (
            <Grid container spacing={1} sx={{ fontSize: '14px' }}>
              <Grid item xs={1.5}>
                {t('features.customerSegments.detail.sync.status')}
              </Grid>
              <Grid item xs={10.5}>
                <Box display='flex' alignItems='center'>
                  <Typography fontSize='14px' component='div'>
                    {currentTagRunning
                      ? t('features.customerSegments.detail.sync.running', { duration: estimatedProcessingDurationInMinutes })
                      : customerSegment.tagOperationSetting.isActive
                      ? t('features.customerSegments.detail.sync.active')
                      : t('features.customerSegments.detail.sync.inactive')}
                  </Typography>
                  <Tooltip
                    title={
                      currentTagRunning
                        ? t('features.customerSegments.detail.sync.currentTagRunning')
                        : otherTagsRunning
                        ? t('features.customerSegments.detail.sync.otherTagsRunning')
                        : t('features.customerSegments.detail.sync.edit')
                    }
                    placement='right'
                  >
                    <Box display='flex' alignItems='center'>
                      <Button
                        disabled={currentTagRunning || otherTagsRunning}
                        onClick={() => (customerSegment?.tagOperationSetting?.isActive ? setDeactivateDialogOpen(true) : setActivateDialogOpen(true))}
                        sx={{ cursor: 'pointer', marginLeft: '8px', minWidth: 'auto', padding: 0 }}
                      >
                        <EditRoundedIcon fontSize='inherit' />
                      </Button>
                    </Box>
                  </Tooltip>
                </Box>
              </Grid>
              <Grid item xs={1.5}>
                {t('features.customerSegments.detail.sync.tag')}
              </Grid>
              <Grid item xs={10.5}>
                {customerSegment.tagOperationSetting.tagName || '-'}
              </Grid>
              <Grid item xs={1.5}>
                {t('features.customerSegments.detail.sync.lastSyncedAt')}
              </Grid>
              <Grid item xs={10.5}>
                {customerSegment.tagOperationSetting.lastSyncedAt
                  ? formatDateTime(timestampToDayjs(customerSegment.tagOperationSetting.lastSyncedAt), i18n.language)
                  : '-'}
              </Grid>
            </Grid>
          ) : (
            // Skeleton while loading
            Array.from(new Array(3)).map((_, i) => <Skeleton key={i} width='360px' />)
          )}
        </Box>
      </Paper>

      {activateDialogOpen && customerSegment && customerSegment.tagOperationSetting && (
        <ActivateAutoSyncDialog
          open={activateDialogOpen}
          handleClose={() => setActivateDialogOpen(false)}
          customerSegmentId={customerSegment.id}
          customerSegmentName={customerSegment.name}
          estimatedProcessingDurationInMinutes={estimatedProcessingDurationInMinutes}
        />
      )}

      {deactivateDialogOpen && customerSegment && customerSegment.tagOperationSetting && (
        <DeactivateAutoSyncDialog
          open={deactivateDialogOpen}
          handleClose={() => setDeactivateDialogOpen(false)}
          customerSegmentId={customerSegment.id}
          tagName={customerSegment.tagOperationSetting.tagName}
          estimatedProcessingDurationInMinutes={estimatedProcessingDurationInMinutes}
        />
      )}
    </>
  )
}
