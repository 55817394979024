import { closestCenter, DndContext, DragEndEvent, DragStartEvent, MouseSensor, useSensor, useSensors } from '@dnd-kit/core'
import { restrictToParentElement } from '@dnd-kit/modifiers'
import { arrayMove, SortableContext, useSortable } from '@dnd-kit/sortable'
import DeleteOutlinedIcon from '@mui/icons-material/DeleteOutlined'
import EditOutlinedIcon from '@mui/icons-material/EditOutlined'
import FileDownloadOutlinedIcon from '@mui/icons-material/FileDownloadOutlined'
import FormatListBulletedOutlinedIcon from '@mui/icons-material/FormatListBulletedOutlined'
import GridViewOutlinedIcon from '@mui/icons-material/GridViewOutlined'
import MoreHorizIcon from '@mui/icons-material/MoreHoriz'
import RouteOutlinedIcon from '@mui/icons-material/RouteOutlined'
import SearchIcon from '@mui/icons-material/Search'
import {
  Badge,
  Box,
  Button,
  Card,
  CardActionArea,
  CardContent,
  Chip,
  Collapse,
  Grid,
  IconButton,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  MenuList,
  Paper,
  Skeleton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  ToggleButton,
  ToggleButtonGroup,
  Tooltip,
  useTheme,
} from '@mui/material'
import { EmptyState } from 'components/emptyState'
import { GuideIcon } from 'components/guideIcon'
import { MetricsTooltip } from 'components/metricsTooltip'
import { PageHeader } from 'components/pageHeader'
import { SkeletonTable } from 'components/skeletonTable'
import { UpgradeRecommendDialog } from 'components/upgradeRecommendDialog'
import { billingStatus } from 'config/plan'
import dayjs from 'dayjs'
import { SegmentGridItem } from 'features/customerSegments/components/segmentGridItem'
import { SegmentTableItem } from 'features/customerSegments/components/segmentTableItem'
import { CustomerSegmentState } from 'features/customerSegments/types/types'
import { getIdToken } from 'firebase/auth'
import { SegmentGroup, ShopBillingStatus } from 'gen/firestore'
import { SegmentGroupService } from 'gen/proto/segment_group/segment_group_connectweb'
import { useAccount } from 'hooks/useAccount'
import { useAuthUser } from 'hooks/useAuthUser'
import { useCsvDownload } from 'hooks/useCsvDownload'
import { useCustomSnackbar } from 'hooks/useCustomSnackbar'
import { useGrpcClient } from 'hooks/useGrpcClient'
import useLocalStorage from 'hooks/useLocalStrage'
import { useSentryNotifier } from 'hooks/useSentryNotifier'
import { useEffect, useMemo, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'
import { extractTextFromTextWithIcon } from 'utils/iconUtil'
import { formatDate } from 'utils/timeUtil'
import { AddSegmentDialog } from './components/addSegmentDialog'
import { CanvasView } from './components/canvasView'
import { DeleteDialog } from './components/deleteDialog'
import { UpdateDialog } from './components/updateDialog'
import { useGroupSegments } from './hooks/useGroupSegments'

export enum ViewType {
  grid = 'grid',
  table = 'table',
  canvas = 'canvas',
}

type AddSegmentDialogState = {
  open: boolean
  group: SegmentGroup | undefined
}

export const CustomerSegmentGroup = () => {
  const { enqueueSnackbar } = useCustomSnackbar()
  const { t, i18n } = useTranslation()
  const { shop } = useAccount()
  const { notifySentry } = useSentryNotifier()
  const authUser = useAuthUser()
  const segmentGroupService = useGrpcClient(SegmentGroupService)
  const shopBillingStatus = billingStatus(shop, dayjs())

  const params = useParams()
  const groupId = params.id

  const { segmentGroup, customerSegments } = useGroupSegments(groupId)

  const [searchOpen, setSearchOpen] = useState(false)
  const [searchInput, setSearchInput] = useState('')
  const [viewType, setViewType] = useLocalStorage<ViewType>('SegmentGroupViewType', ViewType.table)

  const filteredCustomerSegments = useMemo(() => {
    return customerSegments?.filter((row) => !searchInput || row.name.toLowerCase().includes(searchInput.toLowerCase()))
  }, [customerSegments, searchInput])

  const [draggableCustomerSegments, setDraggableCustomerSegments] = useState(filteredCustomerSegments)

  useEffect(() => {
    setDraggableCustomerSegments(filteredCustomerSegments)
  }, [filteredCustomerSegments])

  const [menuOpen, setMenuOpen] = useState(false)
  const menuAnchorRef = useRef<HTMLButtonElement | null>(null)

  const [updateDialogOpen, setUpdateDialogOpen] = useState(false)
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false)

  const [addSegmentDialogState, setAddSegmentDialogState] = useState<AddSegmentDialogState>({ open: false, group: undefined })
  const [upgradeRecommendDialogOpen, setUpgradeRecommendDialogOpen] = useState(false)

  const { handleDownload, loading: downloadLoading } = useCsvDownload({
    data: filteredCustomerSegments?.map((row) => {
      return {
        segmentName: extractTextFromTextWithIcon(row.name),
        count: row.metrics?.count,
        repeaterRate: row.metrics?.repeaterRate.toFixed(3),
        ltv: row.metrics?.ltv,
        avgOrderValue: row.metrics?.avgOrderValue,
        avgOrderCount: row.metrics?.avgOrderCount.toFixed(2),
        avgDaysBetweenOrders: row.metrics?.avgDaysBetweenOrders.toFixed(1),
      }
    }),
    headers: [
      t('features.customerSegments.group.name'),
      t('common.customerMetrics.title', { context: 'count' }),
      t('common.customerMetrics.title', { context: 'repeaterRate' }),
      t('common.customerMetrics.title', { context: 'ltv' }),
      t('common.customerMetrics.title', { context: 'avgOrderValue' }),
      t('common.customerMetrics.title', { context: 'avgOrderCount' }),
      t('common.customerMetrics.title', { context: 'avgDaysBetweenOrders' }),
    ],
    fileName: t('features.customerSegments.group.downloadFileName', { group: segmentGroup?.name, date: formatDate(dayjs(), i18n.language) }),
    page: 'customerSegments',
  })

  const saveNewSegmentsSequence = async (customerSegments: CustomerSegmentState[], segmentGroup: SegmentGroup) => {
    if (!segmentGroup || customerSegments.length === 0) return
    try {
      const token = await getIdToken(authUser!)
      await segmentGroupService.updateSegmentsSequence(
        {
          segmentGroupId: segmentGroup.ref.id,
          segmentIdsInSequence: customerSegments.map((customerSegment) => customerSegment.id),
        },
        { headers: { Authorization: `Bearer ${token}` } }
      )
    } catch (err) {
      enqueueSnackbar(t('features.customerSegments.group.'), { severity: 'error' })
      notifySentry(err)
    }
  }
  const [draggingSegmentId, setDraggingSegmentId] = useState<string | undefined>(undefined)

  const handleDragStart = (event: DragStartEvent) => {
    const activeId = event.active.id as string
    if (activeId !== undefined) {
      setDraggingSegmentId(activeId)
    }
  }

  const handleDragEnd = (event: DragEndEvent, segmentGroup: SegmentGroup) => {
    setDraggingSegmentId(undefined)

    const { active, over } = event

    if (over && active.id !== over.id) {
      setDraggableCustomerSegments((prevItems) => {
        if (prevItems) {
          const oldIndex = prevItems.findIndex((item) => item.id === active.id)
          const newIndex = prevItems.findIndex((item) => item.id === over.id)
          const newItems = arrayMove(prevItems, oldIndex, newIndex)

          saveNewSegmentsSequence(newItems, segmentGroup)

          return newItems
        }
      })
    }
  }

  const dndSensors = useSensors(
    useSensor(MouseSensor, {
      activationConstraint: {
        distance: 5, // Dragging more than 5px will initiate the drag operation.
      },
    })
  )

  const isActiveSearch = useMemo(() => {
    if (!customerSegments || !filteredCustomerSegments) {
      return false
    }
    return customerSegments.length !== filteredCustomerSegments.length
  }, [customerSegments, filteredCustomerSegments])

  return (
    <>
      <Box display='flex' justifyContent='space-between' alignItems='center' marginBottom='24px'>
        <Box display='flex' alignItems='center'>
          <Box sx={{ maxWidth: '650px' }}>
            <PageHeader title={segmentGroup?.name || ''} marginBottom='0px' />
          </Box>

          {viewType === ViewType.canvas ? (
            <>
              <Tooltip title={t('common.beta.description')} placement='right-end'>
                <Chip size='small' label={t('common.beta.title')} variant='outlined' sx={{ marginLeft: '8px' }} />
              </Tooltip>
              <GuideIcon guideType='CustomerJourney' />
            </>
          ) : (
            <>
              <Tooltip title={t('features.customerSegments.group.menu')} placement='top'>
                <span>
                  <IconButton
                    size='small'
                    disabled={!segmentGroup}
                    onClick={() => setMenuOpen(true)}
                    ref={menuAnchorRef}
                    sx={{ marginLeft: '8px', borderRadius: '8px' }}
                  >
                    <MoreHorizIcon fontSize='small' />
                  </IconButton>
                </span>
              </Tooltip>

              <Menu anchorEl={menuAnchorRef.current} open={menuOpen} onClose={() => setMenuOpen(false)}>
                <MenuList dense sx={{ padding: '0px' }}>
                  <MenuItem
                    onClick={() => {
                      setUpdateDialogOpen(true)
                      setMenuOpen(false)
                    }}
                  >
                    <ListItemIcon>
                      <EditOutlinedIcon fontSize='small' />
                    </ListItemIcon>
                    <ListItemText>{t('features.customerSegments.group.edit')}</ListItemText>
                  </MenuItem>
                  <MenuItem
                    onClick={() => {
                      setDeleteDialogOpen(true)
                      setMenuOpen(false)
                    }}
                  >
                    <ListItemIcon>
                      <DeleteOutlinedIcon fontSize='small' />
                    </ListItemIcon>
                    <ListItemText>{t('features.customerSegments.group.delete')}</ListItemText>
                  </MenuItem>
                </MenuList>
              </Menu>
            </>
          )}
        </Box>

        <Box display='flex' alignItems='center'>
          <ToggleButtonGroup
            value={viewType}
            size='small'
            exclusive
            onChange={(_, v) => v !== null && setViewType(v)}
            sx={{ marginRight: '8px', '& .MuiToggleButton-root': { padding: '6px' } }}
          >
            <ToggleButton value={ViewType.grid}>
              <Tooltip title={t('features.customerSegments.group.grid')} placement='top'>
                <GridViewOutlinedIcon sx={{ fontSize: '16px' }} />
              </Tooltip>
            </ToggleButton>
            <ToggleButton value={ViewType.table}>
              <Tooltip title={t('features.customerSegments.group.table')} placement='top'>
                <FormatListBulletedOutlinedIcon sx={{ fontSize: '16px' }} />
              </Tooltip>
            </ToggleButton>
            <ToggleButton value={ViewType.canvas}>
              <Tooltip title={t('features.customerSegments.group.canvas')} placement='top'>
                <RouteOutlinedIcon sx={{ fontSize: '16px', transform: 'rotate(90deg)' }} />
              </Tooltip>
            </ToggleButton>
          </ToggleButtonGroup>

          {viewType !== ViewType.canvas && (
            <Box display='flex' alignItems='center'>
              <Box display='flex' alignItems='center'>
                <Tooltip title={t('features.customerSegments.group.search')} placement='top'>
                  <IconButton size='small' onClick={() => setSearchOpen(!searchOpen)} sx={{ marginRight: '4px', borderRadius: '8px' }}>
                    <Badge color='primary' variant='dot' invisible={searchInput.length === 0}>
                      <SearchIcon fontSize='small' />
                    </Badge>
                  </IconButton>
                </Tooltip>
                <Collapse orientation='horizontal' in={searchOpen}>
                  <TextField variant='standard' autoFocus size='small' onChange={(e) => setSearchInput(e.target.value)} />
                </Collapse>
              </Box>

              <Tooltip title={t('features.customerSegments.group.download')} placement='top'>
                <span>
                  <IconButton
                    size='small'
                    disabled={!filteredCustomerSegments || filteredCustomerSegments.length === 0 || downloadLoading}
                    onClick={() => {
                      if (shopBillingStatus === ShopBillingStatus.free) {
                        setUpgradeRecommendDialogOpen(true)
                        return
                      }
                      handleDownload()
                    }}
                    sx={{ marginRight: '12px', borderRadius: '8px' }}
                  >
                    <FileDownloadOutlinedIcon fontSize='small' />
                  </IconButton>
                </span>
              </Tooltip>

              <Button
                variant='contained'
                disabled={!filteredCustomerSegments}
                onClick={() => setAddSegmentDialogState({ open: true, group: segmentGroup })}
              >
                {t('features.customerSegments.group.add')}
              </Button>
            </Box>
          )}
        </Box>
      </Box>

      {customerSegments &&
        (customerSegments.length === 0 ? (
          <EmptyState
            title={t('features.customerSegments.group.groupIsEmpty')}
            buttonText={t('features.customerSegments.group.add')}
            buttonOnClick={() => setAddSegmentDialogState({ open: true, group: segmentGroup })}
          />
        ) : (
          filteredCustomerSegments?.length === 0 && (
            <EmptyState title={t('features.customerSegments.group.searchResultZero', { input: searchInput })} />
          )
        ))}

      {viewType === ViewType.table &&
        (!filteredCustomerSegments ? (
          <SkeletonTable rowCount={3} columnCount={9} />
        ) : (
          filteredCustomerSegments.length > 0 && (
            <TableContainer component={Paper} variant='outlined' sx={{ borderRadius: '6px' }}>
              <Table sx={{ minWidth: '650px' }}>
                <TableHead>
                  <TableRow
                    sx={{
                      '& .MuiTableCell-head': {
                        color: (theme) => theme.palette.text.secondary,
                        fontSize: '13px',
                        whiteSpace: 'nowrap',
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                      },
                    }}
                  >
                    <TableCell />
                    <TableCell>{t('features.customerSegments.group.name')}</TableCell>
                    <TableCell align='center'>
                      <MetricsTooltip metricsKey='count' element={<Box>{t('common.customerMetrics.title', { context: 'count' })}</Box>} />
                    </TableCell>
                    <TableCell align='center'>
                      <MetricsTooltip
                        metricsKey='repeaterRate'
                        element={<Box>{t('common.customerMetrics.title', { context: 'repeaterRate' })}</Box>}
                      />
                    </TableCell>
                    <TableCell align='center'>
                      <MetricsTooltip metricsKey='ltv' element={<Box>{t('common.customerMetrics.title', { context: 'ltv' })}</Box>} />
                    </TableCell>
                    <TableCell align='center'>
                      <MetricsTooltip
                        metricsKey='avgOrderValue'
                        element={<Box>{t('common.customerMetrics.title', { context: 'avgOrderValue' })}</Box>}
                      />
                    </TableCell>
                    <TableCell align='center'>
                      <MetricsTooltip
                        metricsKey='avgOrderCount'
                        element={<Box>{t('common.customerMetrics.title', { context: 'avgOrderCount' })}</Box>}
                      />
                    </TableCell>
                    <TableCell align='center'>
                      <MetricsTooltip
                        metricsKey='avgDaysBetweenOrders'
                        element={<Box>{t('common.customerMetrics.title', { context: 'avgDaysBetweenOrders' })}</Box>}
                      />
                    </TableCell>
                    <TableCell align='right' />
                  </TableRow>
                </TableHead>
                <TableBody>
                  {segmentGroup && draggableCustomerSegments && (
                    <DndContext
                      sensors={dndSensors}
                      collisionDetection={closestCenter}
                      onDragStart={handleDragStart}
                      onDragEnd={(e) => {
                        handleDragEnd(e, segmentGroup)
                      }}
                      modifiers={[restrictToParentElement]}
                    >
                      <SortableContext items={draggableCustomerSegments.map((item) => item.id)}>
                        {draggableCustomerSegments.map((row) => (
                          <DraggableSegmentItem
                            key={row.id}
                            row={row}
                            group={segmentGroup}
                            viewType={viewType}
                            isDraggable={isActiveSearch}
                            draggingSegmentId={draggingSegmentId}
                          />
                        ))}
                      </SortableContext>
                    </DndContext>
                  )}
                </TableBody>
              </Table>
            </TableContainer>
          )
        ))}

      {viewType === ViewType.grid && (
        <Grid container spacing={3} alignItems='stretch' sx={{ padding: '8px 4px' }}>
          {!filteredCustomerSegments
            ? Array.from(new Array(8)).map((_, i) => (
                <Grid key={i} item xs={12} sm={6} md={4} lg={3}>
                  <Card variant='outlined' sx={{ borderRadius: '12px' }}>
                    <CardActionArea>
                      <CardContent>
                        <Skeleton width={30} height={50} animation='wave' />
                        <Skeleton width='60%' height={16} animation='wave' />
                        <Skeleton width='100%' height={16} animation='wave' />
                        <Skeleton width='100%' height={16} animation='wave' />
                      </CardContent>
                    </CardActionArea>
                  </Card>
                </Grid>
              ))
            : segmentGroup &&
              draggableCustomerSegments && (
                <DndContext
                  sensors={dndSensors}
                  collisionDetection={closestCenter}
                  onDragStart={handleDragStart}
                  onDragEnd={(e) => {
                    handleDragEnd(e, segmentGroup)
                  }}
                  modifiers={[restrictToParentElement]}
                >
                  <SortableContext items={draggableCustomerSegments.map((item) => item.id)}>
                    {draggableCustomerSegments.map((row) => (
                      <DraggableSegmentItem
                        key={row.id}
                        row={row}
                        group={segmentGroup}
                        viewType={viewType}
                        isDraggable={isActiveSearch}
                        draggingSegmentId={draggingSegmentId}
                      />
                    ))}
                  </SortableContext>
                </DndContext>
              )}
        </Grid>
      )}

      {viewType === ViewType.canvas && segmentGroup && filteredCustomerSegments && filteredCustomerSegments.length > 0 && (
        <CanvasView segmentGroup={segmentGroup} customerSegments={filteredCustomerSegments} />
      )}

      {addSegmentDialogState.open && addSegmentDialogState.group && (
        <AddSegmentDialog
          open={addSegmentDialogState.open}
          group={addSegmentDialogState.group}
          handleClose={() => setAddSegmentDialogState({ open: false, group: undefined })}
        />
      )}

      {updateDialogOpen && segmentGroup && (
        <UpdateDialog open={updateDialogOpen} handleClose={() => setUpdateDialogOpen(false)} id={segmentGroup.ref.id} name={segmentGroup.name} />
      )}

      {deleteDialogOpen && segmentGroup && (
        <DeleteDialog open={deleteDialogOpen} handleClose={() => setDeleteDialogOpen(false)} id={segmentGroup.ref.id} name={segmentGroup.name} />
      )}

      {upgradeRecommendDialogOpen && (
        <UpgradeRecommendDialog
          open={upgradeRecommendDialogOpen}
          handleClose={() => setUpgradeRecommendDialogOpen(false)}
          referrer='customerSegmentGroup'
        />
      )}
    </>
  )
}
type DraggableSegmentItemProps = {
  row: CustomerSegmentState
  group: SegmentGroup
  viewType: ViewType
  isDraggable: boolean
  draggingSegmentId: string | undefined
}

const DraggableSegmentItem = ({ row, group, viewType, isDraggable, draggingSegmentId }: DraggableSegmentItemProps) => {
  /*
  When viewType is table, the following warning appears in the console.
  ====================================
  Warning: validateDOMNesting(...):
  <div> cannot appear as a child of <tbody>.
  ====================================
  This warning has no real harm on the UI, so we skip its resolution.
  This warning is caused by the insertion of a div tag automatically generated by dnd kit inside a table tag.
  */
  const { attributes, listeners, setNodeRef, transform, transition } = useSortable({ id: row.id })
  const theme = useTheme()
  const style = {
    transform: transform ? `translate3d(${transform.x}px, ${transform.y}px, 0)` : '',
    zIndex: draggingSegmentId === row.id ? '1' : '0',
    position: 'relative',
    background: draggingSegmentId === row.id && viewType === ViewType.table ? theme.palette.background.paper : 'inherit',
    transition,
  }
  const dragProps = isDraggable ? {} : { ref: setNodeRef, style, ...attributes, ...listeners }
  return (
    <>
      {viewType === ViewType.grid ? (
        <Grid item xs={12} sm={6} md={4} lg={3} {...dragProps}>
          <SegmentGridItem key={row.id} row={row} group={group} />
        </Grid>
      ) : (
        <SegmentTableItem key={row.id} row={row} group={group} dragProps={dragProps} />
      )}
    </>
  )
}
