import dayjs from 'dayjs'

export enum TimeRange {
  last7Days = 'last7Days',
  last14Days = 'last14Days',
  last30Days = 'last30Days',
  last90Days = 'last90Days',
  last180Days = 'last180Days',
  last365Days = 'last365Days',
  thisWeek = 'thisWeek',
  lastWeek = 'lastWeek',
  thisMonth = 'thisMonth',
  lastMonth = 'lastMonth',
  thisYear = 'thisYear',
  lastYear = 'lastYear',
  all = 'all',
}

// convertTimeRangeToStartEndDate: convert timeRange to startDate and endDate in 'YYYY-MM-DD' format
export const convertTimeRangeToStartEndDate = (timeRange: TimeRange, timezone: string): { startDate: string; endDate: string } => {
  const now = dayjs().tz(timezone)

  const calculateDateRange = (start: dayjs.Dayjs, end: dayjs.Dayjs) => ({
    startDate: start.format('YYYY-MM-DD'),
    endDate: end.format('YYYY-MM-DD'),
  })

  switch (timeRange) {
    case TimeRange.last7Days:
      return calculateDateRange(now.subtract(7 - 1, 'day'), now)
    case TimeRange.last14Days:
      return calculateDateRange(now.subtract(14 - 1, 'day'), now)
    case TimeRange.last30Days:
      return calculateDateRange(now.subtract(30 - 1, 'day'), now)
    case TimeRange.last90Days:
      return calculateDateRange(now.subtract(90 - 1, 'day'), now)
    case TimeRange.last180Days:
      return calculateDateRange(now.subtract(180 - 1, 'day'), now)
    case TimeRange.last365Days:
      return calculateDateRange(now.subtract(365 - 1, 'day'), now)
    case TimeRange.thisWeek:
      return calculateDateRange(now.startOf('week').add(1, 'day'), now.endOf('week').add(1, 'day'))
    case TimeRange.lastWeek:
      return calculateDateRange(now.subtract(1, 'week').startOf('week').add(1, 'day'), now.subtract(1, 'week').endOf('week').add(1, 'day'))
    case TimeRange.thisMonth:
      return calculateDateRange(now.startOf('month'), now.endOf('month'))
    case TimeRange.lastMonth:
      return calculateDateRange(now.subtract(1, 'month').startOf('month'), now.subtract(1, 'month').endOf('month'))
    case TimeRange.thisYear:
      return calculateDateRange(now.startOf('year'), now.endOf('year'))
    case TimeRange.lastYear:
      return calculateDateRange(now.subtract(1, 'year').startOf('year'), now.subtract(1, 'year').endOf('year'))
    case TimeRange.all:
      return calculateDateRange(now.subtract(3650, 'day'), now)
    default:
      return calculateDateRange(now.subtract(6, 'day'), now)
  }
}
