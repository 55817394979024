import {
  OP_CONTAIN_ANY,
  OP_DATE_RANGE,
  OP_EQUAL,
  OP_INT_RANGE,
  OP_ORDER_SEQUENCE_FIRST,
  OP_ORDER_SEQUENCE_SPECIFY,
  OP_RELATIVE_DATE_RANGE,
} from 'features/customerSegments/querySetForm/schema/const'
import { QueryItemFormState, QuerySetFormState } from 'features/customerSegments/querySetForm/schema/querySetFormSchema'
import { query } from 'firebase/firestore'
import { useAccount } from 'hooks/useAccount'
import { useSubCollectionRef } from 'hooks/useCollectionRef'
import { useCollection } from 'hooks/useFirestoreData'
import { useTranslation } from 'react-i18next'

export type SegmentTemplate = {
  name: string
  description: string
  querySet: QuerySetFormState
}

export const useSegmentTemplates = (): { data: SegmentTemplate[] } => {
  const { t } = useTranslation()

  const { account } = useAccount()
  const { dimensionOptionsRef } = useSubCollectionRef(account.shopRef!.id)
  const { data } = useCollection(query(dimensionOptionsRef))

  const topProduct = data?.find((d) => d.dimension === 'product')?.options?.[0] ?? 'sample'
  const topProductType = data?.find((d) => d.dimension === 'product_type')?.options?.[0] ?? 'sample'
  const topProductTag = data?.find((d) => d.dimension === 'product_tag')?.options?.[0] ?? 'sample'
  const topProductVendor = data?.find((d) => d.dimension === 'product_vendor')?.options?.[0] ?? 'sample'

  const templates: SegmentTemplate[] = [
    {
      name: t('features.templates.segmentTemplates.product_name'),
      description: t('features.templates.segmentTemplates.product_desc'),
      querySet: generateQuerySet([
        {
          dimension: 'product',
          operator: OP_CONTAIN_ANY,
          value: [topProduct],
          filters: [],
        },
      ]),
    },
    {
      name: t('features.templates.segmentTemplates.productType_name'),
      description: t('features.templates.segmentTemplates.productType_desc'),
      querySet: generateQuerySet([
        {
          dimension: 'product_type',
          operator: OP_CONTAIN_ANY,
          value: [topProductType],
          filters: [],
        },
      ]),
    },
    {
      name: t('features.templates.segmentTemplates.productTag_name'),
      description: t('features.templates.segmentTemplates.productTag_desc'),
      querySet: generateQuerySet([
        {
          dimension: 'product_tag',
          operator: OP_CONTAIN_ANY,
          value: [topProductTag],
          filters: [],
        },
      ]),
    },
    {
      name: t('features.templates.segmentTemplates.productVendor_name'),
      description: t('features.templates.segmentTemplates.productVendor_desc'),
      querySet: generateQuerySet([
        {
          dimension: 'product_vendor',
          operator: OP_CONTAIN_ANY,
          value: [topProductVendor],
          filters: [],
        },
      ]),
    },
    {
      name: t('features.templates.segmentTemplates.onlineStore_name'),
      description: t('features.templates.segmentTemplates.onlineStore_desc'),
      querySet: generateQuerySet([
        {
          dimension: 'channel',
          operator: OP_CONTAIN_ANY,
          value: ['Online Store'],
          filters: [],
        },
      ]),
    },
    {
      name: t('features.templates.segmentTemplates.pos_name'),
      description: t('features.templates.segmentTemplates.pos_desc'),
      querySet: generateQuerySet([
        {
          dimension: 'channel',
          operator: OP_CONTAIN_ANY,
          value: ['Point of Sale'],
          filters: [],
        },
      ]),
    },
    {
      name: t('features.templates.segmentTemplates.google_name'),
      description: t('features.templates.segmentTemplates.google_desc'),
      querySet: generateQuerySet([
        {
          dimension: 'referrer',
          operator: OP_CONTAIN_ANY,
          value: ['Google'],
          filters: [],
        },
      ]),
    },
    {
      name: t('features.templates.segmentTemplates.newsletter_name'),
      description: t('features.templates.segmentTemplates.newsletter_desc'),
      querySet: generateQuerySet([
        {
          dimension: 'utm_source',
          operator: OP_CONTAIN_ANY,
          value: ['newsletter'],
          filters: [],
        },
      ]),
    },
    {
      name: t('features.templates.segmentTemplates.adCampaign_name'),
      description: t('features.templates.segmentTemplates.adCampaign_desc'),
      querySet: generateQuerySet([
        {
          dimension: 'utm_campaign',
          operator: OP_CONTAIN_ANY,
          value: ['Ad Campaign'],
          filters: [],
        },
      ]),
    },
    {
      name: t('features.templates.segmentTemplates.gold_name'),
      description: t('features.templates.segmentTemplates.gold_desc'),
      querySet: generateQuerySet([
        {
          dimension: 'customer_tag',
          operator: OP_CONTAIN_ANY,
          value: ['gold'],
          filters: [],
        },
      ]),
    },
    {
      name: t('features.templates.segmentTemplates.subscription_name'),
      description: t('features.templates.segmentTemplates.subscription_desc'),
      querySet: generateQuerySet([
        {
          dimension: 'order_tag',
          operator: OP_CONTAIN_ANY,
          value: ['subscription'],
          filters: [],
        },
      ]),
    },
    {
      name: t('features.templates.segmentTemplates.us_name'),
      description: t('features.templates.segmentTemplates.us_desc'),
      querySet: generateQuerySet([
        {
          dimension: 'country',
          operator: OP_CONTAIN_ANY,
          value: ['United States'],
          filters: [],
        },
      ]),
    },
    {
      name: t('features.templates.segmentTemplates.ny_name'),
      description: t('features.templates.segmentTemplates.ny_desc'),
      querySet: generateQuerySet([
        {
          dimension: 'prefecture',
          operator: OP_CONTAIN_ANY,
          value: ['New York'],
          filters: [],
        },
      ]),
    },
    {
      name: t('features.templates.segmentTemplates.yearly_name'),
      description: t('features.templates.segmentTemplates.yearly_desc'),
      querySet: generateQuerySet([
        {
          dimension: 'order_date',
          operator: OP_DATE_RANGE,
          value: { min_date: '2024-01-01', max_date: '2024-12-31' },
          filters: [],
        },
      ]),
    },
    {
      name: t('features.templates.segmentTemplates.recent_name'),
      description: t('features.templates.segmentTemplates.recent_desc'),
      querySet: generateQuerySet([
        {
          dimension: 'order_date',
          operator: OP_RELATIVE_DATE_RANGE,
          value: { min: 30 },
          filters: [],
        },
      ]),
    },
    {
      name: t('features.templates.segmentTemplates.oneTimeProduct_name'),
      description: t('features.templates.segmentTemplates.oneTimeProduct_desc'),
      querySet: generateQuerySet([
        {
          dimension: 'product',
          operator: OP_CONTAIN_ANY,
          value: [topProduct],
          filters: [{ filter_type: 'order_count', operator: OP_INT_RANGE, value: { min: 1, max: 1 } }],
        },
      ]),
    },
    {
      name: t('features.templates.segmentTemplates.repeatProduct_name'),
      description: t('features.templates.segmentTemplates.repeatProduct_desc'),
      querySet: generateQuerySet([
        {
          dimension: 'product',
          operator: OP_CONTAIN_ANY,
          value: [topProduct],
          filters: [{ filter_type: 'order_count', operator: OP_INT_RANGE, value: { min: 2 } }],
        },
      ]),
    },
    {
      name: t('features.templates.segmentTemplates.firstTimeProduct_name'),
      description: t('features.templates.segmentTemplates.firstTimeProduct_desc'),
      querySet: generateQuerySet([
        {
          dimension: 'product',
          operator: OP_CONTAIN_ANY,
          value: [topProduct],
          filters: [{ filter_type: 'order_sequence', operator: OP_ORDER_SEQUENCE_FIRST }],
        },
      ]),
    },
    {
      name: t('features.templates.segmentTemplates.returningProduct_name'),
      description: t('features.templates.segmentTemplates.returningProduct_desc'),
      querySet: generateQuerySet([
        {
          dimension: 'product',
          operator: OP_CONTAIN_ANY,
          value: [topProduct],
          filters: [{ filter_type: 'order_sequence', operator: OP_ORDER_SEQUENCE_SPECIFY, value: { min_sequence: 2 } }],
        },
      ]),
    },
    {
      name: t('features.templates.segmentTemplates.yearlyProduct_name'),
      description: t('features.templates.segmentTemplates.yearlyProduct_desc'),
      querySet: generateQuerySet([
        {
          dimension: 'product',
          operator: OP_CONTAIN_ANY,
          value: [topProduct],
          filters: [{ filter_type: 'order_date', operator: OP_DATE_RANGE, value: { min_date: '2024-01-01', max_date: '2024-12-31' } }],
        },
      ]),
    },
    {
      name: t('features.templates.segmentTemplates.recentProduct_name'),
      description: t('features.templates.segmentTemplates.recentProduct_desc'),
      querySet: generateQuerySet([
        {
          dimension: 'product',
          operator: OP_CONTAIN_ANY,
          value: [topProduct],
          filters: [{ filter_type: 'order_date', operator: OP_RELATIVE_DATE_RANGE, value: { min: 30 } }],
        },
      ]),
    },
    {
      name: t('features.templates.segmentTemplates.active_name'),
      description: t('features.templates.segmentTemplates.active_desc'),
      querySet: generateQuerySet([
        {
          dimension: 'recency',
          operator: OP_INT_RANGE,
          value: { max: 60 },
          filters: [],
        },
      ]),
    },
    {
      name: t('features.templates.segmentTemplates.atRisk_name'),
      description: t('features.templates.segmentTemplates.atRisk_desc'),
      querySet: generateQuerySet([
        {
          dimension: 'recency',
          operator: OP_INT_RANGE,
          value: { min: 61, max: 120 },
          filters: [],
        },
      ]),
    },
    {
      name: t('features.templates.segmentTemplates.churned_name'),
      description: t('features.templates.segmentTemplates.churned_desc'),
      querySet: generateQuerySet([
        {
          dimension: 'order_date',
          operator: OP_RELATIVE_DATE_RANGE,
          value: { min: 120 },
          filters: [],
        },
      ]),
    },
    {
      name: t('features.templates.segmentTemplates.oneTime_name'),
      description: t('features.templates.segmentTemplates.oneTime_desc'),
      querySet: generateQuerySet([
        {
          dimension: 'frequency',
          operator: OP_EQUAL,
          value: 1,
          filters: [],
        },
      ]),
    },
    {
      name: t('features.templates.segmentTemplates.repeat_name'),
      description: t('features.templates.segmentTemplates.repeat_desc'),
      querySet: generateQuerySet([
        {
          dimension: 'frequency',
          operator: OP_INT_RANGE,
          value: { min: 2, max: 4 },
          filters: [],
        },
      ]),
    },
    {
      name: t('features.templates.segmentTemplates.loyal_name'),
      description: t('features.templates.segmentTemplates.loyal_desc'),
      querySet: generateQuerySet([
        {
          dimension: 'frequency',
          operator: OP_INT_RANGE,
          value: { min: 5 },
          filters: [],
        },
      ]),
    },
    {
      name: t('features.templates.segmentTemplates.allBuyers_name'),
      description: t('features.templates.segmentTemplates.allBuyers_desc'),
      querySet: generateQuerySet([
        {
          dimension: 'frequency',
          operator: OP_INT_RANGE,
          value: { min: 1 },
          filters: [],
        },
      ]),
    },
  ]

  return { data: templates }
}

export const generateQuerySet = (queryItems: QueryItemFormState[]): QuerySetFormState => {
  return {
    join_operator: 'and',
    query_subsets: [
      {
        join_operator: 'and',
        query_items: queryItems,
      },
    ],
  }
}
