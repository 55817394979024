import { Autocomplete, Box, Grid, TextField } from '@mui/material'
import { PageHeader } from 'components/pageHeader'
import dayjs from 'dayjs'
import { getIdToken } from 'firebase/auth'
import { orderBy, query } from 'firebase/firestore'
import { InsightDimension } from 'gen/firestore'
import { InsightService } from 'gen/proto/insight/insight_connectweb'
import { useAccount } from 'hooks/useAccount'
import { useAuthUser } from 'hooks/useAuthUser'
import { useSubCollectionRef } from 'hooks/useCollectionRef'
import { useCustomSnackbar } from 'hooks/useCustomSnackbar'
import { useCollection } from 'hooks/useFirestoreData'
import { useGrpcClient } from 'hooks/useGrpcClient'
import { useSentryNotifier } from 'hooks/useSentryNotifier'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { extractTextFromTextWithIcon } from 'utils/iconUtil'
import { formatDate } from 'utils/timeUtil'
import { InsightCategoryValues } from './components/insightCategoryValues'
import { InsightNumericValues } from './components/insightNumericValues'
import { addIndexToName } from './types/insight'
import { useNavigate, useLocation } from 'react-router-dom'

export type InsightValue = {
  name: string
  customerCount: number
  ratio: number
}

export const Insights = () => {
  const { t } = useTranslation()
  const authUser = useAuthUser()
  const { enqueueSnackbar } = useCustomSnackbar()
  const { notifySentry } = useSentryNotifier()
  const insightService = useGrpcClient(InsightService)
  const navigate = useNavigate()

  const { account } = useAccount()
  const { customerSegmentsRef } = useSubCollectionRef(account.shopRef!.id)
  const { data: customerSegments } = useCollection(query(customerSegmentsRef, orderBy('createdAt', 'desc')))

  // TODO: Validate segment id
  const location = useLocation()
  const params = new URLSearchParams(location.search)
  const [customerSegmentId, setCustomerSegmentId] = useState<string>(params.get('customer_segment_id') || '')

  const handleCustomerSegmentChange = (value: string | null) => {
    const selected = customerSegments && customerSegments.find((cs) => cs.name === value)
    const newSegmentId = selected?.ref.id || ''
    setCustomerSegmentId(newSegmentId)
    newSegmentId ? navigate(`?customer_segment_id=${newSegmentId}`) : navigate(location.pathname)
  }

  // Product
  const [productInsightValues, setProductInsightValues] = useState<InsightValue[] | undefined>(undefined)
  const [productInsightLoading, setProductInsightLoading] = useState(false)
  const [productInsightDimension, setProductInsightDimension] = useState<InsightDimension>(InsightDimension.product)
  const productInsightDimensions: InsightDimension[] = [
    InsightDimension.product,
    InsightDimension.productType,
    InsightDimension.productVendor,
    InsightDimension.productTag,
    InsightDimension.productVariant,
  ]
  useEffect(() => {
    fetchCategoryData(productInsightDimension, setProductInsightValues, setProductInsightLoading, 3)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [customerSegmentId, productInsightDimension])

  // Source
  const [sourceInsightValues, setSourceInsightValues] = useState<InsightValue[] | undefined>(undefined)
  const [sourceInsightLoading, setSourceInsightLoading] = useState(false)
  const [sourceInsightDimension, setSourceInsightDimension] = useState<InsightDimension>(InsightDimension.referrer)
  const sourceInsightDimensions: InsightDimension[] = [
    InsightDimension.referrer,
    InsightDimension.utmSource,
    InsightDimension.utmMedium,
    InsightDimension.utmCampaign,
  ]
  useEffect(() => {
    fetchCategoryData(sourceInsightDimension, setSourceInsightValues, setSourceInsightLoading, 3)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [customerSegmentId, sourceInsightDimension])

  // Order
  const [orderInsightValues, setOrderInsightValues] = useState<InsightValue[] | undefined>(undefined)
  const [orderInsightLoading, setOrderInsightLoading] = useState(false)
  const [orderInsightDimension, setOrderInsightDimension] = useState<InsightDimension>(InsightDimension.orderTag)
  const orderInsightDimensions: InsightDimension[] = [InsightDimension.orderTag, InsightDimension.channel, InsightDimension.coupon]
  useEffect(() => {
    fetchCategoryData(orderInsightDimension, setOrderInsightValues, setOrderInsightLoading, 3)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [customerSegmentId, orderInsightDimension])

  // Customer
  const [customerInsightValues, setCustomerInsightValues] = useState<InsightValue[] | undefined>(undefined)
  const [customerInsightLoading, setCustomerInsightLoading] = useState(false)
  const [customerInsightDimension, setCustomerInsightDimension] = useState<InsightDimension>(InsightDimension.customerTag)
  const customerInsightDimensions: InsightDimension[] = [InsightDimension.customerTag, InsightDimension.country, InsightDimension.province]
  useEffect(() => {
    fetchCategoryData(customerInsightDimension, setCustomerInsightValues, setCustomerInsightLoading, 3)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [customerSegmentId, customerInsightDimension])

  // Order day
  const [orderDayInsightValues, setOrderDayInsightValues] = useState<InsightValue[] | undefined>(undefined)
  const [orderDayInsightLoading, setOrderDayInsightLoading] = useState(false)
  useEffect(() => {
    fetchOrderDayData(setOrderDayInsightValues, setOrderDayInsightLoading)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [customerSegmentId])

  // Order hour
  const [orderHourInsightValues, setOrderHourInsightValues] = useState<InsightValue[] | undefined>(undefined)
  const [orderHourInsightLoading, setOrderHourInsightLoading] = useState(false)
  useEffect(() => {
    fetchOrderHourData(setOrderHourInsightValues, setOrderHourInsightLoading)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [customerSegmentId])

  const fetchCategoryData = async (
    dimension: InsightDimension,
    setValues: React.Dispatch<React.SetStateAction<InsightValue[] | undefined>>,
    setLoading: React.Dispatch<React.SetStateAction<boolean>>,
    limit: number
  ) => {
    setLoading(true)
    try {
      const token = await getIdToken(authUser!)
      const { values } = await insightService.getPopularityReport(
        {
          dimension,
          customerSegmentId,
          startDate: formatDate(dayjs().subtract(12, 'months')), // 1 year
          endDate: formatDate(dayjs()),
        },
        { headers: { Authorization: `Bearer ${token}` } }
      )
      setValues(
        values.slice(0, limit).map((v, i) => ({
          name: addIndexToName(v.name, i + 1),
          customerCount: Number(v.customerCount),
          ratio: Number((v.ratio * 100).toFixed(1)),
        }))
      )
    } catch (err) {
      enqueueSnackbar(t('common.messageDataFetchError'), { severity: 'error' })
      notifySentry(err)
    } finally {
      setLoading(false)
    }
  }

  const fetchOrderDayData = async (
    setValues: React.Dispatch<React.SetStateAction<InsightValue[] | undefined>>,
    setLoading: React.Dispatch<React.SetStateAction<boolean>>
  ) => {
    setLoading(true)
    try {
      const token = await getIdToken(authUser!)
      const { values } = await insightService.getPopularityReport(
        {
          dimension: InsightDimension.weekday,
          customerSegmentId,
          startDate: formatDate(dayjs().subtract(12, 'months')), // 1 year
          endDate: formatDate(dayjs()),
        },
        { headers: { Authorization: `Bearer ${token}` } }
      )

      const weekdayOrder = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday']
      const orderedValues = weekdayOrder.map((day) => {
        const value = values.find((v) => v.name === day)
        return {
          name: t(`common.dayOfWeek.${day.toLowerCase()}`),
          customerCount: value ? Number(value.customerCount) : 0,
          ratio: value ? Number((value.ratio * 100).toFixed(1)) : 0,
        }
      })

      setValues(orderedValues)
    } catch (err) {
      enqueueSnackbar(t('common.messageDataFetchError'), { severity: 'error' })
      notifySentry(err)
    } finally {
      setLoading(false)
    }
  }

  const fetchOrderHourData = async (
    setValues: React.Dispatch<React.SetStateAction<InsightValue[] | undefined>>,
    setLoading: React.Dispatch<React.SetStateAction<boolean>>
  ) => {
    setLoading(true)
    try {
      const token = await getIdToken(authUser!)
      const { values } = await insightService.getPopularityReport(
        {
          dimension: InsightDimension.hour,
          customerSegmentId,
          startDate: formatDate(dayjs().subtract(12, 'months')), // 1 year
          endDate: formatDate(dayjs()),
        },
        { headers: { Authorization: `Bearer ${token}` } }
      )

      const hourOrder = [
        '12 AM',
        '1 AM',
        '2 AM',
        '3 AM',
        '4 AM',
        '5 AM',
        '6 AM',
        '7 AM',
        '8 AM',
        '9 AM',
        '10 AM',
        '11 AM',
        '12 PM',
        '1 PM',
        '2 PM',
        '3 PM',
        '4 PM',
        '5 PM',
        '6 PM',
        '7 PM',
        '8 PM',
        '9 PM',
        '10 PM',
        '11 PM',
      ]
      const orderedValues = hourOrder.map((hour) => {
        const value = values.find((v) => v.name === hour)
        return {
          name: hour,
          customerCount: value ? Number(value.customerCount) : 0,
          ratio: value ? Number((value.ratio * 100).toFixed(1)) : 0,
        }
      })

      setValues(orderedValues)
    } catch (err) {
      enqueueSnackbar(t('common.messageDataFetchError'), { severity: 'error' })
      notifySentry(err)
    } finally {
      setLoading(false)
    }
  }

  return (
    <>
      <Box display='flex' alignItems='center' justifyContent='space-between' marginBottom='24px'>
        <PageHeader title={t('features.insights.title')} marginBottom='0px' />
        <Autocomplete
          size='small'
          disabled={!customerSegments}
          options={customerSegments || []}
          value={customerSegments?.find((cs) => cs.ref.id === customerSegmentId) || null}
          onChange={(_, v) => handleCustomerSegmentChange(v?.name || null)}
          isOptionEqualToValue={(option, value) => option.ref.id === value.ref.id}
          getOptionLabel={(option) => option.name || ''}
          renderOption={(props, option) => (
            <li {...props} key={option.ref.id} style={{ display: 'block', wordWrap: 'break-word', overflowWrap: 'break-word' }}>
              {option.name}
            </li>
          )}
          renderInput={(params) => <TextField {...params} label={t('features.insights.detail.customerSegment')} sx={{ width: '280px' }} />}
        />
      </Box>

      <Grid container spacing={3} alignItems='stretch'>
        <Grid item xs={6}>
          <InsightNumericValues
            title={extractTextFromTextWithIcon(t('features.insights.questions.weekday_popularity'))}
            customerSegmentId={customerSegmentId}
            insightDimension={InsightDimension.weekday}
            insightValues={orderDayInsightValues}
            loading={orderDayInsightLoading}
            height={170}
          />
        </Grid>
        <Grid item xs={6}>
          <InsightNumericValues
            title={extractTextFromTextWithIcon(t('features.insights.questions.hour_popularity'))}
            customerSegmentId={customerSegmentId}
            insightDimension={InsightDimension.hour}
            insightValues={orderHourInsightValues}
            loading={orderHourInsightLoading}
            height={200}
          />
        </Grid>
        <Grid item xs={6}>
          <InsightCategoryValues
            title={t('features.insights.category_product')}
            customerSegmentId={customerSegmentId}
            insightDimension={productInsightDimension}
            insightValues={productInsightValues}
            loading={productInsightLoading}
            insightDimensions={productInsightDimensions}
            setInsightDimension={setProductInsightDimension}
          />
        </Grid>
        <Grid item xs={6}>
          <InsightCategoryValues
            title={t('features.insights.category_source')}
            customerSegmentId={customerSegmentId}
            insightDimension={sourceInsightDimension}
            insightValues={sourceInsightValues}
            loading={sourceInsightLoading}
            insightDimensions={sourceInsightDimensions}
            setInsightDimension={setSourceInsightDimension}
          />
        </Grid>
        <Grid item xs={6}>
          <InsightCategoryValues
            title={t('features.insights.category_order')}
            customerSegmentId={customerSegmentId}
            insightDimension={orderInsightDimension}
            insightValues={orderInsightValues}
            loading={orderInsightLoading}
            insightDimensions={orderInsightDimensions}
            setInsightDimension={setOrderInsightDimension}
          />
        </Grid>
        <Grid item xs={6}>
          <InsightCategoryValues
            title={t('features.insights.category_customer')}
            customerSegmentId={customerSegmentId}
            insightDimension={customerInsightDimension}
            insightValues={customerInsightValues}
            loading={customerInsightLoading}
            insightDimensions={customerInsightDimensions}
            setInsightDimension={setCustomerInsightDimension}
          />
        </Grid>
      </Grid>
    </>
  )
}
