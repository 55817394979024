import { zodResolver } from '@hookform/resolvers/zod'
import { LoadingButton } from '@mui/lab'
import { Box, Button, Dialog, FormControl, FormControlLabel, Radio, RadioGroup, Typography } from '@mui/material'
import { getIdToken } from 'firebase/auth'
import { OrderPricingRule, Shop } from 'gen/firestore'
import { ShopService } from 'gen/proto/shop/shop_connectweb'
import { useAuthUser } from 'hooks/useAuthUser'
import { useCustomSnackbar } from 'hooks/useCustomSnackbar'
import { useGrpcClient } from 'hooks/useGrpcClient'
import { useSentryNotifier } from 'hooks/useSentryNotifier'
import { FC } from 'react'
import { Controller, useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { z } from 'zod'

type Props = {
  open: boolean
  shop: Shop
  handleClose: () => void
}

export const UpdateOrderPricingRuleDialog: FC<Props> = ({ open, shop, handleClose }) => {
  const { t } = useTranslation()
  const authUser = useAuthUser()
  const { enqueueSnackbar } = useCustomSnackbar()
  const { notifySentry } = useSentryNotifier()
  const shopService = useGrpcClient(ShopService)

  const orderPricingRuleSchema = z.object({
    orderPricingRule: z.string().min(1, { message: t('features.settings.workspace.updateOrderPricingRuleDialog.messageValidationPricingRule') }),
  })

  type InputSchema = z.infer<typeof orderPricingRuleSchema>

  const {
    control,
    handleSubmit,
    formState: { isSubmitting },
  } = useForm<InputSchema>({
    resolver: zodResolver(orderPricingRuleSchema),
    defaultValues: {
      orderPricingRule: shop.orderPricingRule,
    },
  })

  const updatePricingMethod = async (input: InputSchema) => {
    try {
      const token = await getIdToken(authUser!)
      await shopService.updateOrderPricingRule({ orderPricingRule: input.orderPricingRule }, { headers: { Authorization: `Bearer ${token}` } })
      enqueueSnackbar(t('features.settings.workspace.updateOrderPricingRuleDialog.messageSaved'), { severity: 'success' })
      handleClose()
    } catch (err) {
      enqueueSnackbar(t('features.settings.workspace.updateOrderPricingRuleDialog.messageError'), { severity: 'error' })
      notifySentry(err)
    }
  }

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      maxWidth='xs'
      fullWidth
      PaperProps={{
        sx: { borderRadius: '8px' },
      }}
    >
      <Box padding='24px 24px 20px'>
        <Typography fontSize='18px' marginBottom='4px'>
          {t('features.settings.workspace.updateOrderPricingRuleDialog.title')}
        </Typography>
        <Typography fontSize='13px' color='text.secondary' marginBottom='16px'>
          {t('features.settings.workspace.updateOrderPricingRuleDialog.description')}
        </Typography>

        <Box marginBottom='20px'>
          <Controller
            control={control}
            name='orderPricingRule'
            render={({ field }) => (
              <FormControl component='fieldset'>
                <RadioGroup {...field}>
                  {Object.values(OrderPricingRule).map((rule) => (
                    <FormControlLabel
                      key={rule}
                      value={rule}
                      control={<Radio size='small' />}
                      label={<Typography fontSize='15px'>{t(`features.settings.workspace.orderPricingRule_${rule}`)}</Typography>}
                    />
                  ))}
                </RadioGroup>
              </FormControl>
            )}
          />
        </Box>

        <Box display='flex' justifyContent='flex-end' gap='12px'>
          <Button disabled={isSubmitting} onClick={handleClose} variant='outlined'>
            {t('features.settings.workspace.updateOrderPricingRuleDialog.cancel')}
          </Button>
          <LoadingButton
            type='submit'
            loading={isSubmitting}
            loadingPosition='center'
            onClick={handleSubmit(updatePricingMethod)}
            variant='contained'
          >
            {t('features.settings.workspace.updateOrderPricingRuleDialog.submit')}
          </LoadingButton>
        </Box>
      </Box>
    </Dialog>
  )
}
