import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown'
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp'
import MoreHorizIcon from '@mui/icons-material/MoreHoriz'
import { Box, Collapse, Divider, IconButton, Skeleton, TableCell, TableRow, Tooltip, Typography } from '@mui/material'
import { QuerySet } from 'features/customerSegments/querySetView/querySet'
import { CustomerSegmentState } from 'features/customerSegments/types/types'
import { SegmentGroup } from 'gen/firestore'
import { useCurrency } from 'hooks/useCurrency'
import { FC, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { generatePath, useNavigate } from 'react-router-dom'
import { AUTHORIZED_ROUTE } from 'routing'
import { extractIconFromTextWithIcon, extractTextFromTextWithIcon } from 'utils/iconUtil'
import { convertQuerySetToForm, convertStringToApiQuerySetState } from '../querySetForm/schema/converter'
import { OverviewSegmentMenu } from './overviewSegmentMenu'

type Props = {
  row: CustomerSegmentState
  group?: SegmentGroup
  dragProps?: {}
}

export const SegmentTableItem: FC<Props> = ({ row, group, dragProps }) => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const { formatCurrency } = useCurrency()

  const [collapseOpen, setCollapseOpen] = useState(false)
  const [menuOpen, setMenuOpen] = useState(false)
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)

  return (
    <>
      <TableRow sx={{ '& .MuiTableCell-root': { borderBottom: 'unset' } }} {...dragProps}>
        <TableCell>
          <IconButton size='small' onClick={() => setCollapseOpen(!collapseOpen)} sx={{ borderRadius: '8px' }}>
            {collapseOpen ? <KeyboardArrowUpIcon fontSize='small' /> : <KeyboardArrowDownIcon fontSize='small' />}
          </IconButton>
        </TableCell>
        <TableCell>
          <Box
            display='flex'
            alignItems='center'
            gap='12px'
            onClick={() => {
              const to = generatePath(AUTHORIZED_ROUTE.CUSTOMER_SEGMENT_DETAIL, { id: row.id })
              navigate(to)
            }}
            sx={{
              cursor: 'pointer',
            }}
          >
            <Typography fontSize='18px'>{extractIconFromTextWithIcon(row.name)}</Typography>
            <Typography
              fontSize='14px'
              sx={{
                transition: 'color 0.3s',
                '&:hover': {
                  color: 'text.secondary',
                },
              }}
            >
              {extractTextFromTextWithIcon(row.name)}
            </Typography>
          </Box>
        </TableCell>
        <TableCell align='center'>{row.metrics ? row.metrics.count.toLocaleString() : <Skeleton />}</TableCell>
        <TableCell align='center'>{row.metrics ? `${(row.metrics.repeaterRate * 100).toFixed(1)}%` : <Skeleton />}</TableCell>
        <TableCell align='center'>{row.metrics ? formatCurrency(row.metrics.ltv) : <Skeleton />}</TableCell>
        <TableCell align='center'>{row.metrics ? formatCurrency(row.metrics.avgOrderValue) : <Skeleton />}</TableCell>
        <TableCell align='center'>{row.metrics ? row.metrics.avgOrderCount.toFixed(2) : <Skeleton />}</TableCell>
        <TableCell align='center'>{row.metrics ? row.metrics.avgDaysBetweenOrders.toFixed(1) : <Skeleton />}</TableCell>
        <TableCell align='right'>
          <Tooltip title={t('features.customerSegments.components.segmentItem.menu')} placement='top'>
            <IconButton
              size='small'
              onClick={(e) => {
                setMenuOpen(true)
                setAnchorEl(e.currentTarget)
              }}
              sx={{ borderRadius: '8px' }}
            >
              <MoreHorizIcon fontSize='small' />
            </IconButton>
          </Tooltip>
        </TableCell>
      </TableRow>
      <TableRow {...dragProps} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={1} />
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={8}>
          <Collapse in={collapseOpen} timeout='auto' unmountOnExit>
            <Divider sx={{ marginBottom: '16px' }} />
            {row.description && (
              <Box marginBottom='8px' fontSize='13px' color='text.secondary'>
                {row.description}
              </Box>
            )}
            <Box marginBottom='16px'>
              <QuerySet querySet={convertQuerySetToForm(convertStringToApiQuerySetState(row.querySet))} />
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>

      <OverviewSegmentMenu
        open={menuOpen}
        handleClose={() => {
          setMenuOpen(false)
          setAnchorEl(null)
        }}
        anchorEl={anchorEl}
        customerSegment={row}
        group={group}
      />
    </>
  )
}
