import { Link, Tooltip } from '@mui/material'
import { getGuideUrl } from 'config/guide'
import { CustomerMetrics } from 'gen/firestore'
import { useActionTracker } from 'hooks/useMixpanel'
import { FC, ReactElement } from 'react'
import { useTranslation } from 'react-i18next'

type Props = {
  metricsKey: keyof CustomerMetrics
  element: ReactElement
}

export const MetricsTooltip: FC<Props> = ({ metricsKey, element }) => {
  const { t, i18n } = useTranslation()
  const { dispatch } = useActionTracker()

  return (
    <Tooltip
      title={
        <>
          {t('common.customerMetrics.desc', { context: metricsKey })}
          <Link
            onClick={() => {
              const guideURL = getGuideUrl(i18n.language, 'SegmentMetrics')
              dispatch('ViewGuide', { guideType: 'CustomerMetrics', url: guideURL })
              window.open(guideURL, '_blank')
            }}
            sx={{ cursor: 'pointer', marginLeft: '2px' }}
            color='inherit'
          >
            {t('common.customerMetrics.viewGuide')}
          </Link>
        </>
      }
      placement='top-start'
      arrow
      sx={{ cursor: 'default' }}
    >
      {element}
    </Tooltip>
  )
}
