import DateRangeRoundedIcon from '@mui/icons-material/DateRangeRounded'
import PercentRoundedIcon from '@mui/icons-material/PercentRounded'
import PersonRoundedIcon from '@mui/icons-material/PersonRounded'
import ShoppingBagRoundedIcon from '@mui/icons-material/ShoppingBagRounded'
import ShoppingCartRoundedIcon from '@mui/icons-material/ShoppingCartRounded'
import VolunteerActivismRoundedIcon from '@mui/icons-material/VolunteerActivismRounded'
import { Box, Grid, Paper, Skeleton, Typography } from '@mui/material'
import { CustomerMetrics } from 'gen/firestore'
import { useCurrency } from 'hooks/useCurrency'
import { FC, cloneElement } from 'react'
import { useTranslation } from 'react-i18next'
import { MetricsTooltip } from './metricsTooltip'

type Props = {
  metrics: CustomerMetrics | undefined
  type: 'grid' | 'list'
  onClick?: (key: keyof CustomerMetrics) => void
}

type MetricsItem = {
  key: keyof CustomerMetrics
  icon: JSX.Element
  value: string | undefined
}

export const MetricsItems: FC<Props> = ({ metrics, type, onClick }) => {
  const { t } = useTranslation()
  const { formatCurrency } = useCurrency()

  const metricsItems: MetricsItem[] = [
    {
      key: 'count',
      icon: <PersonRoundedIcon />,
      value: metrics?.count.toLocaleString(),
    },
    {
      key: 'repeaterRate',
      icon: <PercentRoundedIcon />,
      value: metrics && `${(metrics.repeaterRate * 100).toFixed(1)}%`,
    },
    {
      key: 'ltv',
      icon: <VolunteerActivismRoundedIcon />,
      value: metrics && formatCurrency(metrics.ltv),
    },
    {
      key: 'avgOrderValue',
      icon: <ShoppingCartRoundedIcon />,
      value: metrics && formatCurrency(metrics.avgOrderValue),
    },
    {
      key: 'avgOrderCount',
      icon: <ShoppingBagRoundedIcon />,
      value: metrics?.avgOrderCount.toFixed(2),
    },
    {
      key: 'avgDaysBetweenOrders',
      icon: <DateRangeRoundedIcon />,
      value: metrics?.avgDaysBetweenOrders.toFixed(1),
    },
  ]

  return (
    <>
      {type === 'grid' && (
        <Grid container spacing={2} alignItems='stretch'>
          {metricsItems.map((item, index) => (
            <Grid item xs={2} key={`${item.key}-${index}`}>
              <Paper
                variant='outlined'
                sx={{
                  backgroundColor: 'transparent',
                  height: '100%',
                  flex: 1,
                  padding: '16px 20px',
                  borderRadius: '12px',
                  cursor: onClick ? 'pointer' : 'default',
                }}
                onClick={() => onClick && onClick(item.key)}
              >
                <Box display='flex' flexDirection='column' justifyContent='space-between' height='100%'>
                  <Box display='flex' alignItems='center' gap='4px' marginBottom='2px'>
                    {cloneElement(item.icon, { sx: { fontSize: '14px', color: 'text.secondary' } })}
                    <MetricsTooltip
                      metricsKey={item.key}
                      element={
                        <Typography fontSize='14px' color='text.secondary'>
                          {t('common.customerMetrics.title', { context: item.key })}
                        </Typography>
                      }
                    />
                  </Box>
                  <Typography fontSize='20px'>{item.value ? item.value : <Skeleton />}</Typography>
                </Box>
              </Paper>
            </Grid>
          ))}
        </Grid>
      )}

      {type === 'list' && (
        <Grid container spacing={2.5}>
          {metricsItems.map((item, index) => (
            <Grid item xs={12} key={`${item.key}-${index}`}>
              <Box key={index} display='flex' justifyContent='space-between'>
                <Box display='flex' alignItems='center'>
                  {cloneElement(item.icon, { sx: { fontSize: '16px', color: 'text.secondary' } })}
                  <Typography variant='body2' color='textSecondary' marginLeft='12px'>
                    {t('common.customerMetrics.title', { context: item.key })}
                  </Typography>
                </Box>
                <Typography variant='body2'>{(metrics && item.value) || <Skeleton width='48px' />}</Typography>
              </Box>
            </Grid>
          ))}
        </Grid>
      )}
    </>
  )
}
