import FileDownloadOutlinedIcon from '@mui/icons-material/FileDownloadOutlined'
import NotificationImportantRoundedIcon from '@mui/icons-material/NotificationImportantRounded'
import PollOutlinedIcon from '@mui/icons-material/PollOutlined'
import TableRowsOutlinedIcon from '@mui/icons-material/TableRowsOutlined'
import {
  Box,
  Chip,
  CircularProgress,
  IconButton,
  MenuItem,
  Pagination,
  Paper,
  Select,
  Skeleton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
  Typography,
  useTheme,
} from '@mui/material'
import { EmptyState } from 'components/emptyState'
import { GuideIcon } from 'components/guideIcon'
import { MetricsItems } from 'components/metricsItems'
import { UpgradeRecommendDialog } from 'components/upgradeRecommendDialog'
import { billingStatus } from 'config/plan'
import dayjs from 'dayjs'
import { CustomerSegmentState } from 'features/customerSegments/types/types'
import { CustomerMetrics, ShopBillingStatus } from 'gen/firestore'
import { useAccount } from 'hooks/useAccount'
import { useCsvDownload } from 'hooks/useCsvDownload'
import { useCurrency } from 'hooks/useCurrency'
import { FC, useMemo, useState } from 'react'
import Chart from 'react-apexcharts'
import { useTranslation } from 'react-i18next'
import { formatDate } from 'utils/timeUtil'
import { convertTimeRangeToStartEndDate, TimeRange } from '../types/timeRange'
import { MetricsHistory } from '../types/types'

type Props = {
  customerSegment: CustomerSegmentState | undefined
  metricsHistory: MetricsHistory[] | undefined
  loading: boolean
  handleTimeRangeChange: (startDate: string, endDate: string) => void
}

enum ViewType {
  chart = 'chart',
  table = 'table',
}

export enum MetricsIndex {
  count = 'count',
  repeaterRate = 'repeaterRate',
  ltv = 'ltv',
  avgOrderValue = 'avgOrderValue',
  avgOrderCount = 'avgOrderCount',
  avgDaysBetweenOrders = 'avgDaysBetweenOrders',
}

const CHART_HEIGHT = 360

export const Metrics: FC<Props> = ({ customerSegment, metricsHistory, loading, handleTimeRangeChange }) => {
  const { t, i18n } = useTranslation()
  const { shop } = useAccount()
  const theme = useTheme()
  const shopBillingStatus = billingStatus(shop, dayjs())
  const { formatCurrency } = useCurrency()

  const [timeRange, setTimeRange] = useState(TimeRange.last7Days)
  const [viewType, setViewType] = useState(ViewType.chart)
  const [upgradeRecommendDialogOpen, setUpgradeRecommendDialogOpen] = useState(false)
  const [metricsIndex, setMetricsIndex] = useState<MetricsIndex>(MetricsIndex.count)
  const [page, setPage] = useState(0)
  const rowsPerPage = 14

  const updateTimeRangeValue = (v: TimeRange) => {
    if (shopBillingStatus === ShopBillingStatus.free) {
      setUpgradeRecommendDialogOpen(true)
      setTimeRange(TimeRange.last7Days)
      return
    }
    setTimeRange(v)
    const { startDate, endDate } = convertTimeRangeToStartEndDate(v, shop.timezone)
    handleTimeRangeChange(startDate, endDate)
  }

  const metricsIndexLabel = {
    count: t('common.customerMetrics.title_count'),
    repeaterRate: t('common.customerMetrics.title_repeaterRate'),
    ltv: t('common.customerMetrics.title_ltv'),
    avgOrderValue: t('common.customerMetrics.title_avgOrderValue'),
    avgOrderCount: t('common.customerMetrics.title_avgOrderCount'),
    avgDaysBetweenOrders: t('common.customerMetrics.title_avgDaysBetweenOrders'),
  }

  const formatCustomerMetrics = (metricsIndex: MetricsIndex, value: number): string => {
    if (metricsIndex === MetricsIndex.count) return value.toLocaleString()
    if (metricsIndex === MetricsIndex.repeaterRate) return `${(value * 100).toFixed(1)}%`
    if (metricsIndex === MetricsIndex.ltv) return formatCurrency(value) || value.toLocaleString()
    if (metricsIndex === MetricsIndex.avgOrderValue) return formatCurrency(value) || value.toLocaleString()
    if (metricsIndex === MetricsIndex.avgOrderCount) return value.toFixed(2)
    if (metricsIndex === MetricsIndex.avgDaysBetweenOrders) return value.toFixed(1)
    return value.toLocaleString()
  }

  const latestMetrics = useMemo((): CustomerMetrics | undefined => {
    if (!metricsHistory) return undefined
    if (metricsHistory.length === 0) {
      return {
        count: 0,
        repeaterRate: 0,
        ltv: 0,
        avgOrderValue: 0,
        avgOrderCount: 0,
        avgDaysBetweenOrders: 0,
      }
    }

    return metricsHistory[metricsHistory.length - 1].metrics
  }, [metricsHistory])

  const { handleDownload, loading: downloadLoading } = useCsvDownload({
    data: metricsHistory
      ? metricsHistory.map((item) => ({
          date: item.date,
          ...item.metrics,
        }))
      : [],
    headers: [
      t('features.customerSegments.detail.metrics.date'),
      t('common.customerMetrics.title_count'),
      t('common.customerMetrics.title_repeaterRate'),
      t('common.customerMetrics.title_ltv'),
      t('common.customerMetrics.title_avgOrderValue'),
      t('common.customerMetrics.title_avgOrderCount'),
      t('common.customerMetrics.title_avgDaysBetweenOrders'),
    ],
    fileName: t('features.customerSegments.detail.metrics.downloadFileName', {
      name: customerSegment?.name,
      date: formatDate(dayjs(), i18n.language),
    }),
    page: 'customerSegment_metricsHistory',
  })

  return (
    <>
      <Box marginBottom='24px'>
        <MetricsItems metrics={latestMetrics} type='grid' onClick={(key) => setMetricsIndex(key as MetricsIndex)} />
      </Box>

      <Paper sx={{ padding: '32px', borderRadius: '12px' }} variant='outlined'>
        <Box display='flex' justifyContent='space-between' alignItems='center' marginBottom='24px'>
          <Box>
            <Box display='flex' alignItems='center' marginBottom='2px'>
              <Typography fontWeight='bold'>{t('features.customerSegments.detail.metrics.title')}</Typography>
              <GuideIcon guideType='SegmentMetrics' />
            </Box>
            <Box display='flex' gap='2px'>
              {Object.entries(metricsIndexLabel).map(([key, label]) => (
                <Chip
                  key={key}
                  label={label}
                  size='small'
                  variant={key === metricsIndex ? 'filled' : 'outlined'}
                  onClick={() => setMetricsIndex(key as MetricsIndex)}
                  sx={{ cursor: 'pointer', borderRadius: '4px', fontSize: '12px', border: 'none' }}
                />
              ))}
            </Box>
          </Box>
          <Box display='flex' alignItems='center' gap='8px'>
            <Select
              size='small'
              variant='standard'
              disabled={!metricsHistory}
              value={timeRange}
              onChange={(e) => {
                const newValue = e.target.value as TimeRange
                updateTimeRangeValue(newValue)
              }}
              sx={{
                fontSize: '14px',
                marginRight: '4px',
                '& .MuiSelect-select': {
                  borderRadius: '2px',
                  paddingX: '8px',
                },
              }}
            >
              {Object.values(TimeRange).map((value) => (
                <MenuItem key={value} value={value} dense>
                  {t(`features.customerSegments.detail.timeRange_${value}`)}
                </MenuItem>
              ))}
            </Select>
            <Tooltip
              title={
                viewType === ViewType.chart
                  ? t('features.customerSegments.detail.metrics.viewTable')
                  : t('features.customerSegments.detail.metrics.viewChart')
              }
              placement='top'
            >
              <IconButton
                size='small'
                onClick={() => setViewType(viewType === ViewType.chart ? ViewType.table : ViewType.chart)}
                sx={{ borderRadius: '8px' }}
              >
                {viewType === ViewType.chart ? <TableRowsOutlinedIcon fontSize='small' /> : <PollOutlinedIcon fontSize='small' />}
              </IconButton>
            </Tooltip>
            <Tooltip title={t('features.customerSegments.detail.metrics.download')} placement='top'>
              <span>
                <IconButton
                  size='small'
                  disabled={!customerSegment || !metricsHistory || downloadLoading}
                  onClick={() => {
                    if (shopBillingStatus === ShopBillingStatus.free) {
                      setUpgradeRecommendDialogOpen(true)
                      return
                    }
                    handleDownload()
                  }}
                  sx={{ borderRadius: '8px' }}
                >
                  <FileDownloadOutlinedIcon fontSize='small' />
                </IconButton>
              </span>
            </Tooltip>
          </Box>
        </Box>

        {metricsHistory && metricsHistory.length === 1 && (
          <Paper
            variant='outlined'
            sx={{
              borderRadius: '4px',
              border: 'none',
              padding: '14px 20px',
              backgroundColor: (theme) => theme.palette.primary.main + '1A',
              marginBottom: '24px',
              marginTop: '-8px',
            }}
          >
            <Box display='flex' alignItems='center'>
              <NotificationImportantRoundedIcon fontSize='small' color='primary' sx={{ marginRight: '12px' }} />
              <Typography fontSize='14px'>{t('features.customerSegments.detail.metrics.alert')}</Typography>
            </Box>
          </Paper>
        )}

        {loading || !metricsHistory ? (
          <Box height={CHART_HEIGHT} display='flex' justifyContent='center' alignItems='center'>
            <CircularProgress size={24} />
          </Box>
        ) : metricsHistory.length === 0 ? (
          <EmptyState title={t('features.customerSegments.detail.metrics.empty')} />
        ) : viewType === ViewType.chart ? (
          <Chart
            type='area'
            height={CHART_HEIGHT}
            series={[
              {
                name: metricsIndexLabel[metricsIndex],
                data: metricsHistory.map((r) => ({ x: r.date, y: r.metrics[metricsIndex] })),
              },
            ]}
            options={{
              xaxis: {
                tickAmount: 20,
                labels: {
                  style: {
                    colors: theme.palette.text.primary,
                  },
                },
              },
              yaxis: {
                labels: {
                  formatter: (val) => {
                    return formatCustomerMetrics(metricsIndex, val)
                  },
                  style: {
                    colors: theme.palette.text.primary,
                  },
                },
              },
              tooltip: {
                theme: theme.palette.mode,
                y: {
                  formatter: (val) => {
                    return formatCustomerMetrics(metricsIndex, val)
                  },
                },
              },
              dataLabels: {
                enabled: true,
                offsetY: -6,
                background: {
                  enabled: false,
                },
                style: {
                  fontSize: '12px',
                  fontWeight: 'normal',
                },
                formatter: (val: number) => {
                  return formatCustomerMetrics(metricsIndex, val)
                },
              },
              stroke: {
                curve: 'smooth',
                width: 2,
              },
              chart: {
                zoom: { enabled: false },
                toolbar: { show: false },
                animations: {
                  enabled: false,
                },
              },
              fill: {
                type: 'gradient',
              },
              colors: [theme.palette.primary.main],
            }}
          />
        ) : (
          <>
            <TableContainer>
              <Table size='small'>
                <TableHead>
                  <TableRow
                    sx={{
                      '& .MuiTableCell-head': {
                        color: (theme) => theme.palette.text.secondary,
                        fontSize: '13px',
                        whiteSpace: 'nowrap',
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                      },
                    }}
                  >
                    <TableCell>{t('features.customerSegments.detail.metrics.date')}</TableCell>
                    <TableCell align='center'>{t('common.customerMetrics.title_count')}</TableCell>
                    <TableCell align='center'>{t('common.customerMetrics.title_repeaterRate')}</TableCell>
                    <TableCell align='center'>{t('common.customerMetrics.title_ltv')}</TableCell>
                    <TableCell align='center'>{t('common.customerMetrics.title_avgOrderValue')}</TableCell>
                    <TableCell align='center'>{t('common.customerMetrics.title_avgOrderCount')}</TableCell>
                    <TableCell align='center'>{t('common.customerMetrics.title_avgDaysBetweenOrders')}</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {metricsHistory &&
                    metricsHistory.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row) => (
                      <TableRow key={row.date}>
                        <TableCell>{row.date}</TableCell>
                        <TableCell align='center'>{row.metrics.count}</TableCell>
                        <TableCell align='center'>{`${(row.metrics.repeaterRate * 100).toFixed(1)}%`}</TableCell>
                        <TableCell align='center'>{formatCurrency(row.metrics.ltv) || <Skeleton />}</TableCell>
                        <TableCell align='center'>{formatCurrency(row.metrics.avgOrderValue) || <Skeleton />}</TableCell>
                        <TableCell align='center'>{row.metrics.avgOrderCount.toFixed(2)}</TableCell>
                        <TableCell align='center'>{row.metrics.avgDaysBetweenOrders.toFixed(1)}</TableCell>
                      </TableRow>
                    ))}
                </TableBody>
              </Table>
            </TableContainer>

            <Box display='flex' justifyContent='center'>
              <Pagination
                shape='rounded'
                count={metricsHistory ? Math.ceil(metricsHistory.length / rowsPerPage) : 0}
                page={page + 1}
                onChange={(_, v) => setPage(v - 1)}
                sx={{ marginTop: '20px' }}
              />
            </Box>
          </>
        )}
      </Paper>

      {upgradeRecommendDialogOpen && (
        <UpgradeRecommendDialog
          open={upgradeRecommendDialogOpen}
          handleClose={() => setUpgradeRecommendDialogOpen(false)}
          referrer='customerSegmentMetricsHistory'
        />
      )}
    </>
  )
}
