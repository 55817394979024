import ArrowForwardIcon from '@mui/icons-material/ArrowForward'
import { Box, Button, CircularProgress, Paper, Typography, useTheme } from '@mui/material'
import { EmptyState } from 'components/emptyState'
import { InsightDimension } from 'gen/firestore'
import { FC } from 'react'
import Chart from 'react-apexcharts'
import { useTranslation } from 'react-i18next'
import { generatePath } from 'react-router-dom'
import { AUTHORIZED_ROUTE } from 'routing'
import { extractTextFromTextWithIcon } from 'utils/iconUtil'
import { InsightValue } from '../insights'

type Props = {
  title: string
  customerSegmentId: string
  insightDimension: InsightDimension
  insightValues: InsightValue[] | undefined
  loading: boolean
  height: number
}

export const InsightNumericValues: FC<Props> = ({ title, customerSegmentId, insightDimension, insightValues, loading, height }) => {
  const { t } = useTranslation()
  const theme = useTheme()

  return (
    <Paper variant='outlined' sx={{ borderRadius: '8px', padding: '28px 24px 0px', height: '100%' }}>
      <Box display='flex' alignItems='center' justifyContent='space-between'>
        <Typography>{title}</Typography>
        <Button
          size='small'
          endIcon={<ArrowForwardIcon fontSize='small' />}
          sx={{ padding: 0 }}
          onClick={() => {
            const to = generatePath(AUTHORIZED_ROUTE.INSIGHT_DETAIL, { id: insightDimension }) + `?customer_segment_id=${customerSegmentId}`
            window.open(to, '_blank')
          }}
        >
          {t('features.customerSegments.detail.insights.viewMore')}
        </Button>
      </Box>
      {loading || !insightValues ? (
        <Box height={height} display='flex' justifyContent='center' alignItems='center'>
          <CircularProgress size={24} />
        </Box>
      ) : insightValues.length === 0 ? (
        <EmptyState title={t('features.customerSegments.detail.insights.empty')} />
      ) : (
        <Chart
          options={{
            chart: {
              type: 'bar',
              toolbar: {
                show: false,
              },
            },
            xaxis: {
              categories: insightValues.map((v) => extractTextFromTextWithIcon(v.name)),
            },
            yaxis: {
              show: false,
            },
            plotOptions: {
              bar: {
                horizontal: false,
                borderRadius: 4,
                columnWidth: '75%',
              },
            },
            dataLabels: {
              enabled: false,
            },
            colors: [theme.palette.primary.main],
            grid: {
              yaxis: {
                lines: {
                  show: false,
                },
              },
            },
            tooltip: {
              y: {
                formatter: (value) => `${value}%`,
              },
            },
          }}
          series={[
            {
              name: t('features.insights.detail.popularityInsight.ratio'),
              data: insightValues.map((v) => v.ratio),
            },
          ]}
          type='bar'
          height={height}
        />
      )}
    </Paper>
  )
}
