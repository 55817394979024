import { Code, ConnectError } from '@bufbuild/connect-web'
import AutoFixHighOutlinedIcon from '@mui/icons-material/AutoFixHighOutlined'
import { LoadingButton } from '@mui/lab'
import { Box, Button, Chip, Dialog, TextField, Tooltip, Typography } from '@mui/material'
import { getIdToken } from 'firebase/auth'
import { AIAssistService } from 'gen/proto/ai_assist/ai_assist_connectweb'
import { useAuthUser } from 'hooks/useAuthUser'
import { useCustomSnackbar } from 'hooks/useCustomSnackbar'
import { useGrpcClient } from 'hooks/useGrpcClient'
import { useActionTracker } from 'hooks/useMixpanel'
import { useSentryNotifier } from 'hooks/useSentryNotifier'
import { useTypingEffect } from 'hooks/useTypingEffect'
import { FC, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { generatePath } from 'react-router-dom'
import { AUTHORIZED_ROUTE } from 'routing'

type Props = {
  open: boolean
  handleClose: () => void
  segmentId: string
  segmentName: string
}

const MAX_INPUT_LENGTH = 500

export const SuggestInsightDialog: FC<Props> = ({ open, handleClose, segmentId, segmentName }) => {
  const { t } = useTranslation()
  const authUser = useAuthUser()
  const aiAssistService = useGrpcClient(AIAssistService)
  const { enqueueSnackbar } = useCustomSnackbar()
  const { notifySentry } = useSentryNotifier()
  const { dispatch } = useActionTracker()

  const [isLoading, setIsLoading] = useState(false)
  const [inputText, setInputText] = useState('')
  const [answerText, setAnswerText] = useState('')
  const [insightDimension, setInsightDimension] = useState('')
  const [insightReportType, setInsightReportType] = useState('')

  const displayedText = useTypingEffect(answerText, 25)

  const handleSubmit = async () => {
    setIsLoading(true)
    try {
      const token = await getIdToken(authUser!)
      const response = await aiAssistService.provideInsightForCustomerSegment(
        { customerSegmentId: segmentId, question: inputText },
        { headers: { Authorization: `Bearer ${token}` } }
      )
      setAnswerText(response.answer)
      setInsightDimension(response.dimension)
      setInsightReportType(response.reportType)
      dispatch('SuggestInsightByAI', {
        question: inputText,
        answer: response.answer,
        insightReport: `${response.dimension}-${response.reportType}`,
        customerSegmentName: segmentName,
      })
    } catch (err) {
      if (err instanceof ConnectError && err.code === Code.FailedPrecondition) {
        setAnswerText(t('features.customerSegments.detail.suggestInsightDialog.failedPrecondition'))
      } else if (err instanceof ConnectError && err.code === Code.Unavailable) {
        setAnswerText(t('features.customerSegments.detail.suggestInsightDialog.unavailable'))
      } else {
        enqueueSnackbar(t('features.customerSegments.detail.suggestInsightDialog.messageError'), { severity: 'error' })
        notifySentry(err)
      }
    } finally {
      setIsLoading(false)
    }
  }

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      maxWidth='xs'
      fullWidth
      PaperProps={{
        sx: { borderRadius: '8px' },
      }}
    >
      <Box padding='24px 24px 20px'>
        <Box display='flex' alignItems='center' gap='8px' marginBottom='16px'>
          <AutoFixHighOutlinedIcon fontSize='small' color='primary' />
          <Typography fontSize='18px'>{t('features.customerSegments.detail.suggestInsightDialog.title')}</Typography>
          <Tooltip title={t('common.beta.description')} placement='right-end'>
            <Chip size='small' label={t('common.beta.title')} variant='outlined' />
          </Tooltip>
        </Box>

        <Box>
          {answerText.length > 0 ? (
            <Box>
              <Typography marginBottom='20px'>{displayedText}</Typography>
            </Box>
          ) : (
            <TextField
              fullWidth
              multiline
              rows={4}
              variant='outlined'
              placeholder={t('features.customerSegments.detail.suggestInsightDialog.description')}
              value={inputText}
              onChange={(e) => setInputText(e.target.value)}
              sx={{ marginBottom: '20px' }}
            />
          )}
        </Box>

        <Box display='flex' justifyContent='flex-end' gap='12px'>
          {answerText.length > 0 ? (
            <>
              <Button variant='outlined' onClick={() => setAnswerText('')}>
                {t('features.customerSegments.detail.suggestInsightDialog.askAgain')}
              </Button>
              {insightDimension.length > 0 && insightReportType.length > 0 && (
                <Button
                  variant='contained'
                  onClick={() => {
                    const path = generatePath(AUTHORIZED_ROUTE.INSIGHT_DETAIL, { id: insightDimension })
                    const queryParams = `?reportType=${insightReportType}&customer_segment_id=${segmentId}`
                    window.open(`${path}${queryParams}`, '_blank')
                  }}
                >
                  {t('features.customerSegments.detail.suggestInsightDialog.viewReport')}
                </Button>
              )}
            </>
          ) : (
            <>
              <Button disabled={isLoading} onClick={handleClose} variant='outlined'>
                {t('features.customerSegments.detail.suggestInsightDialog.cancel')}
              </Button>
              <LoadingButton
                type='submit'
                disabled={isLoading || inputText.length === 0 || inputText.length > MAX_INPUT_LENGTH}
                loading={isLoading}
                loadingPosition='center'
                onClick={handleSubmit}
                variant='contained'
              >
                {t('features.customerSegments.detail.suggestInsightDialog.ask')}
              </LoadingButton>
            </>
          )}
        </Box>
      </Box>
    </Dialog>
  )
}
