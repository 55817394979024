import { Box, Breadcrumbs, Chip, Link, Typography } from '@mui/material'
import { GuideType } from 'config/guide'
import { FC } from 'react'
import { useNavigate } from 'react-router-dom'
import { GuideIcon } from './guideIcon'

type Props = {
  title: string
  breadcrumbItems?: BreadcrumbItem[]
  marginBottom: string
  isBeta?: boolean
  guideType?: GuideType
}

type BreadcrumbItem = {
  label: string
  path: string
}

// PageHeader: Component for displaying content in the top area of the page
export const PageHeader: FC<Props> = ({ title, breadcrumbItems = [], marginBottom, isBeta, guideType }) => {
  const navigate = useNavigate()

  return (
    <Box sx={{ marginBottom: marginBottom }}>
      {breadcrumbItems.length > 0 && (
        <Breadcrumbs separator='›' sx={{ marginBottom: '12px' }}>
          {breadcrumbItems.map((item) => {
            if (item.path !== '') {
              return (
                <Link
                  key={item.label}
                  underline='hover'
                  color='inherit'
                  sx={{ fontSize: '12px', cursor: 'pointer', lineHeight: '2.0' }}
                  onClick={() => navigate(item.path)}
                >
                  {item.label}
                </Link>
              )
            } else {
              return (
                <Typography key={item.label} fontSize='12px' color='text.primary'>
                  {item.label}
                </Typography>
              )
            }
          })}
        </Breadcrumbs>
      )}

      <Box display='flex' alignItems='center'>
        <Typography variant='h2' fontSize='18px' fontWeight='500'>
          {title}
        </Typography>
        {isBeta && <Chip size='small' label='BETA' variant='outlined' sx={{ marginLeft: '6px' }} />}
        {guideType && <GuideIcon guideType={guideType} />}
      </Box>
    </Box>
  )
}
