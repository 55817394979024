import { LoadingButton } from '@mui/lab'
import { Alert, Box, Button, Dialog, Link, Typography } from '@mui/material'
import { getIdToken } from 'firebase/auth'
import { CustomerSegmentService } from 'gen/proto/customer_segment/customer_segment_connectweb'
import { useAuthUser } from 'hooks/useAuthUser'
import { useCustomSnackbar } from 'hooks/useCustomSnackbar'
import { useGrpcClient } from 'hooks/useGrpcClient'
import { useSentryNotifier } from 'hooks/useSentryNotifier'
import { FC, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { generatePath, useNavigate } from 'react-router-dom'
import { AUTHORIZED_ROUTE } from 'routing'

type Props = {
  open: boolean
  handleClose: () => void
  id: string
  name: string
  tagOperationSettingIsActive: boolean
  redirect?: boolean
}

export const DeleteDialog: FC<Props> = ({ open, handleClose, id, name, tagOperationSettingIsActive, redirect }) => {
  const { t } = useTranslation()
  const authUser = useAuthUser()
  const navigate = useNavigate()
  const customerSegmentService = useGrpcClient(CustomerSegmentService)
  const { enqueueSnackbar } = useCustomSnackbar()
  const { notifySentry } = useSentryNotifier()

  const [isLoading, setIsLoading] = useState(false)

  const handleSubmit = async () => {
    setIsLoading(true)
    try {
      const token = await getIdToken(authUser!)
      await customerSegmentService.delete({ customerSegmentId: id }, { headers: { Authorization: `Bearer ${token}` } })
      if (redirect) {
        navigate(AUTHORIZED_ROUTE.CUSTOMER_SEGMENTS)
      } else {
        handleClose()
      }
      enqueueSnackbar(t('features.customerSegments.components.deleteDialog.messageSaved'), { severity: 'success' })
    } catch (err) {
      enqueueSnackbar(t('features.customerSegments.components.deleteDialog.messageError'), { severity: 'error' })
      notifySentry(err)
    } finally {
      setIsLoading(false)
    }
  }

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      maxWidth='xs'
      fullWidth
      PaperProps={{
        sx: { borderRadius: '8px' },
      }}
    >
      <Box padding='24px 24px 20px'>
        <Typography fontSize='18px' marginBottom='16px'>
          {t('features.customerSegments.components.deleteDialog.title')}
        </Typography>
        {tagOperationSettingIsActive && (
          <Alert severity='warning' sx={{ marginBottom: '16px' }}>
            {t('features.customerSegments.components.deleteDialog.alert')}
            <Link
              onClick={() => {
                const to = generatePath(AUTHORIZED_ROUTE.CUSTOMER_SEGMENT_DETAIL, { id: id })
                navigate(`${to}#sync`)
              }}
              sx={{ cursor: 'pointer', marginLeft: '4px' }}
            >
              {t('features.customerSegments.components.deleteDialog.alertLinkText')}
            </Link>
          </Alert>
        )}
        <Typography fontSize='14px' color='text.secondary' marginBottom='20px'>
          {t('features.customerSegments.components.deleteDialog.description', { name: name })}
        </Typography>
        <Box display='flex' justifyContent='flex-end' gap='12px'>
          <Button disabled={isLoading} onClick={handleClose} variant='outlined'>
            {t('features.customerSegments.components.deleteDialog.cancel')}
          </Button>
          <LoadingButton
            type='submit'
            disabled={tagOperationSettingIsActive}
            loading={isLoading}
            loadingPosition='center'
            onClick={handleSubmit}
            variant='contained'
          >
            {t('features.customerSegments.components.deleteDialog.submit')}
          </LoadingButton>
        </Box>
      </Box>
    </Dialog>
  )
}
