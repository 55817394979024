import { getIdToken } from '@firebase/auth'
import { CustomerSegmentService } from 'gen/proto/customer_segment/customer_segment_connectweb'
import { useAuthUser } from 'hooks/useAuthUser'
import { useCustomSnackbar } from 'hooks/useCustomSnackbar'
import { useGrpcClient } from 'hooks/useGrpcClient'
import { useActionTracker } from 'hooks/useMixpanel'
import { useSentryNotifier } from 'hooks/useSentryNotifier'
import csvDownload from 'json-to-csv-export'
import { useState, useCallback, useMemo } from 'react'
import { useTranslation } from 'react-i18next'

type Props = {
  customerSegmentId: string | undefined
  fileName: string
  page: string
}

export const useCustomersDownload = ({ customerSegmentId, fileName, page }: Props) => {
  const { t } = useTranslation()
  const { enqueueSnackbar } = useCustomSnackbar()
  const { notifySentry } = useSentryNotifier()
  const { dispatch } = useActionTracker()
  const authUser = useAuthUser()
  const customerSegmentService = useGrpcClient(CustomerSegmentService)

  const [loading, setLoading] = useState(false)

  const headers = useMemo(
    () => [
      t('features.customerSegments.detail.customers.customerId'),
      t('features.customerSegments.detail.customers.email'),
      t('features.customerSegments.detail.customers.name'),
      t('features.customerSegments.detail.customers.recency'),
      t('features.customerSegments.detail.customers.frequency'),
      t('features.customerSegments.detail.customers.monetary'),
    ],
    [t]
  )

  const fetchCustomers = useCallback(async () => {
    if (!customerSegmentId) return []

    const token = await getIdToken(authUser!)
    const resp = await customerSegmentService.getCustomers(
      { customerSegmentId: customerSegmentId },
      { headers: { Authorization: `Bearer ${token}` } }
    )
    return resp.customers.map((c) => ({
      id: c.id,
      email: c.email,
      name: t('common.customer.fullName', { firstName: c.firstName, lastName: c.lastName }),
      recency: c.recency > 0 ? Number(c.recency) : 0,
      frequency: Number(c.frequency),
      monetary: Number(c.monetary),
    }))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [customerSegmentId])

  const handleDownload = useCallback(async () => {
    if (!customerSegmentId) return

    try {
      setLoading(true)
      const customers = await fetchCustomers()
      csvDownload({
        data: customers,
        filename: fileName,
        headers: headers,
        delimiter: ',',
      })
      dispatch('DownloadCSV', { page: page })
    } catch (err) {
      enqueueSnackbar(t('common.messageDataFetchError'), { severity: 'error' })
      notifySentry(err)
    } finally {
      setLoading(false)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [customerSegmentId])

  return {
    handleDownload,
    loading,
  }
}
