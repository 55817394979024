import ArrowBackIcon from '@mui/icons-material/ArrowBack'
import FileDownloadOutlinedIcon from '@mui/icons-material/FileDownloadOutlined'
import {
  Box,
  IconButton,
  ListItemText,
  ListSubheader,
  Menu,
  MenuItem,
  MenuList,
  Skeleton,
  ToggleButton,
  ToggleButtonGroup,
  Tooltip,
  Typography,
} from '@mui/material'
import { GuideIcon } from 'components/guideIcon'
import { UpgradeRecommendDialog } from 'components/upgradeRecommendDialog'
import { billingStatus } from 'config/plan'
import dayjs from 'dayjs'
import { convertQuerySetToForm, convertStringToApiQuerySetState } from 'features/customerSegments/querySetForm/schema/converter'
import { generateNumericQuerySet } from 'features/insights/types/querySet'
import { getIdToken } from 'firebase/auth'
import { orderBy, query } from 'firebase/firestore'
import { InsightDimension, InsightReportType, ShopBillingStatus } from 'gen/firestore'
import { InsightService } from 'gen/proto/insight/insight_connectweb'
import { useAccount } from 'hooks/useAccount'
import { useAuthUser } from 'hooks/useAuthUser'
import { useSubCollectionRef } from 'hooks/useCollectionRef'
import { useCsvDownload } from 'hooks/useCsvDownload'
import { useCustomSnackbar } from 'hooks/useCustomSnackbar'
import { useCollection } from 'hooks/useFirestoreData'
import { useGrpcClient } from 'hooks/useGrpcClient'
import { useActionTracker } from 'hooks/useMixpanel'
import { useSentryNotifier } from 'hooks/useSentryNotifier'
import { useEffect, useMemo, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { generatePath, useLocation, useNavigate, useParams } from 'react-router-dom'
import { AUTHORIZED_ROUTE } from 'routing'
import { formatYearMonth } from 'utils/timeUtil'
import { ReportTypeIcon } from '../components/reportTypeIcon'
import { InsightDrawerState, InsightFilterState } from '../types/insight'
import { InsightCategory, InsightDimensionProfile, insightDimensions } from '../types/insightDimensions'
import { generateQuerySet } from '../types/querySet'
import { InsightDrawer } from './components/components/insightDrawer'
import { ViewType, ViewTypeToggleButton } from './components/components/viewTypeToggleButton'
import { HistogramInsight } from './components/histogramInsight'
import { InsightFilter } from './components/insightFilter'
import { MonthlyInsight } from './components/monthlyInsight'
import { NthOrderInsight } from './components/nthOrderInsight'
import { OrderCountInsight } from './components/orderCountInsight'
import { PopularityInsight } from './components/popularityInsight'

export type PopularityInsightValue = {
  rank: number
  name: string
  customerCount: number
  ratio: number
  isVisible: boolean
}
export type MonthlyInsightValue = {
  rank: number
  name: string
  values: {
    yearMonth: string
    customerCount: number
  }[]
  isVisible: boolean
}

export type NthOrderInsightValue = {
  rank: number
  name: string
  first: number
  second: number
  secondPlus: number
  third: number
  fourth: number
  fifthPlus: number
  total: number
  isVisible: boolean
}

export type OrderCountInsightValue = {
  rank: number
  name: string
  once: number
  twice: number
  twicePlus: number
  threeTimes: number
  fourTimes: number
  fiveTimesPlus: number
  total: number
  isVisible: boolean
}

export type HistogramInsightValue = {
  values: {
    min: number
    max: number
    customerCount: number
  }[]
  mean: number
  median: number
  max: number
}

export const Detail = () => {
  const { t, i18n } = useTranslation()
  const navigate = useNavigate()
  const location = useLocation()
  const { dispatch } = useActionTracker()
  const { account, shop } = useAccount()
  const authUser = useAuthUser()
  const { enqueueSnackbar } = useCustomSnackbar()
  const { notifySentry } = useSentryNotifier()
  const insightService = useGrpcClient(InsightService)
  const shopBillingStatus = billingStatus(shop, dayjs())
  const { customerSegmentsRef } = useSubCollectionRef(account.shopRef!.id)
  const { data: customerSegments } = useCollection(query(customerSegmentsRef, orderBy('createdAt', 'desc')))

  const params = useParams()
  const searchParams = new URLSearchParams(location.search)

  const viewTypeParam = searchParams.get('reportType') as InsightReportType | undefined

  const [dimensionMenuOpen, setDimensionMenuOpen] = useState(false)
  const dimensionMenuAnchorRef = useRef<HTMLButtonElement | null>(null)

  const [viewType, setViewType] = useState<ViewType>(ViewType.visual)
  const [loading, setLoading] = useState(false)
  const [insightDrawerState, setInsightDrawerState] = useState<InsightDrawerState>({ open: false })
  const [upgradeRecommendDialogOpen, setUpgradeRecommendDialogOpen] = useState(false)

  const [insightFilter, setInsightFilter] = useState<InsightFilterState>({
    customerSegmentId: searchParams.get('customer_segment_id') || undefined,
    startDate: searchParams.get('start_date') || undefined,
    endDate: searchParams.get('end_date') || undefined,
  })

  const insightDimension: InsightDimension | undefined = useMemo(() => {
    return params.id as InsightDimension
  }, [params.id])

  const dimensionProfile: InsightDimensionProfile | undefined = useMemo(() => {
    return insightDimensions.find((d) => d.name === insightDimension)
  }, [insightDimension])

  const reportType: InsightReportType | undefined = useMemo(() => {
    if (!dimensionProfile) return
    if (viewTypeParam && dimensionProfile.reportTypes.includes(viewTypeParam)) {
      return viewTypeParam
    }
    return dimensionProfile.reportTypes[0]
  }, [dimensionProfile, viewTypeParam])

  useEffect(() => {
    if (!insightDimension || !dimensionProfile) return

    const isValidReportType = viewTypeParam && dimensionProfile.reportTypes.includes(viewTypeParam)

    if (isValidReportType) return
    const newReportType = dimensionProfile.reportTypes[0]
    if (viewTypeParam === newReportType) return

    searchParams.set('reportType', newReportType)
    if (insightFilter.customerSegmentId) searchParams.set('customer_segment_id', insightFilter.customerSegmentId)

    const to =
      generatePath(AUTHORIZED_ROUTE.INSIGHT_DETAIL, {
        id: insightDimension,
      }) + (searchParams.toString() ? `?${searchParams.toString()}` : '')
    navigate(to)

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchParams])

  useEffect(() => {
    if (!insightDimension) return
    const updateQueryParams = () => {
      if (viewTypeParam) searchParams.set('reportType', viewTypeParam)
      if (insightFilter.customerSegmentId) {
        searchParams.set('customer_segment_id', insightFilter.customerSegmentId)
      } else {
        searchParams.delete('customer_segment_id')
      }
      const to =
        generatePath(AUTHORIZED_ROUTE.INSIGHT_DETAIL, {
          id: insightDimension,
        }) + `?${searchParams.toString()}` || ''
      navigate(to)
    }
    updateQueryParams()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [insightFilter.customerSegmentId])

  useEffect(() => {
    if (!insightDimension || !reportType || !insightFilter.customerSegmentId) return
    const customerSegmentName = (customerSegments && customerSegments.find((cs) => cs.ref.id === insightFilter.customerSegmentId)?.name) || 'all'
    dispatch('ViewInsight', { dimension: insightDimension, reportType: reportType, customerSegment: customerSegmentName })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [insightDimension, reportType, insightFilter.customerSegmentId])

  // popularity insight
  const [popularityInsightValues, setPopularityInsightValues] = useState<PopularityInsightValue[]>()
  const DEFAULT_VISIBLE_COUNT = 3

  const handleChartDataPointClickForPopularityInsight = (name: string) => {
    const queryFilters = [
      {
        filter_type: 'order_date' as const,
        operator: 'date_range' as const,
        value: { min_date: insightFilter.startDate, max_date: insightFilter.endDate },
      },
    ]
    const segmentQuerySet = customerSegments && customerSegments.find((segment) => segment.ref.id === insightFilter.customerSegmentId)?.querySet
    const additionalQuerySet = segmentQuerySet ? convertQuerySetToForm(convertStringToApiQuerySetState(segmentQuerySet)) : undefined
    const querySet = generateQuerySet(insightDimension, name, queryFilters, additionalQuerySet)
    if (querySet) {
      setInsightDrawerState({ open: true, querySet })
    }
  }

  const { handleDownload: handleDownloadPopularity, loading: downloadLoadingForPopularity } = useCsvDownload({
    data:
      popularityInsightValues &&
      popularityInsightValues.filter(({ isVisible }) => isVisible).map(({ name, customerCount, ratio }) => ({ name, customerCount, ratio })),
    headers: [t('features.insights.detail.name'), t('features.insights.detail.customerCount'), t('features.insights.detail.popularityInsight.ratio')],
    fileName: t('features.insights.detail.popularityInsight.downloadFileName', { dimension: insightDimension }),
    page: 'insight_' + insightDimension,
  })

  // monthly insight
  const [monthlyInsightValues, setMonthlyInsightValues] = useState<MonthlyInsightValue[]>()

  const handleChartDataPointClickForMonthlyInsight = (name: string, month: string) => {
    const queryFilters = [
      {
        filter_type: 'order_date' as const,
        operator: 'date_range' as const,
        value: {
          min_date: dayjs(month).startOf('month').format('YYYY-MM-DD'),
          max_date: dayjs(month).endOf('month').format('YYYY-MM-DD'),
        },
      },
    ]
    const segmentQuerySet = customerSegments && customerSegments.find((segment) => segment.ref.id === insightFilter.customerSegmentId)?.querySet
    const additionalQuerySet = segmentQuerySet ? convertQuerySetToForm(convertStringToApiQuerySetState(segmentQuerySet)) : undefined
    const querySet = generateQuerySet(insightDimension, name, queryFilters, additionalQuerySet)
    if (querySet) {
      setInsightDrawerState({ open: true, querySet })
    }
  }

  const { handleDownload: handleDownloadMonthly, loading: downloadLoadingForMonthly } = useCsvDownload({
    data:
      monthlyInsightValues &&
      monthlyInsightValues
        .filter(({ isVisible }) => isVisible)
        .flatMap(({ name, values }) =>
          values.map(({ yearMonth, customerCount }) => ({
            name,
            yearMonth,
            customerCount,
          }))
        ),
    headers: [
      t('features.insights.detail.name'),
      t('features.insights.detail.monthlyInsight.yearMonth'),
      t('features.insights.detail.customerCount'),
    ],
    fileName: t('features.insights.detail.monthlyInsight.downloadFileName', { dimension: insightDimension }),
    page: 'insight_' + insightDimension,
  })

  // nthOrder insight
  const [nthOrderInsightValues, setNthOrderInsightValues] = useState<NthOrderInsightValue[]>()

  const handleChartDataPointClickForNthOrder = (name: string, index: number) => {
    const sequenceMap: { [key: number]: { min_sequence: number; max_sequence?: number; ascending: boolean } } = {
      0: { min_sequence: 1, max_sequence: 1, ascending: true },
      1: { min_sequence: 2, ascending: true },
      2: { min_sequence: 2, max_sequence: 2, ascending: true },
      3: { min_sequence: 3, max_sequence: 3, ascending: true },
      4: { min_sequence: 4, max_sequence: 4, ascending: true },
      5: { min_sequence: 5, ascending: true },
    }
    const queryFilters = [
      { filter_type: 'order_sequence' as const, operator: 'specify_sequence' as const, value: sequenceMap[index] },
      {
        filter_type: 'order_date' as const,
        operator: 'date_range' as const,
        value: { min_date: insightFilter.startDate, max_date: insightFilter.endDate },
      },
    ]
    const segmentQuerySet = customerSegments && customerSegments.find((segment) => segment.ref.id === insightFilter.customerSegmentId)?.querySet
    const additionalQuerySet = segmentQuerySet ? convertQuerySetToForm(convertStringToApiQuerySetState(segmentQuerySet)) : undefined
    const querySet = generateQuerySet(insightDimension, name, queryFilters, additionalQuerySet)
    if (querySet) {
      setInsightDrawerState({ open: true, querySet })
    }
  }

  const { handleDownload: handleDownloadNthOrder, loading: downloadLoadingForNthOrder } = useCsvDownload({
    data:
      nthOrderInsightValues &&
      nthOrderInsightValues
        .filter(({ isVisible }) => isVisible)
        .map(({ name, total, first, second, secondPlus, third, fourth, fifthPlus }) => ({
          name,
          total,
          first,
          second,
          secondPlus,
          third,
          fourth,
          fifthPlus,
        })),
    headers: [
      t('features.insights.detail.name'),
      t('features.insights.detail.customerCount'),
      t('features.insights.detail.nthOrderInsight.first'),
      t('features.insights.detail.nthOrderInsight.second'),
      t('features.insights.detail.nthOrderInsight.secondPlus'),
      t('features.insights.detail.nthOrderInsight.third'),
      t('features.insights.detail.nthOrderInsight.fourth'),
      t('features.insights.detail.nthOrderInsight.fifthPlus'),
    ],
    fileName: t('features.insights.detail.nthOrderInsight.downloadFileName', { dimension: insightDimension }),
    page: 'insight_' + insightDimension,
  })

  // orderCount insight
  const [oderCountInsightValues, setOderCountInsightValues] = useState<OrderCountInsightValue[]>()

  const handleChartDataPointClickForOrderCount = (name: string, index: number) => {
    const queryFilters = [
      {
        filter_type: 'order_count' as const,
        operator: 'int_range' as const,
        value: index === 5 ? { min: index } : { min: index, max: index },
      },
      {
        filter_type: 'order_date' as const,
        operator: 'date_range' as const,
        value: { min_date: insightFilter.startDate, max_date: insightFilter.endDate },
      },
    ]
    const segmentQuerySet = customerSegments && customerSegments.find((segment) => segment.ref.id === insightFilter.customerSegmentId)?.querySet
    const additionalQuerySet = segmentQuerySet ? convertQuerySetToForm(convertStringToApiQuerySetState(segmentQuerySet)) : undefined
    const querySet = generateQuerySet(insightDimension, name, queryFilters, additionalQuerySet)
    if (querySet) {
      setInsightDrawerState({ open: true, querySet })
    }
  }

  const { handleDownload: handleDownloadOrderCount, loading: downloadLoadingForOrderCount } = useCsvDownload({
    data:
      oderCountInsightValues &&
      oderCountInsightValues
        .filter(({ isVisible }) => isVisible)
        .map(({ name, total, once, twicePlus, twice, threeTimes, fourTimes, fiveTimesPlus }) => ({
          name,
          total,
          once,
          twicePlus,
          twice,
          threeTimes,
          fourTimes,
          fiveTimesPlus,
        })),
    headers: [
      t('features.insights.detail.name'),
      t('features.insights.detail.customerCount'),
      t('features.insights.detail.orderCountInsight.once'),
      t('features.insights.detail.orderCountInsight.twicePlus'),
      t('features.insights.detail.orderCountInsight.twice'),
      t('features.insights.detail.orderCountInsight.threeTimes'),
      t('features.insights.detail.orderCountInsight.fourTimes'),
      t('features.insights.detail.orderCountInsight.fiveTimesPlus'),
    ],
    fileName: t('features.insights.detail.orderCountInsight.downloadFileName', { dimension: insightDimension }),
    page: 'insight_' + insightDimension,
  })

  // histogram insight
  const [histogramInsightValues, setHistogramInsightValues] = useState<HistogramInsightValue>()

  const handleChartDataPointClickForHistogram = (min: number, max: number) => {
    const queryFilter = {
      filter_type: 'order_date' as const,
      operator: 'date_range' as const,
      value: { min_date: insightFilter.startDate, max_date: insightFilter.endDate },
    }
    const segmentQuerySet = customerSegments && customerSegments.find((segment) => segment.ref.id === insightFilter.customerSegmentId)?.querySet
    const additionalQuerySet = segmentQuerySet ? convertQuerySetToForm(convertStringToApiQuerySetState(segmentQuerySet)) : undefined
    const querySet = generateNumericQuerySet(insightDimension, min, max, queryFilter, additionalQuerySet)
    if (querySet) {
      setInsightDrawerState({ open: true, querySet })
    }
  }

  const { handleDownload: handleDownloadHistogram, loading: downloadLoadingForHistogram } = useCsvDownload({
    data:
      histogramInsightValues &&
      histogramInsightValues.values.map(({ min, max, customerCount }) => ({
        range: `${min} - ${max}`,
        customerCount,
      })),
    headers: [t('features.insights.detail.histogramInsight.range'), t('features.insights.detail.customerCount')],
    fileName: t('features.insights.detail.histogramInsight.downloadFileName', { dimension: insightDimension }),
    page: 'insight_' + insightDimension,
  })

  // fetch data
  useEffect(() => {
    const fetchData = async () => {
      if (!insightFilter.startDate || !insightFilter.endDate) return
      setLoading(true)
      try {
        const token = await getIdToken(authUser!)
        if (viewTypeParam === InsightReportType.popularity) {
          const resp = await insightService.getPopularityReport(
            {
              customerSegmentId: insightFilter.customerSegmentId || '',
              dimension: insightDimension,
              startDate: insightFilter.startDate,
              endDate: insightFilter.endDate,
            },
            { headers: { Authorization: `Bearer ${token}` } }
          )
          setPopularityInsightValues(
            resp.values.map((v, i) => ({
              rank: i + 1,
              name: v.name,
              customerCount: Number(v.customerCount),
              ratio: Number((v.ratio * 100).toFixed(2)),
              isVisible: shopBillingStatus !== ShopBillingStatus.free || i < DEFAULT_VISIBLE_COUNT,
            }))
          )
        } else if (viewTypeParam === InsightReportType.monthly) {
          const resp = await insightService.getMonthlyReport(
            {
              customerSegmentId: insightFilter.customerSegmentId || '',
              dimension: insightDimension,
              startDate: insightFilter.startDate,
              endDate: insightFilter.endDate,
            },
            { headers: { Authorization: `Bearer ${token}` } }
          )
          setMonthlyInsightValues(
            resp.values.map((v, i) => ({
              rank: i + 1,
              name: v.name,
              values: v.monthlyValues.map((v) => ({
                yearMonth: formatYearMonth(dayjs(v.yearMonth), i18n.language),
                customerCount: Number(v.customerCount),
              })),
              isVisible: shopBillingStatus !== ShopBillingStatus.free || i < DEFAULT_VISIBLE_COUNT,
            }))
          )
        } else if (viewTypeParam === InsightReportType.nthOrder) {
          const resp = await insightService.getNthOrderReport(
            {
              customerSegmentId: insightFilter.customerSegmentId || '',
              dimension: insightDimension,
              startDate: insightFilter.startDate,
              endDate: insightFilter.endDate,
            },
            { headers: { Authorization: `Bearer ${token}` } }
          )
          setNthOrderInsightValues(
            resp.values.map((v, i) => ({
              rank: i + 1,
              name: v.name,
              first: Number(v.first),
              second: Number(v.second),
              secondPlus: Number(v.secondPlus),
              third: Number(v.third),
              fourth: Number(v.fourth),
              fifthPlus: Number(v.fifthPlus),
              total: Number(v.total),
              isVisible: shopBillingStatus !== ShopBillingStatus.free || i < DEFAULT_VISIBLE_COUNT,
            }))
          )
        } else if (viewTypeParam === InsightReportType.orderCount) {
          const resp = await insightService.getOrderCountReport(
            {
              customerSegmentId: insightFilter.customerSegmentId || '',
              dimension: insightDimension,
              startDate: insightFilter.startDate,
              endDate: insightFilter.endDate,
            },
            { headers: { Authorization: `Bearer ${token}` } }
          )
          setOderCountInsightValues(
            resp.values.map((v, i) => ({
              rank: i + 1,
              name: v.name,
              once: Number(v.once),
              twice: Number(v.twice),
              twicePlus: Number(v.twicePlus),
              threeTimes: Number(v.threeTimes),
              fourTimes: Number(v.fourTimes),
              fiveTimesPlus: Number(v.fiveTimesPlus),
              total: Number(v.total),
              isVisible: shopBillingStatus !== ShopBillingStatus.free || i < DEFAULT_VISIBLE_COUNT,
            }))
          )
        } else if (viewTypeParam === InsightReportType.histogram) {
          const resp = await insightService.getHistogramReport(
            {
              customerSegmentId: insightFilter.customerSegmentId || '',
              dimension: insightDimension,
              startDate: insightFilter.startDate,
              endDate: insightFilter.endDate,
            },
            { headers: { Authorization: `Bearer ${token}` } }
          )
          setHistogramInsightValues({
            values: resp.values.map((v) => ({
              min: Number(v.min),
              max: Number(v.max),
              customerCount: Number(v.customerCount),
            })),
            mean: Number(resp.mean),
            median: Number(resp.median),
            max: Number(resp.max),
          })
        }
      } catch (err) {
        enqueueSnackbar(t('common.messageDataFetchError'), { severity: 'error' })
        notifySentry(err)
      } finally {
        setLoading(false)
      }
    }
    fetchData()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [insightDimension, insightFilter, viewTypeParam])

  return (
    <>
      <Box marginBottom='8px'>
        <Box
          display='flex'
          alignItems='center'
          marginBottom='12px'
          sx={{ fontSize: '14px', color: (theme) => theme.palette.text.secondary, cursor: 'pointer', '&:hover': { textDecoration: 'underline' } }}
          onClick={() =>
            navigate(
              insightFilter.customerSegmentId && insightFilter.customerSegmentId !== ''
                ? `${AUTHORIZED_ROUTE.INSIGHTS_OVERVIEW}?customer_segment_id=${insightFilter.customerSegmentId}`
                : AUTHORIZED_ROUTE.INSIGHTS_OVERVIEW
            )
          }
          width='fit-content'
        >
          <ArrowBackIcon sx={{ fontSize: '14px', marginRight: '4px' }} />
          <Typography fontSize='12px'>{t('features.insights.detail.backToOverview')}</Typography>
        </Box>

        <Box display='flex' alignItems='center'>
          <Box
            display='flex'
            alignItems='center'
            width='fit-content'
            ref={dimensionMenuAnchorRef}
            sx={{
              cursor: 'pointer',
              '&:hover': { opacity: 0.7 },
            }}
            onClick={() => setDimensionMenuOpen(true)}
          >
            <Typography variant='h2' fontSize='18px' fontWeight='500'>
              {insightDimension ? t('features.insights.dimension', { context: insightDimension }) : <Skeleton />}
            </Typography>
          </Box>
          <Typography fontSize='14px' color='text.secondary' marginLeft='16px'>
            {t(`features.insights.questions.${insightDimension}_${reportType}`)}
          </Typography>
          <GuideIcon guideType='CustomerInsights' />

          <Box flexGrow='1'>
            {viewTypeParam === InsightReportType.popularity && (
              <Box display='flex' justifyContent='flex-end' alignItems='center' marginLeft='12px'>
                <ViewTypeToggleButton viewType={viewType} setViewType={setViewType} />
                <Tooltip title={t('features.insights.detail.download')} placement='top'>
                  <span>
                    <IconButton
                      size='small'
                      disabled={!popularityInsightValues || popularityInsightValues.length === 0 || downloadLoadingForPopularity}
                      onClick={() => {
                        if (shopBillingStatus === ShopBillingStatus.free) {
                          setUpgradeRecommendDialogOpen(true)
                          return
                        }
                        handleDownloadPopularity()
                      }}
                      sx={{ borderRadius: '8px' }}
                    >
                      <FileDownloadOutlinedIcon fontSize='small' />
                    </IconButton>
                  </span>
                </Tooltip>
              </Box>
            )}
            {viewTypeParam === InsightReportType.monthly && (
              <Box display='flex' justifyContent='flex-end' alignItems='center' marginLeft='12px'>
                <ViewTypeToggleButton viewType={viewType} setViewType={setViewType} />
                <Tooltip title={t('features.insights.detail.download')} placement='top'>
                  <span>
                    <IconButton
                      disabled={!monthlyInsightValues || monthlyInsightValues.length === 0 || downloadLoadingForMonthly}
                      onClick={() => {
                        if (shopBillingStatus === ShopBillingStatus.free) {
                          setUpgradeRecommendDialogOpen(true)
                          return
                        }
                        handleDownloadMonthly()
                      }}
                      sx={{ borderRadius: '8px' }}
                    >
                      <FileDownloadOutlinedIcon fontSize='small' />
                    </IconButton>
                  </span>
                </Tooltip>
              </Box>
            )}
            {viewTypeParam === InsightReportType.nthOrder && (
              <Box display='flex' justifyContent='flex-end' alignItems='center' marginLeft='12px'>
                <ViewTypeToggleButton viewType={viewType} setViewType={setViewType} />
                <Tooltip title={t('features.insights.detail.download')} placement='top'>
                  <span>
                    <IconButton
                      size='small'
                      disabled={!nthOrderInsightValues || nthOrderInsightValues.length === 0 || downloadLoadingForNthOrder}
                      onClick={() => {
                        if (shopBillingStatus === ShopBillingStatus.free) {
                          setUpgradeRecommendDialogOpen(true)
                          return
                        }
                        handleDownloadNthOrder()
                      }}
                      sx={{ borderRadius: '8px' }}
                    >
                      <FileDownloadOutlinedIcon fontSize='small' />
                    </IconButton>
                  </span>
                </Tooltip>
              </Box>
            )}
            {viewTypeParam === InsightReportType.orderCount && (
              <Box display='flex' justifyContent='flex-end' alignItems='center' marginLeft='12px'>
                <ViewTypeToggleButton viewType={viewType} setViewType={setViewType} />
                <Tooltip title={t('features.insights.detail.download')} placement='top'>
                  <span>
                    <IconButton
                      size='small'
                      disabled={!oderCountInsightValues || oderCountInsightValues.length === 0 || downloadLoadingForOrderCount}
                      onClick={() => {
                        if (shopBillingStatus === ShopBillingStatus.free) {
                          setUpgradeRecommendDialogOpen(true)
                          return
                        }
                        handleDownloadOrderCount()
                      }}
                      sx={{ borderRadius: '8px' }}
                    >
                      <FileDownloadOutlinedIcon fontSize='small' />
                    </IconButton>
                  </span>
                </Tooltip>
              </Box>
            )}
            {viewTypeParam === InsightReportType.histogram && (
              <Box display='flex' justifyContent='flex-end' alignItems='center' marginLeft='12px' flexGrow='1'>
                <Tooltip title={t('features.insights.detail.download')} placement='top'>
                  <span>
                    <IconButton
                      size='small'
                      disabled={!histogramInsightValues || histogramInsightValues.values.length === 0 || downloadLoadingForHistogram}
                      onClick={() => {
                        if (shopBillingStatus === ShopBillingStatus.free) {
                          setUpgradeRecommendDialogOpen(true)
                          return
                        }
                        handleDownloadHistogram()
                      }}
                      sx={{ borderRadius: '8px' }}
                    >
                      <FileDownloadOutlinedIcon fontSize='small' />
                    </IconButton>
                  </span>
                </Tooltip>
              </Box>
            )}
          </Box>
        </Box>

        <Menu
          anchorEl={dimensionMenuAnchorRef.current}
          open={dimensionMenuOpen}
          onClose={() => setDimensionMenuOpen(false)}
          PaperProps={{
            sx: {
              maxHeight: '480px',
            },
          }}
        >
          <MenuList dense sx={{ padding: '0px' }}>
            {Object.values(InsightCategory)
              .filter((c) => c !== InsightCategory.all)
              .map((category) => (
                <div key={category}>
                  <ListSubheader sx={{ backgroundColor: 'transparent' }}>{t('features.insights.category', { context: category })}</ListSubheader>
                  {insightDimensions
                    .filter((d) => d.category === category)
                    .map((dimension) => (
                      <MenuItem
                        key={dimension.name}
                        onClick={() => {
                          if (dimension.name === insightDimension) return
                          const newPath = generatePath(AUTHORIZED_ROUTE.INSIGHT_DETAIL, { id: dimension.name })
                          const matchDimension = insightDimensions.find((d) => d.name === dimension.name)
                          if (!matchDimension) return
                          const newReportType = matchDimension.reportTypes[0]
                          if (newReportType) {
                            searchParams.set('reportType', newReportType)
                            navigate(`${newPath}?${searchParams.toString()}`)
                          }
                          setDimensionMenuOpen(false)
                        }}
                      >
                        <ListItemText>{t('features.insights.dimension', { context: dimension.name })}</ListItemText>
                      </MenuItem>
                    ))}
                </div>
              ))}
          </MenuList>
        </Menu>
      </Box>

      <Box display='flex' alignItems='center' marginBottom='16px'>
        {insightDimension && (
          <InsightFilter insightFilter={insightFilter} setInsightFilter={(filter) => setInsightFilter(filter)} insightDimension={insightDimension} />
        )}

        <ToggleButtonGroup
          value={reportType}
          size='small'
          color='primary'
          exclusive
          onChange={(_, v) => {
            if (v === null) return
            searchParams.set('reportType', v)
            if (insightFilter.customerSegmentId) searchParams.set('customer_segment_id', insightFilter.customerSegmentId)

            const to =
              insightDimension &&
              generatePath(AUTHORIZED_ROUTE.INSIGHT_DETAIL, {
                id: insightDimension,
              }) + (searchParams.toString() ? `?${searchParams.toString()}` : '')

            navigate(to)
          }}
          sx={{
            // marginLeft: '16px',
            '& .MuiToggleButtonGroup-grouped': {
              padding: '4px 12px',
            },
          }}
        >
          {dimensionProfile?.reportTypes.map((type) => (
            <ToggleButton value={type} key={type}>
              <ReportTypeIcon reportType={type} sx={{ fontSize: '16px', marginRight: '4px' }} />
              {t('features.insights.detail.reportType', { context: type })}
            </ToggleButton>
          ))}
        </ToggleButtonGroup>
      </Box>

      {insightDimension && viewTypeParam === InsightReportType.popularity && (
        <PopularityInsight
          viewType={viewType}
          insightValues={popularityInsightValues}
          loading={loading}
          onChartPointClick={handleChartDataPointClickForPopularityInsight}
        />
      )}
      {insightDimension && viewTypeParam === InsightReportType.monthly && (
        <MonthlyInsight
          viewType={viewType}
          insightValues={monthlyInsightValues}
          loading={loading}
          onChartPointClick={handleChartDataPointClickForMonthlyInsight}
        />
      )}
      {insightDimension && viewTypeParam === InsightReportType.nthOrder && (
        <NthOrderInsight
          viewType={viewType}
          insightValues={nthOrderInsightValues}
          loading={loading}
          onChartPointClick={handleChartDataPointClickForNthOrder}
        />
      )}
      {insightDimension && viewTypeParam === InsightReportType.orderCount && (
        <OrderCountInsight
          viewType={viewType}
          insightValues={oderCountInsightValues}
          loading={loading}
          onChartPointClick={handleChartDataPointClickForOrderCount}
        />
      )}
      {insightDimension && viewTypeParam === InsightReportType.histogram && (
        <HistogramInsight
          dimension={insightDimension}
          insightValues={histogramInsightValues}
          loading={loading}
          onChartPointClick={handleChartDataPointClickForHistogram}
        />
      )}

      {upgradeRecommendDialogOpen && (
        <UpgradeRecommendDialog open={upgradeRecommendDialogOpen} handleClose={() => setUpgradeRecommendDialogOpen(false)} referrer='feature' />
      )}
      <InsightDrawer
        open={insightDrawerState.open}
        handleOpen={() => setInsightDrawerState({ open: true })}
        handleClose={() => setInsightDrawerState({ open: false })}
        querySet={insightDrawerState.querySet}
      />
    </>
  )
}
