import { Box, Card, CardActionArea, CardContent, Chip, Grid, Skeleton } from '@mui/material'
import { PageHeader } from 'components/pageHeader'
import { useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useLocation } from 'react-router-dom'
import { InsightCategory, insightDimensions } from '../types/insightDimensions'
import { CustomerInsightCard } from './components/customerInsightCard'

export const Overview = () => {
  const { t } = useTranslation()

  const location = useLocation()
  const searchParams = new URLSearchParams(location.search)
  const customerSegmentId = searchParams.get('customer_segment_id') || ''

  const [category, setCategory] = useState<InsightCategory>(InsightCategory.all)

  const filteredInsights = useMemo(() => {
    if (category === InsightCategory.all) return insightDimensions
    return insightDimensions.filter((dimension) => dimension.category.includes(category))
  }, [category])

  return (
    <>
      <PageHeader title={t('features.insights.overview.title')} marginBottom='24px' guideType='CustomerInsights' />
      <Box display='flex' alignItems='center' marginBottom='24px' gap='8px'>
        {Object.values(InsightCategory).map((c) => (
          <Chip
            key={c}
            size='small'
            label={t('features.insights.category', { context: c })}
            color={c === category ? 'primary' : 'default'}
            onClick={() => setCategory(c)}
            sx={{ cursor: 'pointer', borderRadius: '6px' }}
          />
        ))}
      </Box>
      <Grid container spacing={3} alignItems='stretch'>
        {!filteredInsights
          ? Array.from(new Array(4)).map((_, i) => (
              <Grid key={i} item xs={12} sm={6} md={4} lg={3}>
                <Card sx={{ borderRadius: '12px' }}>
                  <CardActionArea>
                    <Skeleton variant='rectangular' height={120} animation='wave' />
                    <CardContent>
                      <Skeleton width='60%' height={30} animation='wave' />
                      <Skeleton width='90%' height={20} animation='wave' />
                    </CardContent>
                  </CardActionArea>
                </Card>
              </Grid>
            ))
          : filteredInsights.map((row) => (
              <Grid key={row.name} item xs={12} sm={6} md={4} lg={3}>
                <CustomerInsightCard insight={row} customerSegmentId={customerSegmentId} />
              </Grid>
            ))}
      </Grid>
    </>
  )
}
