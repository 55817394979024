import { Code, ConnectError } from '@bufbuild/connect-web'
import Picker from '@emoji-mart/react'
import { LoadingButton } from '@mui/lab'
import { Box, Button, Dialog, FormControl, Popover, TextField, Typography } from '@mui/material'
import { convertFormToQuerySet } from 'features/customerSegments/querySetForm/schema/converter'
import { QuerySetFormState } from 'features/customerSegments/querySetForm/schema/querySetFormSchema'
import { getIdToken } from 'firebase/auth'
import { CustomerSegmentService } from 'gen/proto/customer_segment/customer_segment_connectweb'
import { useAuthUser } from 'hooks/useAuthUser'
import { useCustomSnackbar } from 'hooks/useCustomSnackbar'
import { useGrpcClient } from 'hooks/useGrpcClient'
import { useSentryNotifier } from 'hooks/useSentryNotifier'
import { FC, useRef, useState } from 'react'
import { Controller, UseFormReturn, useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { generatePath, useNavigate } from 'react-router-dom'
import { AUTHORIZED_ROUTE } from 'routing'
import { extractIconFromTextWithIcon, extractTextFromTextWithIcon } from 'utils/iconUtil'

type Props = {
  open: boolean
  querySetFormMethods: UseFormReturn<QuerySetFormState>
  name: string
  description: string
  handleClose: () => void
}

type CustomerSegmentFormHeader = {
  name: string
  description: string
}

export const CreateDialog: FC<Props> = ({ open, querySetFormMethods, name, description, handleClose }) => {
  const { t, i18n } = useTranslation()
  const authUser = useAuthUser()
  const navigate = useNavigate()
  const { enqueueSnackbar } = useCustomSnackbar()
  const { notifySentry } = useSentryNotifier()
  const customerSegmentService = useGrpcClient(CustomerSegmentService)

  const defaultEmojiList = ['😀', '🥰', '😎', '👋', '🐶', '💐', '🔥', '🥑', '🍔', '🏋️‍♀️', '🚗', '🚀', '⏰', '🎁', '🎉']
  const defaultEmoji = defaultEmojiList[Math.floor(Math.random() * defaultEmojiList.length)]

  // EmojiPicker
  const [emojiIcon, setEmojiIcon] = useState<string>(name ? extractIconFromTextWithIcon(name) || defaultEmoji : defaultEmoji)
  const [openEmojiPicker, setOpenEmojiPicker] = useState(false)
  const emojiAnchorRef = useRef<HTMLButtonElement | null>(null)

  const {
    control,
    handleSubmit,
    setError,
    formState: { isSubmitting },
  } = useForm<CustomerSegmentFormHeader>({
    defaultValues: {
      name: extractTextFromTextWithIcon(name) || '',
      description,
    },
  })

  const createSegment = async (header: CustomerSegmentFormHeader) => {
    const ok = await querySetFormMethods.trigger()
    if (!ok) return
    const querySetFormValues = querySetFormMethods.getValues()
    const querySet = convertFormToQuerySet(querySetFormValues)
    const querySetString = JSON.stringify(querySet)

    try {
      const token = await getIdToken(authUser!)
      const payload = {
        name: `${emojiIcon} ${header.name}`, // icon + name
        description: header.description,
        querySet: querySetString,
      }
      const resp = await customerSegmentService.create(payload, { headers: { Authorization: `Bearer ${token}` } })
      enqueueSnackbar(t('features.customerSegments.create.createDialog.messageSaved'), { severity: 'success' })
      navigate(
        generatePath(AUTHORIZED_ROUTE.CUSTOMER_SEGMENT_DETAIL, {
          id: resp.customerSegmentId,
        })
      )
    } catch (err) {
      if (err instanceof ConnectError && err.code === Code.AlreadyExists) {
        setError('name', { message: t('features.customerSegments.create.createDialog.messageAlreadyExists') })
        return
      }
      enqueueSnackbar(t('features.customerSegments.create.createDialog.messageError'), { severity: 'error' })
      notifySentry(err)
    }
  }

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      maxWidth='xs'
      fullWidth
      PaperProps={{
        sx: { borderRadius: '8px' },
      }}
    >
      <Box padding='24px 24px 20px'>
        <Typography fontSize='18px' marginBottom='20px'>
          {t('features.customerSegments.create.createDialog.title')}
        </Typography>

        <Box marginBottom='20px'>
          <Box display='flex' alignItems='center' gap='12px' marginBottom='12px'>
            <Button
              ref={emojiAnchorRef}
              variant='outlined'
              onClick={() => setOpenEmojiPicker(true)}
              sx={{ borderColor: (theme) => theme.palette.action.selected, fontSize: '22px', padding: '0px' }}
            >
              {emojiIcon}
            </Button>
            <Popover
              open={openEmojiPicker}
              anchorEl={emojiAnchorRef.current}
              onClose={() => setOpenEmojiPicker(false)}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left',
              }}
            >
              <Picker
                locale={i18n.language}
                onEmojiSelect={(emoji: any) => {
                  setEmojiIcon(emoji.native)
                  setOpenEmojiPicker(false)
                }}
              />
            </Popover>

            <Controller
              control={control}
              name='name'
              rules={{
                validate: (v) => {
                  if (!v) return t('features.customerSegments.create.createDialog.messageValidationNameMin')
                  if (v.length > 64) return t('features.customerSegments.create.createDialog.messageValidationNameMax')
                  return
                },
              }}
              render={({ field, fieldState: { error } }) => {
                return (
                  <FormControl sx={{ width: '100%' }}>
                    <TextField
                      {...field}
                      error={Boolean(error)}
                      helperText={error?.message}
                      label={t('features.customerSegments.create.createDialog.fieldName')}
                      InputLabelProps={{ shrink: true }}
                      size='small'
                    />
                  </FormControl>
                )
              }}
            />
          </Box>
          <Box>
            <Controller
              control={control}
              name='description'
              rules={{
                validate: (v) => {
                  if (v.length > 128) return t('features.customerSegments.create.createDialog.messageValidationDescriptionMax')
                  return
                },
              }}
              render={({ field, fieldState: { error } }) => {
                return (
                  <FormControl sx={{ width: '100%' }}>
                    <TextField
                      {...field}
                      error={Boolean(error)}
                      helperText={error?.message}
                      label={t('features.customerSegments.create.createDialog.fieldDescription')}
                      InputLabelProps={{ shrink: true }}
                      size='small'
                    />
                  </FormControl>
                )
              }}
            />
          </Box>
        </Box>

        <Box display='flex' justifyContent='flex-end' gap='12px'>
          <Button disabled={isSubmitting} onClick={handleClose} variant='outlined'>
            {t('features.customerSegments.create.createDialog.cancel')}
          </Button>
          <LoadingButton type='submit' loading={isSubmitting} loadingPosition='center' onClick={handleSubmit(createSegment)} variant='contained'>
            {t('features.customerSegments.create.createDialog.submit')}
          </LoadingButton>
        </Box>
      </Box>
    </Dialog>
  )
}
