import ArrowBackIcon from '@mui/icons-material/ArrowBack'
import { Box, Chip, Divider, Grid, IconButton, Skeleton, SwipeableDrawer, Typography, useTheme } from '@mui/material'
import dayjs from 'dayjs'
import { getIdToken } from 'firebase/auth'
import { OrderService } from 'gen/proto/order/order_connectweb'
import { useAuthUser } from 'hooks/useAuthUser'
import { useCurrency } from 'hooks/useCurrency'
import { useCustomSnackbar } from 'hooks/useCustomSnackbar'
import { useGrpcClient } from 'hooks/useGrpcClient'
import { useSentryNotifier } from 'hooks/useSentryNotifier'
import { FC, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { getDimensionIconImagePath } from 'utils/imageUtil'
import { formatDateTime } from 'utils/timeUtil'

type Props = {
  open: boolean
  handleOpen: () => void
  handleClose: () => void
  orderProps:
    | {
        id: string
        name: string
      }
    | undefined
}

export type Order = {
  id: string
  name: string
  orderValue: number
  orderedAt: string
  products: string[]
  tags: string[]
  referrer: string
  landingPage: string
  channel: string
  coupon: string
  utmSource: string
  utmMedium: string
  utmCampaign: string
}

const drawerWidth = 360

export const OrderDrawer: FC<Props> = ({ open, handleOpen, handleClose, orderProps }) => {
  const { t, i18n } = useTranslation()
  const authUser = useAuthUser()
  const { enqueueSnackbar } = useCustomSnackbar()
  const { notifySentry } = useSentryNotifier()
  const { formatCurrency } = useCurrency()
  const theme = useTheme()

  const orderService = useGrpcClient(OrderService)

  const [order, setOrder] = useState<Order>()

  useEffect(() => {
    if (!orderProps) {
      setOrder(undefined)
      return
    }
    const fetch = async () => {
      try {
        const token = await getIdToken(authUser!)
        const resp = await orderService.get({ id: orderProps.id }, { headers: { Authorization: `Bearer ${token}` } })
        setOrder({
          id: resp.id,
          name: resp.name,
          orderValue: Number(resp.orderValue),
          orderedAt: formatDateTime(dayjs(resp.orderedAt), i18n.language),
          products: resp.products.filter((str) => str !== ''),
          tags: resp.tags.filter((str) => str !== ''),
          referrer: resp.referrer,
          landingPage: resp.landingPage,
          channel: resp.channel,
          coupon: resp.coupon,
          utmSource: resp.utmSource,
          utmMedium: resp.utmMedium,
          utmCampaign: resp.utmCampaign,
        })
      } catch (err) {
        enqueueSnackbar(t('common.messageDataFetchError'), { severity: 'error' })
        notifySentry(err)
      }
    }
    fetch()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [orderProps])

  return (
    <SwipeableDrawer open={open} onOpen={handleOpen} onClose={handleClose} anchor='right'>
      <Box width={drawerWidth} maxWidth={drawerWidth} padding='12px' role='presentation'>
        <Box display='flex' justifyContent='space-between'>
          <IconButton onClick={handleClose} size='small' sx={{ borderRadius: '8px' }}>
            <ArrowBackIcon fontSize='small' />
          </IconButton>
        </Box>

        <Box padding='20px 12px'>
          <Box marginBottom='24px'>
            <Typography fontSize='18px' marginBottom='4px'>
              {orderProps?.name}
            </Typography>
            <Box display='flex' alignItems='center'>
              {order ? <Typography fontSize='14px'>{formatCurrency(order.orderValue)}</Typography> : <Skeleton height='16px' width='60px' />}
              {' ・ '}
              {order ? (
                <Typography fontSize='14px'>{formatDateTime(dayjs(order.orderedAt), i18n.language)}</Typography>
              ) : (
                <Skeleton height='16px' width='120px' />
              )}
            </Box>
          </Box>

          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Divider />
            </Grid>
            <Grid item xs={12}>
              <Typography variant='body2' color='text.secondary' marginBottom='4px'>
                <img src={getDimensionIconImagePath('referrer', theme)} alt='referrer' width='13px' style={{ marginRight: '4px' }} />
                {t('features.orders.orderDrawer.referrer')}
              </Typography>
              {order ? (
                <Typography fontSize='14px'>{order.referrer || t('features.orders.orderDrawer.none')}</Typography>
              ) : (
                <Skeleton height='16px' width='120px' />
              )}
            </Grid>
            <Grid item xs={12}>
              <Typography variant='body2' color='text.secondary' marginBottom='4px' style={{ wordWrap: 'break-word' }}>
                <img src={getDimensionIconImagePath('landing_page', theme)} alt='landing_page' width='13px' style={{ marginRight: '4px' }} />
                {t('features.orders.orderDrawer.landingPage')}
              </Typography>
              {order ? (
                <Typography fontSize='14px' style={{ wordWrap: 'break-word' }}>
                  {order.landingPage || t('features.orders.orderDrawer.none')}
                </Typography>
              ) : (
                <Skeleton height='16px' width='120px' />
              )}
            </Grid>
            <Grid item xs={12}>
              <Typography variant='body2' color='text.secondary' marginBottom='4px'>
                <img src={getDimensionIconImagePath('channel', theme)} alt='channel' width='13px' style={{ marginRight: '4px' }} />
                {t('features.orders.orderDrawer.channel')}
              </Typography>
              {order ? (
                <Typography fontSize='14px'>{order.channel || t('features.orders.orderDrawer.none')}</Typography>
              ) : (
                <Skeleton height='16px' width='120px' />
              )}
            </Grid>
            <Grid item xs={12}>
              <Typography variant='body2' color='text.secondary' marginBottom='4px'>
                <img src={getDimensionIconImagePath('coupon', theme)} alt='coupon' width='13px' style={{ marginRight: '4px' }} />
                {t('features.orders.orderDrawer.coupon')}
              </Typography>
              {order ? (
                <Typography fontSize='14px'>{order.coupon || t('features.orders.orderDrawer.none')}</Typography>
              ) : (
                <Skeleton height='16px' width='120px' />
              )}
            </Grid>
            <Grid item xs={12}>
              <Typography variant='body2' color='text.secondary' marginBottom='4px'>
                <img src={getDimensionIconImagePath('utm_source', theme)} alt='utm_source' width='13px' style={{ marginRight: '4px' }} />
                {t('features.orders.orderDrawer.utmSource')}
              </Typography>
              {order ? (
                <Typography fontSize='14px'>{order.utmSource || t('features.orders.orderDrawer.none')}</Typography>
              ) : (
                <Skeleton height='16px' width='120px' />
              )}
            </Grid>
            <Grid item xs={12}>
              <Typography variant='body2' color='text.secondary' marginBottom='4px'>
                <img src={getDimensionIconImagePath('utm_medium', theme)} alt='utm_medium' width='13px' style={{ marginRight: '4px' }} />
                {t('features.orders.orderDrawer.utmMedium')}
              </Typography>
              {order ? (
                <Typography fontSize='14px'>{order.utmMedium || t('features.orders.orderDrawer.none')}</Typography>
              ) : (
                <Skeleton height='16px' width='120px' />
              )}
            </Grid>
            <Grid item xs={12}>
              <Typography variant='body2' color='text.secondary' marginBottom='4px'>
                <img src={getDimensionIconImagePath('utm_campaign', theme)} alt='utm_campaign' width='13px' style={{ marginRight: '4px' }} />
                {t('features.orders.orderDrawer.utmCampaign')}
              </Typography>
              {order ? (
                <Typography fontSize='14px'>{order.utmCampaign || t('features.orders.orderDrawer.none')}</Typography>
              ) : (
                <Skeleton height='16px' width='120px' />
              )}
            </Grid>
            <Grid item xs={12}>
              <Divider />
            </Grid>
            <Grid item xs={12}>
              <Typography variant='body2' color='text.secondary' marginBottom='4px'>
                <img src={getDimensionIconImagePath('product', theme)} alt='product' width='13px' style={{ marginRight: '4px' }} />
                {t('features.orders.orderDrawer.products')}
              </Typography>
              {order ? (
                order.products.length === 0 ? (
                  <Typography fontSize='14px'>{t('features.orders.orderDrawer.none')}</Typography>
                ) : (
                  order.products.map((product) => (
                    <Chip
                      key={product}
                      label={product}
                      size='small'
                      variant='outlined'
                      sx={{ borderRadius: '4px', marginRight: '6px', marginBottom: order.products.length > 1 ? '6px' : '0px' }}
                    />
                  ))
                )
              ) : (
                <Box display='flex'>
                  {[1, 2, 3].map((i) => (
                    <Skeleton key={i} width='48px' sx={{ marginRight: '6px' }} />
                  ))}
                </Box>
              )}
            </Grid>
            <Grid item xs={12}>
              <Typography variant='body2' color='text.secondary' marginBottom='4px'>
                <img src={getDimensionIconImagePath('order_tag', theme)} alt='order_tag' width='13px' style={{ marginRight: '4px' }} />
                {t('features.orders.orderDrawer.tags')}
              </Typography>
              {order ? (
                order.tags.length === 0 ? (
                  <Typography fontSize='14px'>{t('features.orders.orderDrawer.none')}</Typography>
                ) : (
                  order.tags.map((tag) => (
                    <Chip
                      key={tag}
                      label={tag}
                      size='small'
                      sx={{ borderRadius: '4px', marginRight: '6px', marginBottom: order.tags.length > 0 ? '6px' : '0px' }}
                    />
                  ))
                )
              ) : (
                <Box display='flex'>
                  {[1, 2, 3].map((i) => (
                    <Skeleton key={i} width='48px' sx={{ marginRight: '6px' }} />
                  ))}
                </Box>
              )}
            </Grid>
          </Grid>
        </Box>
      </Box>
    </SwipeableDrawer>
  )
}
