import HelpOutlineOutlinedIcon from '@mui/icons-material/HelpOutlineOutlined'
import { IconButton, Tooltip } from '@mui/material'
import { getGuideUrl, GuideType } from 'config/guide'
import { useActionTracker } from 'hooks/useMixpanel'
import { useTranslation } from 'react-i18next'

type Props = {
  guideType: GuideType
}

export const GuideIcon: React.FC<Props> = ({ guideType }) => {
  const { t, i18n } = useTranslation()
  const { dispatch } = useActionTracker()
  const currentLang = i18n.language

  return (
    <Tooltip title={t('common.guideIcon.view')} placement='right'>
      <span>
        <IconButton
          size='small'
          onClick={() => {
            const guideURL = getGuideUrl(currentLang, guideType)
            dispatch('ViewGuide', { guideType: guideType, url: guideURL })
            window.open(guideURL, '_blank')
          }}
          sx={{ marginLeft: '4px', borderRadius: '8px' }}
        >
          <HelpOutlineOutlinedIcon sx={{ fontSize: '14px' }} />
        </IconButton>
      </span>
    </Tooltip>
  )
}
