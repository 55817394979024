import { zodResolver } from '@hookform/resolvers/zod'
import { LoadingButton } from '@mui/lab'
import { Box, Button, Checkbox, Dialog, FormControl, FormControlLabel, InputAdornment, TextField, Typography } from '@mui/material'
import { getIdToken } from 'firebase/auth'
import { CustomerSegmentService } from 'gen/proto/customer_segment/customer_segment_connectweb'
import { useAccount } from 'hooks/useAccount'
import { useAuthUser } from 'hooks/useAuthUser'
import { useCustomSnackbar } from 'hooks/useCustomSnackbar'
import { useGrpcClient } from 'hooks/useGrpcClient'
import { useSentryNotifier } from 'hooks/useSentryNotifier'
import { FC } from 'react'
import { Controller, useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { extractTextFromTextWithIcon } from 'utils/iconUtil'
import { getImagePath } from 'utils/imageUtil'
import { z } from 'zod'

type Props = {
  open: boolean
  handleClose: () => void
  customerSegmentId: string
  customerSegmentName: string
  estimatedProcessingDurationInMinutes: number
}

const TAG_NAME_PREFIX = 'ecp-'
const convertToDefaultTagName = (customerSegmentName: string) => extractTextFromTextWithIcon(customerSegmentName).toLowerCase().replace(/\s+/g, '-')
const addPrefix = (tagName: string) => (tagName.startsWith(TAG_NAME_PREFIX) ? tagName : `${TAG_NAME_PREFIX}${tagName}`)

export const ActivateAutoSyncDialog: FC<Props> = ({
  open,
  handleClose,
  customerSegmentId,
  customerSegmentName,
  estimatedProcessingDurationInMinutes,
}) => {
  const { t } = useTranslation()
  const authUser = useAuthUser()
  const { enqueueSnackbar } = useCustomSnackbar()
  const { notifySentry } = useSentryNotifier()
  const { shop } = useAccount()
  const customerSegmentService = useGrpcClient(CustomerSegmentService)

  const addTagInput = z.object({
    tagName: z
      .string()
      .min(1, { message: t('features.customerSegments.detail.activateAutoSyncDialog.messageValidationTagIsRequired') })
      .max(32, { message: t('features.customerSegments.detail.activateAutoSyncDialog.messageValidationMax') }),
    enableShopifySegmentCreation: z.boolean(),
  })

  type InputSchema = z.infer<typeof addTagInput>

  const {
    control,
    handleSubmit,
    formState: { isSubmitting },
  } = useForm<InputSchema>({
    resolver: zodResolver(addTagInput),
    defaultValues: {
      tagName: convertToDefaultTagName(customerSegmentName),
      enableShopifySegmentCreation: true,
    },
    mode: 'onChange',
  })

  const updateTagOperationSetting = async (input: InputSchema) => {
    try {
      const token = await getIdToken(authUser!)
      await customerSegmentService.updateTagOperationSetting(
        {
          customerSegmentId: customerSegmentId,
          isActive: true,
          tagName: addPrefix(input.tagName),
          enableShopifySegmentCreation: input.enableShopifySegmentCreation,
        },
        { headers: { Authorization: `Bearer ${token}` } }
      )
      enqueueSnackbar(t('features.customerSegments.detail.activateAutoSyncDialog.messageSaved'), { severity: 'success' })
      handleClose()
    } catch (err) {
      enqueueSnackbar(t('features.customerSegments.detail.activateAutoSyncDialog.messageFailed'), { severity: 'error' })
      notifySentry(err)
    }
  }

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      maxWidth='xs'
      fullWidth
      PaperProps={{
        sx: { borderRadius: '8px' },
      }}
    >
      <Box padding='24px 24px 20px'>
        <Box display='flex' alignItems='center' marginBottom='8px'>
          <img src={getImagePath('shopify_logo.svg')} alt='Shopify Logo' width='20px' />
          <Typography fontSize='18px' marginLeft='6px'>
            {t('features.customerSegments.detail.activateAutoSyncDialog.title')}
          </Typography>
        </Box>
        <Typography fontSize='13px' color='text.secondary' marginBottom='12px'>
          {t('features.customerSegments.detail.activateAutoSyncDialog.description', {
            estimatedProcessingDurationInMinutes: estimatedProcessingDurationInMinutes,
          })}
        </Typography>

        <Box marginBottom='20px' display='flex' flexDirection='column'>
          <Box display='flex' flexDirection='column' marginTop='12px'>
            <Controller
              control={control}
              name='tagName'
              render={({ field, fieldState: { error } }) => {
                return (
                  <FormControl>
                    <TextField
                      {...field}
                      error={Boolean(error)}
                      helperText={error?.message}
                      size='small'
                      label={t('features.customerSegments.detail.activateAutoSyncDialog.fieldTagName')}
                      InputProps={{
                        startAdornment: <InputAdornment position='start'>{TAG_NAME_PREFIX}</InputAdornment>,
                      }}
                    />
                  </FormControl>
                )
              }}
            />

            <Controller
              control={control}
              name='enableShopifySegmentCreation'
              render={({ field }) => (
                <FormControlLabel
                  control={<Checkbox {...field} checked={field.value} size='small' />}
                  label={t('features.customerSegments.detail.activateAutoSyncDialog.fieldEnableShopifySegmentCreation')}
                  sx={{ marginTop: '8px', '& .MuiFormControlLabel-label': { fontSize: '14px' } }}
                />
              )}
            />
          </Box>
        </Box>

        <Box display='flex' justifyContent='flex-end' gap='12px'>
          <Button disabled={isSubmitting} onClick={handleClose} variant='outlined'>
            {t('features.customerSegments.detail.activateAutoSyncDialog.cancel')}
          </Button>
          <LoadingButton
            type='submit'
            disabled={shop.isDemo || isSubmitting} // Note: Demo accounts cannot assign tags
            loading={isSubmitting}
            loadingPosition='center'
            onClick={handleSubmit(updateTagOperationSetting)}
            variant='contained'
          >
            {t('features.customerSegments.detail.activateAutoSyncDialog.submit')}
          </LoadingButton>
        </Box>
      </Box>
    </Dialog>
  )
}
