import ArrowForwardIcon from '@mui/icons-material/ArrowForward'
import LocalFireDepartmentRoundedIcon from '@mui/icons-material/LocalFireDepartmentRounded'
import { Box, Button, Chip, CircularProgress, Divider, Grid, Paper, Tooltip, Typography } from '@mui/material'
import { EmptyState } from 'components/emptyState'
import { InsightDimension } from 'gen/firestore'
import { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { generatePath } from 'react-router-dom'
import { AUTHORIZED_ROUTE } from 'routing'
import { extractIconFromTextWithIcon, extractTextFromTextWithIcon } from 'utils/iconUtil'
import { InsightValue } from '../insights'

type Props = {
  title: string
  customerSegmentId: string
  insightDimension: InsightDimension
  insightValues: InsightValue[] | undefined
  loading: boolean
  insightDimensions: InsightDimension[]
  setInsightDimension: (dimension: InsightDimension) => void
}

export const InsightCategoryValues: FC<Props> = ({
  title,
  customerSegmentId,
  insightDimension,
  insightValues,
  loading,
  insightDimensions,
  setInsightDimension,
}) => {
  const { t } = useTranslation()

  return (
    <Paper variant='outlined' sx={{ borderRadius: '8px', padding: '28px 24px', height: '100%' }}>
      <Box display='flex' alignItems='center' justifyContent='space-between' marginBottom='12px'>
        <Typography>{title}</Typography>
        <Button
          size='small'
          endIcon={<ArrowForwardIcon fontSize='small' />}
          sx={{ padding: 0 }}
          onClick={() => {
            const to = generatePath(AUTHORIZED_ROUTE.INSIGHT_DETAIL, { id: insightDimension }) + `?customer_segment_id=${customerSegmentId}`
            window.open(to, '_blank')
          }}
        >
          {t('features.customerSegments.detail.insights.viewMore')}
        </Button>
      </Box>
      <Box display='flex' gap='8px' marginBottom='20px'>
        {insightDimensions.map((d) => (
          <Chip
            key={d}
            size='small'
            label={extractTextFromTextWithIcon(t(`features.insights.dimension_${d}`))}
            color={d === insightDimension ? 'primary' : 'default'}
            onClick={() => setInsightDimension(d)}
            sx={{ cursor: 'pointer', borderRadius: '6px', fontSize: '11px' }}
          />
        ))}
      </Box>
      {loading || !insightValues ? (
        <Box height={140} display='flex' justifyContent='center' alignItems='center'>
          <CircularProgress size={24} />
        </Box>
      ) : insightValues.length === 0 ? (
        <EmptyState title={t('features.customerSegments.detail.insights.empty')} />
      ) : (
        <>
          <Divider sx={{ marginBottom: '20px' }} />
          <Grid container spacing={2} sx={{ paddingX: '8px' }}>
            {insightValues.map((v) => (
              <Grid item xs={12} key={v.name}>
                <Box display='flex' alignItems='center' justifyContent='space-between'>
                  <Box display='flex' alignItems='center' gap='12px'>
                    <Typography fontSize='20px'>{extractIconFromTextWithIcon(v.name)}</Typography>
                    <Typography fontSize='14px'>{extractTextFromTextWithIcon(v.name)}</Typography>
                  </Box>
                  <Tooltip
                    title={
                      customerSegmentId === ''
                        ? t('features.insights.overview.tooltipAll', { customerCount: v.customerCount, ratio: v.ratio })
                        : t('features.insights.overview.tooltipSelected', { customerCount: v.customerCount, ratio: v.ratio })
                    }
                    placement='top'
                    arrow
                  >
                    <Typography fontSize='13px' color='text.secondary' display='flex' alignItems='center'>
                      <LocalFireDepartmentRoundedIcon fontSize='small' sx={{ fontSize: '16px', marginRight: '2px', color: 'orange' }} />
                      {v.ratio}%
                    </Typography>
                  </Tooltip>
                </Box>
              </Grid>
            ))}
          </Grid>
        </>
      )}
    </Paper>
  )
}
