import { Box, Button, List, ListItem, ListItemButton, Typography } from '@mui/material'
import { PageHeader } from 'components/pageHeader'
import { getGuideUrl } from 'config/guide'
import { convertFormToQuerySet } from 'features/customerSegments/querySetForm/schema/converter'
import { QuerySetFormState } from 'features/customerSegments/querySetForm/schema/querySetFormSchema'
import { useActionTracker } from 'hooks/useMixpanel'
import { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { createSearchParams } from 'react-router-dom'
import { AUTHORIZED_ROUTE } from 'routing'
import { useSegmentTemplates } from './hooks/useSegmentTemplates'

export const Templates: FC = () => {
  const { t, i18n } = useTranslation()
  const { data: templates } = useSegmentTemplates()
  const { dispatch } = useActionTracker()

  return (
    <>
      <Box display='flex' justifyContent='space-between' alignItems='center' marginBottom='16px'>
        <PageHeader title={t('features.templates.title')} marginBottom='0px' />
        <Button
          variant='contained'
          size='small'
          onClick={() => {
            const guideURL = getGuideUrl(i18n.language, 'UseCases')
            dispatch('ViewGuide', { guideType: 'UseCases', url: guideURL })
            window.open(guideURL, '_blank')
          }}
        >
          {t('features.templates.viewUseCases')}
        </Button>
      </Box>

      <List dense>
        {templates.map((template) => (
          <ListItem key={template.name} dense disablePadding>
            <ListItemButton
              onClick={() => {
                dispatch('ClickTemplate', { name: template.name })
                const querySetJsonString = JSON.stringify(convertFormToQuerySet(template.querySet as QuerySetFormState))
                const to = `${AUTHORIZED_ROUTE.CUSTOMER_SEGMENT_CREATE}?${createSearchParams({
                  querySet: encodeURIComponent(querySetJsonString),
                  name: encodeURIComponent(template.name),
                  description: encodeURIComponent(template.description),
                }).toString()}`
                window.open(to, '_blank')
              }}
              sx={{ padding: '14px', borderRadius: '4px' }}
            >
              <Box display='flex' alignItems='center' justifyContent='space-between' width='100%'>
                <Box display='flex' alignItems='center' gap='16px'>
                  <Typography fontSize='14px'>{template.name}</Typography>
                  <Typography fontSize='13px' color='text.secondary'>
                    {template.description}
                  </Typography>
                </Box>
                <Typography fontSize='14px' color='primary' sx={{ display: 'none', '.MuiListItemButton-root:hover &': { display: 'block' } }}>
                  {t('features.templates.useTemplate')}
                </Typography>
              </Box>
            </ListItemButton>
          </ListItem>
        ))}
      </List>
    </>
  )
}
