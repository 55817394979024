import LinkIcon from '@mui/icons-material/Link'
import PushPinOutlinedIcon from '@mui/icons-material/PushPinOutlined'
import TroubleshootIcon from '@mui/icons-material/Troubleshoot'
import { Box, Button, IconButton, Tooltip } from '@mui/material'
import { PageHeader } from 'components/pageHeader'
import { UpgradeRecommendDialog } from 'components/upgradeRecommendDialog'
import { billingStatus, customerSegmentCountLimit } from 'config/plan'
import dayjs from 'dayjs'
import { DimensionOptionsProvider } from 'features/customerSegments/context/dimensionOptionsContext'
import { defaultQuerySet } from 'features/customerSegments/querySetForm/defalutValues'
import {
  convertFormToQuerySet,
  convertQuerySetToForm,
  convertStringToApiQuerySetState,
} from 'features/customerSegments/querySetForm/schema/converter'
import { useQuerySetForm } from 'features/customerSegments/querySetForm/useQuerySetForm'
import { query } from 'firebase/firestore'
import { ShopBillingStatus } from 'gen/firestore'
import { useAccount } from 'hooks/useAccount'
import { useSubCollectionRef } from 'hooks/useCollectionRef'
import { useCustomSnackbar } from 'hooks/useCustomSnackbar'
import { useCollectionSubscription } from 'hooks/useFirestoreData'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useLocation } from 'react-router-dom'
import { AUTHORIZED_ROUTE } from 'routing'
import { CreateDialog } from './components/createDialog'
import { PreviewMetricsDialog } from './components/previewMetricsDialog'

export const Create = () => {
  const { t } = useTranslation()
  const location = useLocation()
  const { enqueueSnackbar } = useCustomSnackbar()

  const queryParams = new URLSearchParams(location.search)
  const querySetParam = queryParams.get('querySet')
  const nameParam = queryParams.get('name')
  const descriptionParam = queryParams.get('description')
  const defaultQuerySetFromParam = querySetParam && convertQuerySetToForm(convertStringToApiQuerySetState(decodeURIComponent(querySetParam)))

  const { account, shop } = useAccount()
  const shopBillingStatus = billingStatus(shop, dayjs())
  const { customerSegmentsRef } = useSubCollectionRef(account.shopRef!.id)
  const { data: customerSegments } = useCollectionSubscription(query(customerSegmentsRef))

  const { QuerySetForm, querySetFormMethods } = useQuerySetForm({
    mode: 'onChange',
    defaultValues: defaultQuerySetFromParam || defaultQuerySet,
  })

  const [createDialogOpen, setCreateDialogOpen] = useState(false)
  const [previewMetricsDialogOpen, setPreviewMetricsDialogOpen] = useState(false)
  const [upgradeRecommendDialogOpen, setUpgradeRecommendDialogOpen] = useState(false)

  const handleMetricsDialogOpen = async () => {
    const ok = await querySetFormMethods.trigger()
    if (ok) setPreviewMetricsDialogOpen(true)
  }

  const handleCopyLink = async () => {
    const ok = await querySetFormMethods.trigger()
    if (ok) {
      const querySetFormValues = querySetFormMethods.getValues()
      const querySet = convertFormToQuerySet(querySetFormValues)
      const url = `${window.location.origin}${AUTHORIZED_ROUTE.CUSTOMER_SEGMENT_CREATE}?querySet=${encodeURIComponent(JSON.stringify(querySet))}`
      navigator.clipboard.writeText(url)
      enqueueSnackbar(t('features.customerSegments.create.messageLinkCopied'), { severity: 'success' })
    }
  }

  const handleCreateDialogOpen = async () => {
    if (!customerSegments) return
    if (shopBillingStatus === ShopBillingStatus.free && customerSegments.length >= customerSegmentCountLimit(shopBillingStatus)) {
      setUpgradeRecommendDialogOpen(true)
      return
    }
    const ok = await querySetFormMethods.trigger()
    if (ok) setCreateDialogOpen(true)
  }

  return (
    <>
      <Box
        display='flex'
        alignItems='center'
        justifyContent='space-between'
        paddingBottom='18px'
        position='sticky'
        top='80px'
        zIndex='1'
        sx={{ backgroundColor: (theme) => theme.palette.background.paper }}
      >
        <PageHeader title={t('features.customerSegments.create.title')} marginBottom='0px' guideType='SegmentCreate' />
        <Box display='flex' gap='8px'>
          <Tooltip title={t('features.customerSegments.create.copyLink')} placement='top'>
            <IconButton size='small' sx={{ borderRadius: '8px', padding: '8px' }} onClick={handleCopyLink}>
              <LinkIcon fontSize='small' />
            </IconButton>
          </Tooltip>
          <Button variant='outlined' startIcon={<TroubleshootIcon fontSize='small' />} onClick={handleMetricsDialogOpen}>
            {t('features.customerSegments.create.previewMetrics')}
          </Button>
          <Button
            variant='contained'
            startIcon={<PushPinOutlinedIcon fontSize='small' />}
            disabled={!customerSegments}
            onClick={handleCreateDialogOpen}
          >
            {t('features.customerSegments.create.submit')}
          </Button>
        </Box>
      </Box>

      <DimensionOptionsProvider>
        <QuerySetForm />
      </DimensionOptionsProvider>

      {previewMetricsDialogOpen && (
        <PreviewMetricsDialog
          open={previewMetricsDialogOpen}
          querySetFormMethods={querySetFormMethods}
          handleClose={() => setPreviewMetricsDialogOpen(false)}
        />
      )}

      {createDialogOpen && (
        <CreateDialog
          open={createDialogOpen}
          querySetFormMethods={querySetFormMethods}
          name={decodeURIComponent(nameParam || '')}
          description={decodeURIComponent(descriptionParam || '')}
          handleClose={() => setCreateDialogOpen(false)}
        />
      )}

      {upgradeRecommendDialogOpen && (
        <UpgradeRecommendDialog
          open={upgradeRecommendDialogOpen}
          handleClose={() => setUpgradeRecommendDialogOpen(false)}
          referrer='customerSegmentCreate'
        />
      )}
    </>
  )
}
