import { ListItemButton, ListItemIcon, ListItemText } from '@mui/material'
import { FC } from 'react'

import { SvgIconComponent } from '@mui/icons-material'

type Props = {
  text: string
  icon?: SvgIconComponent
  selected: boolean
  onClick: () => void
}

export const SidebarListItem: FC<Props> = ({ text, icon: Icon, selected, onClick }) => {
  return (
    <ListItemButton
      selected={selected}
      onClick={onClick}
      sx={{
        padding: '2px 12px',
        marginBottom: '2px',
        borderRadius: '2px',
      }}
    >
      {Icon && (
        <ListItemIcon
          sx={{
            minWidth: '32px',
          }}
        >
          <Icon fontSize='small' color={selected ? 'primary' : 'inherit'} />
        </ListItemIcon>
      )}
      <ListItemText
        primary={text}
        primaryTypographyProps={{
          fontSize: '14px',
        }}
      />
    </ListItemButton>
  )
}
