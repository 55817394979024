// @generated by protoc-gen-connect-web v0.2.1 with parameter "target=ts"
// @generated from file proto/customer_segment/customer_segment.proto (package customer_segment, syntax proto3)
/* eslint-disable */
/* @ts-nocheck */

import {CreateRequest, CreateResponse, DeleteRequest, DeleteResponse, EstimateCustomerMetricsRequest, EstimateCustomerMetricsResponse, EstimateCustomersRequest, EstimateCustomersResponse, EstimateMonthlyCohortRequest, EstimateMonthlyCohortResponse, EstimateNthOrderCohortRequest, EstimateNthOrderCohortResponse, GetCustomersRequest, GetCustomersResponse, GetDailyActivitiesRequest, GetDailyActivitiesResponse, GetDailyCustomerMetricsRequest, GetDailyCustomerMetricsResponse, GetLatestCustomerMetricsListRequest, GetLatestCustomerMetricsListResponse, GetOverlapCustomerSegmentsRequest, GetOverlapCustomerSegmentsResponse, UpdateRequest, UpdateResponse, UpdateTagOperationSettingRequest, UpdateTagOperationSettingResponse} from "./customer_segment_pb";
import {MethodKind} from "@bufbuild/protobuf";

/**
 * @generated from service customer_segment.CustomerSegmentService
 */
export const CustomerSegmentService = {
  typeName: "customer_segment.CustomerSegmentService",
  methods: {
    /**
     * @generated from rpc customer_segment.CustomerSegmentService.Create
     */
    create: {
      name: "Create",
      I: CreateRequest,
      O: CreateResponse,
      kind: MethodKind.Unary,
    },
    /**
     * @generated from rpc customer_segment.CustomerSegmentService.Update
     */
    update: {
      name: "Update",
      I: UpdateRequest,
      O: UpdateResponse,
      kind: MethodKind.Unary,
    },
    /**
     * @generated from rpc customer_segment.CustomerSegmentService.Delete
     */
    delete: {
      name: "Delete",
      I: DeleteRequest,
      O: DeleteResponse,
      kind: MethodKind.Unary,
    },
    /**
     * @generated from rpc customer_segment.CustomerSegmentService.GetCustomers
     */
    getCustomers: {
      name: "GetCustomers",
      I: GetCustomersRequest,
      O: GetCustomersResponse,
      kind: MethodKind.Unary,
    },
    /**
     * @generated from rpc customer_segment.CustomerSegmentService.GetLatestCustomerMetricsList
     */
    getLatestCustomerMetricsList: {
      name: "GetLatestCustomerMetricsList",
      I: GetLatestCustomerMetricsListRequest,
      O: GetLatestCustomerMetricsListResponse,
      kind: MethodKind.Unary,
    },
    /**
     * @generated from rpc customer_segment.CustomerSegmentService.GetDailyCustomerMetrics
     */
    getDailyCustomerMetrics: {
      name: "GetDailyCustomerMetrics",
      I: GetDailyCustomerMetricsRequest,
      O: GetDailyCustomerMetricsResponse,
      kind: MethodKind.Unary,
    },
    /**
     * @generated from rpc customer_segment.CustomerSegmentService.GetDailyActivities
     */
    getDailyActivities: {
      name: "GetDailyActivities",
      I: GetDailyActivitiesRequest,
      O: GetDailyActivitiesResponse,
      kind: MethodKind.Unary,
    },
    /**
     * @generated from rpc customer_segment.CustomerSegmentService.GetOverlapCustomerSegments
     */
    getOverlapCustomerSegments: {
      name: "GetOverlapCustomerSegments",
      I: GetOverlapCustomerSegmentsRequest,
      O: GetOverlapCustomerSegmentsResponse,
      kind: MethodKind.Unary,
    },
    /**
     * @generated from rpc customer_segment.CustomerSegmentService.EstimateCustomerMetrics
     */
    estimateCustomerMetrics: {
      name: "EstimateCustomerMetrics",
      I: EstimateCustomerMetricsRequest,
      O: EstimateCustomerMetricsResponse,
      kind: MethodKind.Unary,
    },
    /**
     * @generated from rpc customer_segment.CustomerSegmentService.EstimateCustomers
     */
    estimateCustomers: {
      name: "EstimateCustomers",
      I: EstimateCustomersRequest,
      O: EstimateCustomersResponse,
      kind: MethodKind.Unary,
    },
    /**
     * @generated from rpc customer_segment.CustomerSegmentService.EstimateMonthlyCohort
     */
    estimateMonthlyCohort: {
      name: "EstimateMonthlyCohort",
      I: EstimateMonthlyCohortRequest,
      O: EstimateMonthlyCohortResponse,
      kind: MethodKind.Unary,
    },
    /**
     * @generated from rpc customer_segment.CustomerSegmentService.EstimateNthOrderCohort
     */
    estimateNthOrderCohort: {
      name: "EstimateNthOrderCohort",
      I: EstimateNthOrderCohortRequest,
      O: EstimateNthOrderCohortResponse,
      kind: MethodKind.Unary,
    },
    /**
     * @generated from rpc customer_segment.CustomerSegmentService.UpdateTagOperationSetting
     */
    updateTagOperationSetting: {
      name: "UpdateTagOperationSetting",
      I: UpdateTagOperationSettingRequest,
      O: UpdateTagOperationSettingResponse,
      kind: MethodKind.Unary,
    },
  }
} as const;

