// @generated by protoc-gen-es v0.1.1 with parameter "target=ts"
// @generated from file proto/shop/shop.proto (package shop, syntax proto3)
/* eslint-disable */
/* @ts-nocheck */

import type {BinaryReadOptions, FieldList, JsonReadOptions, JsonValue, PartialMessage, PlainMessage} from "@bufbuild/protobuf";
import {Message, proto3} from "@bufbuild/protobuf";

/**
 * GetAccounts
 *
 * @generated from message shop.GetAccountsRequest
 */
export class GetAccountsRequest extends Message<GetAccountsRequest> {
  constructor(data?: PartialMessage<GetAccountsRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime = proto3;
  static readonly typeName = "shop.GetAccountsRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): GetAccountsRequest {
    return new GetAccountsRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): GetAccountsRequest {
    return new GetAccountsRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): GetAccountsRequest {
    return new GetAccountsRequest().fromJsonString(jsonString, options);
  }

  static equals(a: GetAccountsRequest | PlainMessage<GetAccountsRequest> | undefined, b: GetAccountsRequest | PlainMessage<GetAccountsRequest> | undefined): boolean {
    return proto3.util.equals(GetAccountsRequest, a, b);
  }
}

/**
 * @generated from message shop.GetAccountsResponse
 */
export class GetAccountsResponse extends Message<GetAccountsResponse> {
  /**
   * @generated from field: repeated shop.GetAccountsResponse.Account accounts = 1;
   */
  accounts: GetAccountsResponse_Account[] = [];

  constructor(data?: PartialMessage<GetAccountsResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime = proto3;
  static readonly typeName = "shop.GetAccountsResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "accounts", kind: "message", T: GetAccountsResponse_Account, repeated: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): GetAccountsResponse {
    return new GetAccountsResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): GetAccountsResponse {
    return new GetAccountsResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): GetAccountsResponse {
    return new GetAccountsResponse().fromJsonString(jsonString, options);
  }

  static equals(a: GetAccountsResponse | PlainMessage<GetAccountsResponse> | undefined, b: GetAccountsResponse | PlainMessage<GetAccountsResponse> | undefined): boolean {
    return proto3.util.equals(GetAccountsResponse, a, b);
  }
}

/**
 * @generated from message shop.GetAccountsResponse.Account
 */
export class GetAccountsResponse_Account extends Message<GetAccountsResponse_Account> {
  /**
   * @generated from field: string id = 1;
   */
  id = "";

  /**
   * @generated from field: string email = 2;
   */
  email = "";

  /**
   * AccountRole
   *
   * @generated from field: string role = 3;
   */
  role = "";

  /**
   * AccountStatus
   *
   * @generated from field: string status = 4;
   */
  status = "";

  /**
   * @generated from field: string createdAt = 5;
   */
  createdAt = "";

  constructor(data?: PartialMessage<GetAccountsResponse_Account>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime = proto3;
  static readonly typeName = "shop.GetAccountsResponse.Account";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "email", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "role", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "status", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 5, name: "createdAt", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): GetAccountsResponse_Account {
    return new GetAccountsResponse_Account().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): GetAccountsResponse_Account {
    return new GetAccountsResponse_Account().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): GetAccountsResponse_Account {
    return new GetAccountsResponse_Account().fromJsonString(jsonString, options);
  }

  static equals(a: GetAccountsResponse_Account | PlainMessage<GetAccountsResponse_Account> | undefined, b: GetAccountsResponse_Account | PlainMessage<GetAccountsResponse_Account> | undefined): boolean {
    return proto3.util.equals(GetAccountsResponse_Account, a, b);
  }
}

/**
 * InviteAccount
 *
 * @generated from message shop.InviteAccountRequest
 */
export class InviteAccountRequest extends Message<InviteAccountRequest> {
  /**
   * @generated from field: string email = 1;
   */
  email = "";

  constructor(data?: PartialMessage<InviteAccountRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime = proto3;
  static readonly typeName = "shop.InviteAccountRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "email", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): InviteAccountRequest {
    return new InviteAccountRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): InviteAccountRequest {
    return new InviteAccountRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): InviteAccountRequest {
    return new InviteAccountRequest().fromJsonString(jsonString, options);
  }

  static equals(a: InviteAccountRequest | PlainMessage<InviteAccountRequest> | undefined, b: InviteAccountRequest | PlainMessage<InviteAccountRequest> | undefined): boolean {
    return proto3.util.equals(InviteAccountRequest, a, b);
  }
}

/**
 * @generated from message shop.InviteAccountResponse
 */
export class InviteAccountResponse extends Message<InviteAccountResponse> {
  constructor(data?: PartialMessage<InviteAccountResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime = proto3;
  static readonly typeName = "shop.InviteAccountResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): InviteAccountResponse {
    return new InviteAccountResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): InviteAccountResponse {
    return new InviteAccountResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): InviteAccountResponse {
    return new InviteAccountResponse().fromJsonString(jsonString, options);
  }

  static equals(a: InviteAccountResponse | PlainMessage<InviteAccountResponse> | undefined, b: InviteAccountResponse | PlainMessage<InviteAccountResponse> | undefined): boolean {
    return proto3.util.equals(InviteAccountResponse, a, b);
  }
}

/**
 * RemoveAccount
 *
 * @generated from message shop.RemoveAccountRequest
 */
export class RemoveAccountRequest extends Message<RemoveAccountRequest> {
  /**
   * @generated from field: string email = 1;
   */
  email = "";

  constructor(data?: PartialMessage<RemoveAccountRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime = proto3;
  static readonly typeName = "shop.RemoveAccountRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "email", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): RemoveAccountRequest {
    return new RemoveAccountRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): RemoveAccountRequest {
    return new RemoveAccountRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): RemoveAccountRequest {
    return new RemoveAccountRequest().fromJsonString(jsonString, options);
  }

  static equals(a: RemoveAccountRequest | PlainMessage<RemoveAccountRequest> | undefined, b: RemoveAccountRequest | PlainMessage<RemoveAccountRequest> | undefined): boolean {
    return proto3.util.equals(RemoveAccountRequest, a, b);
  }
}

/**
 * @generated from message shop.RemoveAccountResponse
 */
export class RemoveAccountResponse extends Message<RemoveAccountResponse> {
  constructor(data?: PartialMessage<RemoveAccountResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime = proto3;
  static readonly typeName = "shop.RemoveAccountResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): RemoveAccountResponse {
    return new RemoveAccountResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): RemoveAccountResponse {
    return new RemoveAccountResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): RemoveAccountResponse {
    return new RemoveAccountResponse().fromJsonString(jsonString, options);
  }

  static equals(a: RemoveAccountResponse | PlainMessage<RemoveAccountResponse> | undefined, b: RemoveAccountResponse | PlainMessage<RemoveAccountResponse> | undefined): boolean {
    return proto3.util.equals(RemoveAccountResponse, a, b);
  }
}

/**
 * SubmitSurveyAnswers
 *
 * @generated from message shop.SubmitSurveyAnswersRequest
 */
export class SubmitSurveyAnswersRequest extends Message<SubmitSurveyAnswersRequest> {
  /**
   * @generated from field: string referrer = 1;
   */
  referrer = "";

  /**
   * @generated from field: string role = 2;
   */
  role = "";

  /**
   * @generated from field: string purpose = 3;
   */
  purpose = "";

  constructor(data?: PartialMessage<SubmitSurveyAnswersRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime = proto3;
  static readonly typeName = "shop.SubmitSurveyAnswersRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "referrer", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "role", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "purpose", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): SubmitSurveyAnswersRequest {
    return new SubmitSurveyAnswersRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): SubmitSurveyAnswersRequest {
    return new SubmitSurveyAnswersRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): SubmitSurveyAnswersRequest {
    return new SubmitSurveyAnswersRequest().fromJsonString(jsonString, options);
  }

  static equals(a: SubmitSurveyAnswersRequest | PlainMessage<SubmitSurveyAnswersRequest> | undefined, b: SubmitSurveyAnswersRequest | PlainMessage<SubmitSurveyAnswersRequest> | undefined): boolean {
    return proto3.util.equals(SubmitSurveyAnswersRequest, a, b);
  }
}

/**
 * @generated from message shop.SubmitSurveyAnswersResponse
 */
export class SubmitSurveyAnswersResponse extends Message<SubmitSurveyAnswersResponse> {
  constructor(data?: PartialMessage<SubmitSurveyAnswersResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime = proto3;
  static readonly typeName = "shop.SubmitSurveyAnswersResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): SubmitSurveyAnswersResponse {
    return new SubmitSurveyAnswersResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): SubmitSurveyAnswersResponse {
    return new SubmitSurveyAnswersResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): SubmitSurveyAnswersResponse {
    return new SubmitSurveyAnswersResponse().fromJsonString(jsonString, options);
  }

  static equals(a: SubmitSurveyAnswersResponse | PlainMessage<SubmitSurveyAnswersResponse> | undefined, b: SubmitSurveyAnswersResponse | PlainMessage<SubmitSurveyAnswersResponse> | undefined): boolean {
    return proto3.util.equals(SubmitSurveyAnswersResponse, a, b);
  }
}

/**
 * UpdateOrderPricingRule
 *
 * @generated from message shop.UpdateOrderPricingRuleRequest
 */
export class UpdateOrderPricingRuleRequest extends Message<UpdateOrderPricingRuleRequest> {
  /**
   * @generated from field: string orderPricingRule = 1;
   */
  orderPricingRule = "";

  constructor(data?: PartialMessage<UpdateOrderPricingRuleRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime = proto3;
  static readonly typeName = "shop.UpdateOrderPricingRuleRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "orderPricingRule", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): UpdateOrderPricingRuleRequest {
    return new UpdateOrderPricingRuleRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): UpdateOrderPricingRuleRequest {
    return new UpdateOrderPricingRuleRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): UpdateOrderPricingRuleRequest {
    return new UpdateOrderPricingRuleRequest().fromJsonString(jsonString, options);
  }

  static equals(a: UpdateOrderPricingRuleRequest | PlainMessage<UpdateOrderPricingRuleRequest> | undefined, b: UpdateOrderPricingRuleRequest | PlainMessage<UpdateOrderPricingRuleRequest> | undefined): boolean {
    return proto3.util.equals(UpdateOrderPricingRuleRequest, a, b);
  }
}

/**
 * @generated from message shop.UpdateOrderPricingRuleResponse
 */
export class UpdateOrderPricingRuleResponse extends Message<UpdateOrderPricingRuleResponse> {
  constructor(data?: PartialMessage<UpdateOrderPricingRuleResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime = proto3;
  static readonly typeName = "shop.UpdateOrderPricingRuleResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): UpdateOrderPricingRuleResponse {
    return new UpdateOrderPricingRuleResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): UpdateOrderPricingRuleResponse {
    return new UpdateOrderPricingRuleResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): UpdateOrderPricingRuleResponse {
    return new UpdateOrderPricingRuleResponse().fromJsonString(jsonString, options);
  }

  static equals(a: UpdateOrderPricingRuleResponse | PlainMessage<UpdateOrderPricingRuleResponse> | undefined, b: UpdateOrderPricingRuleResponse | PlainMessage<UpdateOrderPricingRuleResponse> | undefined): boolean {
    return proto3.util.equals(UpdateOrderPricingRuleResponse, a, b);
  }
}

