import CloseIcon from '@mui/icons-material/Close'
import SearchRoundedIcon from '@mui/icons-material/SearchRounded'
import {
  Box,
  Chip,
  CircularProgress,
  Dialog,
  IconButton,
  InputAdornment,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  ListSubheader,
  TextField,
  Typography,
} from '@mui/material'
import { insightDimensions } from 'features/insights/types/insightDimensions'
import { orderBy, query } from 'firebase/firestore'
import { useAccount } from 'hooks/useAccount'
import { useSubCollectionRef } from 'hooks/useCollectionRef'
import { useCollectionSubscription } from 'hooks/useFirestoreData'
import { useActionTracker } from 'hooks/useMixpanel'
import { FC, useEffect, useMemo, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { generatePath, useNavigate } from 'react-router-dom'
import { AUTHORIZED_ROUTE } from 'routing'
import { extractIconFromTextWithIcon, extractTextFromTextWithIcon } from 'utils/iconUtil'

type Props = {
  open: boolean
  handleClose: () => void
}

type SearchItem = {
  name: string
  path: string
  desc?: string
  external?: boolean
}

type SearchItemList = {
  category: string
  items: SearchItem[]
}

const searchItemListHeight = '300px'

export const SearchDialog: FC<Props> = ({ open, handleClose }) => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const { account } = useAccount()
  const { dispatch } = useActionTracker()

  const { customerSegmentsRef, segmentGroupsRef } = useSubCollectionRef(account.shopRef!.id)
  const { data: customerSegments } = useCollectionSubscription(query(customerSegmentsRef))
  const { data: segmentGroups } = useCollectionSubscription(query(segmentGroupsRef, orderBy('createdAt', 'desc')))

  const [searchText, setSearchText] = useState('')
  const [selectedCategory, setSelectedCategory] = useState('')
  const [loading, setLoading] = useState(false)

  const textFieldRef = useRef<HTMLInputElement>(null)

  useEffect(() => {
    const focusTextField = () => {
      if (textFieldRef.current) {
        textFieldRef.current.focus()
      }
    }
    if (open) {
      setTimeout(focusTextField, 0)
    }
  }, [open])

  const categories = [t('common.searchDialog.segments'), t('common.searchDialog.groups'), t('common.searchDialog.insights')]

  const searchItems: SearchItemList[] = useMemo(
    () => [
      {
        category: t('common.searchDialog.segments'),
        items:
          customerSegments?.map((segment) => ({
            name: segment.name,
            path: generatePath(AUTHORIZED_ROUTE.CUSTOMER_SEGMENT_DETAIL, { id: segment.ref.id }),
          })) || [],
      },
      {
        category: t('common.searchDialog.groups'),
        items:
          segmentGroups?.map((group) => ({
            name: group.name,
            path: generatePath(AUTHORIZED_ROUTE.CUSTOMER_SEGMENT_GROUP, { id: group.ref.id }),
          })) || [],
      },
      {
        category: t('common.searchDialog.insights'),
        items: insightDimensions.map((insight) => ({
          name: t('features.insights.dimension', { context: insight.name }),
          path: generatePath(AUTHORIZED_ROUTE.INSIGHT_DETAIL, { id: insight.name }),
          desc: t(`features.insights.questions.${insight.name}_${insight.reportTypes[0]}`),
        })),
      },
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [customerSegments, segmentGroups, insightDimensions]
  )

  const filteredSearchItems = useMemo(() => {
    return searchItems
      .filter((searchItem) => (selectedCategory ? searchItem.category === selectedCategory : true))
      .map((searchItem) => ({
        category: searchItem.category,
        items: searchItem.items.filter((item) => item.name.toLowerCase().includes(searchText.toLowerCase())),
      }))
  }, [searchItems, searchText, selectedCategory])

  useEffect(() => {
    dispatch('OpenSearchDialog')
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      maxWidth='sm'
      fullWidth
      PaperProps={{
        sx: { borderRadius: '8px' },
      }}
    >
      <Box sx={{ bgcolor: 'background.paper' }}>
        <TextField
          inputRef={textFieldRef}
          fullWidth
          placeholder={t('common.searchDialog.placeholder')}
          value={searchText}
          InputProps={{
            startAdornment: (
              <InputAdornment position='start'>
                <SearchRoundedIcon />
              </InputAdornment>
            ),
            endAdornment: searchText && (
              <InputAdornment position='end'>
                <IconButton size='small' onClick={() => setSearchText('')} sx={{ borderRadius: '8px' }}>
                  <CloseIcon fontSize='small' />
                </IconButton>
              </InputAdornment>
            ),
          }}
          onChange={(e) => {
            setSearchText(e.target.value)
            setLoading(true)
            setTimeout(() => {
              setLoading(false)
            }, 500)
          }}
          sx={{
            '& .MuiOutlinedInput-root': {
              borderRadius: 0,
              borderBottom: '1px solid',
              borderColor: 'divider',
              '&:hover': {
                backgroundColor: 'transparent',
              },
              '& fieldset': {
                border: 'none',
              },
            },
          }}
        />
        <Box padding='18px 12px 12px'>
          {categories.map((category, index) => (
            <Chip
              key={`category-${index}`}
              label={category}
              size='small'
              color='default'
              variant={category === selectedCategory ? 'filled' : 'outlined'}
              onClick={() => {
                if (category === selectedCategory) {
                  setSelectedCategory('')
                  return
                }
                setSelectedCategory(category)
              }}
              sx={{ cursor: 'pointer', marginLeft: index !== 0 ? '8px' : '0px', padding: '12px 4px', borderRadius: '6px' }}
            />
          ))}
        </Box>
        <Box>
          {loading ? (
            <Box display='flex' justifyContent='center' alignItems='center' height={searchItemListHeight}>
              <CircularProgress />
            </Box>
          ) : filteredSearchItems.every((item) => item.items.length === 0) ? (
            <Box display='flex' justifyContent='center' alignItems='center' height={searchItemListHeight}>
              {t('common.searchDialog.noResults', { input: searchText })}
            </Box>
          ) : (
            <List
              sx={{
                overflow: 'auto',
                maxHeight: searchItemListHeight,
              }}
              dense
              subheader={<li />}
            >
              {filteredSearchItems.map((searchItem, sectionId) => (
                <Box key={`section-${sectionId}`}>
                  {searchItem.items.length > 0 && <ListSubheader sx={{ lineHeight: 2.5 }}>{searchItem.category}</ListSubheader>}
                  {searchItem.items.map((item, itemId) => (
                    <ListItem
                      key={`item-${sectionId}-${itemId}`}
                      disablePadding
                      dense
                      sx={{ marginBottom: itemId === searchItem.items.length - 1 ? '12px' : '0px' }}
                    >
                      <ListItemButton
                        sx={{ padding: '6px 24px', borderRadius: '8px' }}
                        onClick={() => {
                          handleClose()
                          if (item.external) {
                            window.open(item.path, '_blank')
                          } else {
                            navigate(item.path)
                          }
                        }}
                      >
                        <Typography fontSize='20px' marginRight='16px'>
                          {extractIconFromTextWithIcon(item.name)}
                        </Typography>
                        <ListItemText primary={extractTextFromTextWithIcon(item.name)} secondary={item.desc} />
                      </ListItemButton>
                    </ListItem>
                  ))}
                </Box>
              ))}
            </List>
          )}
        </Box>
      </Box>
    </Dialog>
  )
}
