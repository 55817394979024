export type GuideType =
  | 'Home'
  | 'UpgradeFeature'
  | 'SegmentOverview'
  | 'SegmentCreate'
  | 'SegmentMetrics'
  | 'SegmentSync'
  | 'CustomerJourney'
  | 'CustomerInsights'
  | 'MonthlyCohort'
  | 'NthOrderCohort'
  | 'Members'
  | 'AppIntegrations'
  | 'UseCases'

const HOST_MAP: Record<string, string> = {
  en: 'https://docs.ec-power.io/en',
  ja: 'https://docs.ec-power.io/ja',
}

const GUIDE_PATH_MAP: Record<GuideType, string> = {
  Home: '',
  UpgradeFeature: '/articles/26813655',
  SegmentOverview: '/categories/464d6672',
  SegmentCreate: '/articles/fc1464e3',
  SegmentMetrics: '/articles/902262c8',
  SegmentSync: '/articles/f98b2d93',
  CustomerJourney: '/articles/fa4ab7d4',
  CustomerInsights: '/articles/534dfda0',
  MonthlyCohort: '/articles/4dae85e1',
  NthOrderCohort: '/articles/37fae212',
  Members: '/articles/01ea2394',
  AppIntegrations: '/categories/6a779d8e',
  UseCases: '/categories/a0b287b5',
}

export const getGuideUrl = (lang: string, guideType: GuideType): string => {
  if (!HOST_MAP[lang]) lang = 'en'
  return `${HOST_MAP[lang]}${GUIDE_PATH_MAP[guideType]}`
}
