import EditIcon from '@mui/icons-material/Edit'
import { Box, Divider, Grid, IconButton, Paper, Skeleton, Tooltip, Typography } from '@mui/material'
import { Shop } from 'gen/firestore'
import { FC, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { UpdateOrderPricingRuleDialog } from './components/updateOrderPricingRuleDialog'

type Props = {
  shop: Shop | undefined
}

export const Workspace: FC<Props> = ({ shop }) => {
  const { t } = useTranslation()

  const [updateOrderPricingRuleDialogOpen, setUpdateOrderPricingRuleDialogOpen] = useState(false)

  return (
    <>
      <Paper sx={{ padding: '32px', marginBottom: '24px', borderRadius: '12px' }} variant='outlined'>
        <Box>
          <Typography fontWeight='bold' marginBottom='16px'>
            {t('features.settings.workspace.general')}
          </Typography>

          <Grid container spacing={1} paddingLeft='4px'>
            <Grid fontSize='14px' item xs={1.5}>
              {t('features.settings.workspace.name')}
            </Grid>
            <Grid fontSize='14px' item xs={10.5}>
              {shop ? shop.displayName : <Skeleton width={'120px'} />}
            </Grid>

            <Grid fontSize='14px' item xs={1.5}>
              {t('features.settings.workspace.shopName')}
            </Grid>
            <Grid fontSize='14px' item xs={10.5}>
              {shop ? shop.shopName : <Skeleton width={'120px'} />}
            </Grid>

            <Grid fontSize='14px' item xs={1.5}>
              {t('features.settings.workspace.currency')}
            </Grid>
            <Grid fontSize='14px' item xs={10.5}>
              {shop ? shop.currency : <Skeleton width={'120px'} />}
            </Grid>

            <Grid fontSize='14px' item xs={1.5}>
              {t('features.settings.workspace.timezone')}
            </Grid>
            <Grid fontSize='14px' item xs={10.5}>
              {shop ? shop.timezone : <Skeleton width={'120px'} />}
            </Grid>
          </Grid>
        </Box>

        <Divider sx={{ marginY: '24px' }} />

        <Box>
          <Typography fontWeight='bold' marginBottom='16px'>
            {t('features.settings.workspace.orderPricing')}
          </Typography>

          <Grid container spacing={1} paddingLeft='4px'>
            <Grid fontSize='14px' item xs={1.5}>
              {t('features.settings.workspace.rule')}
            </Grid>
            <Grid fontSize='14px' item xs={10.5}>
              <Box display='flex' alignItems='center'>
                <Typography fontSize='14px'>
                  {shop ? t('features.settings.workspace.orderPricingRule', { context: shop.orderPricingRule }) : <Skeleton width='120px' />}
                </Typography>
                <Tooltip title={t('features.settings.edit')} placement='right'>
                  <IconButton
                    size='small'
                    onClick={() => setUpdateOrderPricingRuleDialogOpen(true)}
                    sx={{ marginLeft: '4px', padding: '4px', borderRadius: '8px' }}
                  >
                    <EditIcon fontSize='inherit' sx={{ fontSize: '14px' }} />
                  </IconButton>
                </Tooltip>
              </Box>
            </Grid>
          </Grid>
        </Box>
      </Paper>

      {shop && (
        <UpdateOrderPricingRuleDialog
          open={updateOrderPricingRuleDialogOpen}
          shop={shop}
          handleClose={() => setUpdateOrderPricingRuleDialogOpen(false)}
        />
      )}
    </>
  )
}
