import { getIdToken } from 'firebase/auth'
import { orderBy, query } from 'firebase/firestore'
import { CustomerMetrics } from 'gen/firestore'
import { CustomerSegmentService } from 'gen/proto/customer_segment/customer_segment_connectweb'
import { useAccount } from 'hooks/useAccount'
import { useAuthUser } from 'hooks/useAuthUser'
import { useSubCollectionRef } from 'hooks/useCollectionRef'
import { useCustomSnackbar } from 'hooks/useCustomSnackbar'
import { useCollectionSubscription } from 'hooks/useFirestoreData'
import { useGrpcClient } from 'hooks/useGrpcClient'
import { useSentryNotifier } from 'hooks/useSentryNotifier'
import { t } from 'i18next'
import { useEffect, useMemo, useState } from 'react'
import { timestampToDayjs } from 'utils/timeUtil'
import { Overview as CustomerSegmentsOverview } from './overview/overview'

export const CustomerSegments = () => {
  const authUser = useAuthUser()
  const { enqueueSnackbar } = useCustomSnackbar()
  const { notifySentry } = useSentryNotifier()

  const { account } = useAccount()
  const { customerSegmentsRef } = useSubCollectionRef(account.shopRef!.id)
  const { data: customerSegmentData } = useCollectionSubscription(query(customerSegmentsRef, orderBy('createdAt', 'desc')))

  const [metricsMap, setMetricsMap] = useState<Map<string, CustomerMetrics>>()
  const customerSegmentService = useGrpcClient(CustomerSegmentService)

  useEffect(() => {
    if (!customerSegments || customerSegments.length === 0) return // No segments to fetch
    if (customerSegments.every((s) => s.metrics)) return // Metrics are already fetched

    const handleFetch = async () => {
      try {
        const token = await getIdToken(authUser!)
        const resp = await customerSegmentService.getLatestCustomerMetricsList(
          { customerSegmentIds: customerSegments.map((s) => s.id) },
          { headers: { Authorization: `Bearer ${token}` } }
        )
        const metricsMap = new Map(
          resp.result.map((metric) => [
            metric.customerSegmentId,
            {
              count: Number(metric.count),
              repeaterRate: metric.repeaterRate,
              ltv: metric.ltv,
              avgOrderValue: metric.avgOrderValue,
              avgOrderCount: metric.avgOrderCount,
              avgDaysBetweenOrders: metric.avgDaysBetweenOrders,
            },
          ])
        )
        setMetricsMap(metricsMap)
      } catch (err) {
        enqueueSnackbar(t('common.messageDataFetchError'), { severity: 'error' })
        notifySentry(err)
      }
    }

    handleFetch()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [customerSegmentData?.map((s) => s.ref.id)])

  const customerSegments = useMemo(() => {
    return customerSegmentData?.map((d) => ({
      id: d.ref.id,
      name: d.name,
      description: d.description,
      querySet: d.querySet,
      favorited: d.favorited,
      metrics: metricsMap?.get(d.ref.id),
      tagOperationSetting: d.tagOperationSetting,
      createdAt: timestampToDayjs(d.createdAt),
    }))
  }, [customerSegmentData, metricsMap])

  return (
    <>
      <CustomerSegmentsOverview rows={customerSegments} />
    </>
  )
}
