import FileDownloadOutlinedIcon from '@mui/icons-material/FileDownloadOutlined'
import NotificationImportantRoundedIcon from '@mui/icons-material/NotificationImportantRounded'
import PollOutlinedIcon from '@mui/icons-material/PollOutlined'
import TableRowsOutlinedIcon from '@mui/icons-material/TableRowsOutlined'
import {
  Box,
  CircularProgress,
  IconButton,
  MenuItem,
  Pagination,
  Paper,
  Select,
  Skeleton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
  Typography,
  useTheme,
} from '@mui/material'
import { EmptyState } from 'components/emptyState'
import { UpgradeRecommendDialog } from 'components/upgradeRecommendDialog'
import { billingStatus } from 'config/plan'
import dayjs from 'dayjs'
import { CustomerSegmentState } from 'features/customerSegments/types/types'
import { ShopBillingStatus } from 'gen/firestore'
import { useAccount } from 'hooks/useAccount'
import { useCsvDownload } from 'hooks/useCsvDownload'
import { useCurrency } from 'hooks/useCurrency'
import { FC, useState } from 'react'
import Chart from 'react-apexcharts'
import { useTranslation } from 'react-i18next'
import { formatDate } from 'utils/timeUtil'
import { convertTimeRangeToStartEndDate, TimeRange } from '../types/timeRange'
import { ActivityState } from '../types/types'

type Props = {
  customerSegment: CustomerSegmentState | undefined
  activities: ActivityState[] | undefined
  loading: boolean
  handleTimeRangeChange: (startDate: string, endDate: string) => void
}

enum ViewType {
  chart = 'chart',
  table = 'table',
}

const CHART_HEIGHT = 420

export const Activities: FC<Props> = ({ customerSegment, activities, loading, handleTimeRangeChange }) => {
  const { t, i18n } = useTranslation()
  const { shop } = useAccount()
  const theme = useTheme()
  const shopBillingStatus = billingStatus(shop, dayjs())
  const { formatCurrency } = useCurrency()

  const [viewType, setViewType] = useState<ViewType>(ViewType.chart)
  const [timeRange, setTimeRange] = useState(TimeRange.last7Days)
  const [upgradeRecommendDialogOpen, setUpgradeRecommendDialogOpen] = useState(false)
  const [page, setPage] = useState(0)
  const rowsPerPage = 14

  const updateTimeRangeValue = (v: TimeRange) => {
    if (shopBillingStatus === ShopBillingStatus.free) {
      setUpgradeRecommendDialogOpen(true)
      setTimeRange(TimeRange.last7Days)
      return
    }
    setTimeRange(v)
    const { startDate, endDate } = convertTimeRangeToStartEndDate(v, shop.timezone)
    handleTimeRangeChange(startDate, endDate)
  }

  const { handleDownload, loading: downloadLoading } = useCsvDownload({
    data: activities?.map((o) => ({
      date: o.date,
      orderAmount: o.orderAmount,
      orderCount: o.orderCount,
    })),
    headers: [
      t('features.customerSegments.detail.activities.date'),
      t('features.customerSegments.detail.activities.orderAmount'),
      t('features.customerSegments.detail.activities.orderCount'),
    ],
    fileName: `${customerSegment?.name}_activities_${formatDate(dayjs(), i18n.language)}`,
    page: 'customerSegment_activities',
  })

  return (
    <>
      <Paper sx={{ padding: '32px', borderRadius: '12px' }} variant='outlined'>
        <Box display='flex' justifyContent='space-between' alignItems='center' marginBottom='24px'>
          <Box>
            <Typography fontWeight='bold' marginBottom='2px'>
              {t('features.customerSegments.detail.activities.title')}
            </Typography>
            <Box display='flex' alignItems='center' gap='2px'>
              <Typography fontSize='13px' color='text.secondary'>
                {loading ? (
                  <Skeleton width='100px' />
                ) : (
                  t('features.customerSegments.detail.activities.totalRevenue', {
                    revenue: formatCurrency(activities?.reduce((total, activity) => total + activity.orderAmount, 0) || 0),
                  })
                )}
              </Typography>
              <Typography fontSize='13px' color='text.secondary'>
                {'・'}
              </Typography>
              <Typography fontSize='13px' color='text.secondary'>
                {loading ? (
                  <Skeleton width='100px' />
                ) : (
                  t('features.customerSegments.detail.activities.totalCount', {
                    count: activities?.reduce((total, activity) => total + activity.orderCount, 0) || 0,
                  })
                )}
              </Typography>
            </Box>
          </Box>
          <Box display='flex' alignItems='center' gap='8px'>
            <Select
              size='small'
              variant='standard'
              disabled={!activities}
              value={timeRange}
              onChange={(e) => {
                const newValue = e.target.value as TimeRange
                updateTimeRangeValue(newValue)
              }}
              sx={{
                fontSize: '14px',
                marginRight: '4px',
                '& .MuiSelect-select': {
                  borderRadius: '2px',
                  paddingX: '8px',
                },
              }}
            >
              {Object.values(TimeRange).map((value) => (
                <MenuItem key={value} value={value} dense>
                  {t(`features.customerSegments.detail.timeRange_${value}`)}
                </MenuItem>
              ))}
            </Select>
            <Tooltip
              title={
                viewType === ViewType.chart
                  ? t('features.customerSegments.detail.activities.viewChart')
                  : t('features.customerSegments.detail.activities.viewTable')
              }
              placement='top'
            >
              <IconButton
                size='small'
                onClick={() => setViewType(viewType === ViewType.chart ? ViewType.table : ViewType.chart)}
                sx={{ borderRadius: '8px' }}
              >
                {viewType === ViewType.chart ? <TableRowsOutlinedIcon fontSize='small' /> : <PollOutlinedIcon fontSize='small' />}
              </IconButton>
            </Tooltip>
            <Tooltip title={t('features.customerSegments.detail.activities.download')} placement='top'>
              <span>
                <IconButton
                  size='small'
                  disabled={!activities || downloadLoading}
                  onClick={() => {
                    if (shopBillingStatus === ShopBillingStatus.free) {
                      setUpgradeRecommendDialogOpen(true)
                      return
                    }
                    handleDownload()
                  }}
                  sx={{ borderRadius: '8px' }}
                >
                  <FileDownloadOutlinedIcon fontSize='small' />
                </IconButton>
              </span>
            </Tooltip>
          </Box>
        </Box>

        {activities && activities.length === 1 && (
          <Paper
            variant='outlined'
            sx={{
              borderRadius: '4px',
              border: 'none',
              padding: '14px 20px',
              backgroundColor: (theme) => theme.palette.primary.main + '1A',
              marginBottom: '24px',
              marginTop: '-8px',
            }}
          >
            <Box display='flex' alignItems='center'>
              <NotificationImportantRoundedIcon fontSize='small' color='primary' sx={{ marginRight: '12px' }} />
              <Typography fontSize='14px'>{t('features.customerSegments.detail.activities.alert')}</Typography>
            </Box>
          </Paper>
        )}

        {loading || !activities ? (
          <Box height={CHART_HEIGHT} display='flex' justifyContent='center' alignItems='center'>
            <CircularProgress size={24} />
          </Box>
        ) : activities.length === 0 ? (
          <EmptyState title={t('features.customerSegments.detail.activities.empty')} />
        ) : viewType === ViewType.chart ? (
          <Chart
            type='area'
            height={CHART_HEIGHT}
            series={[
              {
                name: t('features.customerSegments.detail.activities.orderAmount'),
                data: activities.map((r) => ({ x: r.date, y: r.orderAmount })),
              },
            ]}
            options={{
              xaxis: {
                tickAmount: 20,
                labels: {
                  style: {
                    colors: theme.palette.text.primary,
                  },
                },
              },
              yaxis: {
                labels: {
                  formatter: (val) => {
                    return formatCurrency(val) || ''
                  },
                  style: {
                    colors: theme.palette.text.primary,
                  },
                },
              },
              tooltip: {
                theme: theme.palette.mode,
                y: {
                  formatter: (val) => {
                    return formatCurrency(val) || ''
                  },
                },
              },
              dataLabels: {
                enabled: true,
                offsetY: -6,
                background: {
                  enabled: false,
                },
                style: {
                  fontSize: '12px',
                  fontWeight: 'normal',
                },
                formatter: (val: number) => {
                  return formatCurrency(val) || ''
                },
              },
              stroke: {
                curve: 'smooth',
                width: 2,
              },
              chart: {
                zoom: { enabled: false },
                toolbar: { show: false },
                animations: {
                  enabled: false,
                },
              },
              fill: {
                type: 'gradient',
              },
              colors: [theme.palette.primary.main],
            }}
          />
        ) : (
          <>
            <TableContainer>
              <Table size='small'>
                <TableHead>
                  <TableRow
                    sx={{
                      '& .MuiTableCell-head': {
                        color: (theme) => theme.palette.text.secondary,
                        fontSize: '13px',
                        whiteSpace: 'nowrap',
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                      },
                    }}
                  >
                    <TableCell>{t('features.customerSegments.detail.activities.date')}</TableCell>
                    <TableCell align='center'>{t('features.customerSegments.detail.activities.orderAmount')}</TableCell>
                    <TableCell align='center'>{t('features.customerSegments.detail.activities.orderCount')}</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {activities &&
                    activities.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row) => (
                      <TableRow key={row.date}>
                        <TableCell>{row.date}</TableCell>
                        <TableCell align='center'>{formatCurrency(row.orderAmount) || <Skeleton />}</TableCell>
                        <TableCell align='center'>{row.orderCount}</TableCell>
                      </TableRow>
                    ))}
                </TableBody>
              </Table>
            </TableContainer>

            <Box display='flex' justifyContent='center'>
              <Pagination
                shape='rounded'
                count={activities ? Math.ceil(activities.length / rowsPerPage) : 0}
                page={page + 1}
                onChange={(_, v) => setPage(v - 1)}
                sx={{ marginTop: '20px' }}
              />
            </Box>
          </>
        )}
      </Paper>

      {upgradeRecommendDialogOpen && (
        <UpgradeRecommendDialog
          open={upgradeRecommendDialogOpen}
          handleClose={() => setUpgradeRecommendDialogOpen(false)}
          referrer='customerSegmentOrders'
        />
      )}
    </>
  )
}
