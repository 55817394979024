import ArrowBackIcon from '@mui/icons-material/ArrowBack'
import AutoFixHighOutlinedIcon from '@mui/icons-material/AutoFixHighOutlined'
import EmojiObjectsOutlinedIcon from '@mui/icons-material/EmojiObjectsOutlined'
import LocalOfferOutlinedIcon from '@mui/icons-material/LocalOfferOutlined'
import MoreHorizIcon from '@mui/icons-material/MoreHoriz'
import Person2OutlinedIcon from '@mui/icons-material/Person2Outlined'
import ShowChartOutlinedIcon from '@mui/icons-material/ShowChartOutlined'
import TextSnippetOutlinedIcon from '@mui/icons-material/TextSnippetOutlined'
import { TabContext, TabList, TabPanel } from '@mui/lab'
import { Avatar, Box, Collapse, Divider, IconButton, Paper, Skeleton, Tab, Tooltip, Typography } from '@mui/material'
import { UpgradeRecommendDialog } from 'components/upgradeRecommendDialog'
import { billingStatus } from 'config/plan'
import dayjs from 'dayjs'
import { DetailSegmentMenu } from 'features/customerSegments/components/detailSegmentMenu'
import { convertQuerySetToForm, convertStringToApiQuerySetState } from 'features/customerSegments/querySetForm/schema/converter'
import { QuerySet } from 'features/customerSegments/querySetView/querySet'
import { ShopBillingStatus } from 'gen/firestore'
import { useAccount } from 'hooks/useAccount'
import { useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Link, useLocation, useNavigate, useParams } from 'react-router-dom'
import { AUTHORIZED_ROUTE } from 'routing'
import { extractIconFromTextWithIcon, extractTextFromTextWithIcon } from 'utils/iconUtil'
import { Activities } from './components/activities'
import { Customers } from './components/customers'
import { Insights } from './components/insights'
import { Metrics } from './components/metrics'
import { SuggestInsightDialog } from './components/suggestInsightDialog'
import { Sync } from './components/sync'
import { useActivities } from './hooks/useActivities'
import { useCustomerSegment } from './hooks/useCustomerSegment'
import { useCustomers } from './hooks/useCustomers'
import { useInsights } from './hooks/useInsights'
import { useMetricsHistory } from './hooks/useMetricsHistory'
import { useTagsRunningStatus } from './hooks/useTagsRunningStatus'
import { convertTimeRangeToStartEndDate, TimeRange } from './types/timeRange'

enum TabIndex {
  metrics = 'metrics',
  activities = 'activities',
  insights = 'insights',
  customers = 'customers',
  sync = 'sync',
}

export const Detail = () => {
  const { t } = useTranslation()
  const location = useLocation()
  const navigate = useNavigate()
  const { shop } = useAccount()
  const shopBillingStatus = billingStatus(shop, dayjs())

  const params = useParams()
  const customerSegmentId = params.id
  const customerSegment = useCustomerSegment(customerSegmentId)

  const { startDate: initMetricsStartDate, endDate: initMetricsEndDate } = convertTimeRangeToStartEndDate(TimeRange.last7Days, shop.timezone)
  const [metricsStartDate, setMetricsStartDate] = useState(initMetricsStartDate)
  const [metricsEndDate, setMetricsEndDate] = useState(initMetricsEndDate)
  const { metricsHistory, loading: loadingMetricsHistory } = useMetricsHistory(customerSegmentId, metricsStartDate, metricsEndDate)

  const { startDate: initActivitiesStartDate, endDate: initActivitiesEndDate } = convertTimeRangeToStartEndDate(TimeRange.last7Days, shop.timezone)
  const [activitiesStartDate, setActivitiesStartDate] = useState(initActivitiesStartDate)
  const [activitiesEndDate, setActivitiesEndDate] = useState(initActivitiesEndDate)
  const { activities, loading: loadingActivities } = useActivities(customerSegmentId, activitiesStartDate, activitiesEndDate)

  const { customers, loading: loadingCustomers } = useCustomers(customerSegment, 100)

  const { currentTagRunning, otherTagsRunning } = useTagsRunningStatus(customerSegmentId)
  const {
    productInsightValues,
    customerTagInsightValues,
    recencyInsightValue,
    overlapCustomerSegments,
    loading: loadingInsights,
  } = useInsights(customerSegmentId)

  const [menuOpen, setMenuOpen] = useState(false)
  const menuAnchorRef = useRef<HTMLButtonElement | null>(null)
  const [collapseOpen, setCollapseOpen] = useState(false)
  const [suggestInsightDialogOpen, setSuggestInsightDialogOpen] = useState(false)
  const [upgradeRecommendDialogOpen, setUpgradeRecommendDialogOpen] = useState(false)
  // const [restoreDialogOpen, setRestoreDialogOpen] = useState(false)

  const initialTabIndex = (): TabIndex => {
    if (location.hash === `#${TabIndex.activities}`) return TabIndex.activities
    if (location.hash === `#${TabIndex.insights}`) return TabIndex.insights
    if (location.hash === `#${TabIndex.customers}`) return TabIndex.customers
    if (location.hash === `#${TabIndex.sync}`) return TabIndex.sync
    return TabIndex.metrics
  }
  const [tabIndex, setTabIndex] = useState<TabIndex>(initialTabIndex)

  return (
    <>
      <Box marginBottom='12px'>
        <Box
          display='flex'
          alignItems='center'
          marginBottom='6px'
          sx={{ fontSize: '14px', color: (theme) => theme.palette.text.secondary, cursor: 'pointer', '&:hover': { textDecoration: 'underline' } }}
          onClick={() => navigate(AUTHORIZED_ROUTE.CUSTOMER_SEGMENTS)}
          width='fit-content'
        >
          <ArrowBackIcon sx={{ fontSize: '14px', marginRight: '4px' }} />
          <Typography fontSize='12px'>{t('features.customerSegments.detail.backToOverview')}</Typography>
        </Box>

        <Box display='flex' justifyContent='space-between' alignItems='center'>
          <Box display='flex' alignItems='center' gap='12px'>
            {customerSegment?.name && (
              <Avatar sx={{ borderRadius: '6px', backgroundColor: (theme) => theme.palette.grey[200], fontSize: '20px' }}>
                {extractIconFromTextWithIcon(customerSegment.name)}
              </Avatar>
            )}
            <Box>
              <Typography fontSize='16px'>
                {customerSegment ? extractTextFromTextWithIcon(customerSegment.name) : <Skeleton width='120px' />}
              </Typography>
              <Box display='flex' alignItems='center' gap='2px'>
                <Typography fontSize='12px' color='text.secondary'>
                  {t('features.customerSegments.detail.dailyDataSync')}: {t('features.customerSegments.detail.active')}
                </Typography>
                <Typography fontSize='12px' color='text.secondary'>
                  {'・'}
                </Typography>
                <Typography fontSize='12px' color='text.secondary' onClick={() => setTabIndex(TabIndex.sync)} sx={{ cursor: 'pointer' }}>
                  {t('features.customerSegments.detail.dailyTagSync')}:{' '}
                  {customerSegment?.tagOperationSetting?.isActive
                    ? t('features.customerSegments.detail.active')
                    : t('features.customerSegments.detail.inactive')}
                </Typography>
                <Typography fontSize='12px' color='text.secondary'>
                  {'・'}
                </Typography>
                <Typography fontSize='12px' color='primary' sx={{ cursor: 'pointer' }} onClick={() => setCollapseOpen(!collapseOpen)}>
                  {collapseOpen ? t('features.customerSegments.detail.hideDetails') : t('features.customerSegments.detail.showDetails')}
                </Typography>
              </Box>
            </Box>
          </Box>

          <Box display='flex' gap='4px'>
            <Tooltip title={t('features.customerSegments.detail.askAi')} placement='top'>
              <span>
                <IconButton
                  size='small'
                  disabled={!customerSegment}
                  onClick={() => {
                    if (shopBillingStatus === ShopBillingStatus.free) {
                      setUpgradeRecommendDialogOpen(true)
                      return
                    }
                    setSuggestInsightDialogOpen(true)
                  }}
                  sx={{ borderRadius: '8px' }}
                >
                  <AutoFixHighOutlinedIcon fontSize='small' />
                </IconButton>
              </span>
            </Tooltip>
            {/* <Tooltip title={t('features.customerSegments.detail.restore')} placement='top'>
              <span>
                <IconButton
                  size='small'
                  disabled={!customerSegment}
                  onClick={() => {
                    if (shopBillingStatus === ShopBillingStatus.free) {
                      setUpgradeRecommendDialogOpen(true)
                      return
                    }
                    setRestoreDialogOpen(true)
                  }}
                  sx={{ borderRadius: '8px' }}
                >
                  <RestoreIcon fontSize='small' />
                </IconButton>
              </span>
            </Tooltip> */}
            <Tooltip title={t('features.customerSegments.detail.menu')} placement='top'>
              <span>
                <IconButton
                  size='small'
                  disabled={!customerSegment}
                  onClick={() => setMenuOpen(true)}
                  ref={menuAnchorRef}
                  sx={{ borderRadius: '8px' }}
                >
                  <MoreHorizIcon fontSize='small' />
                </IconButton>
              </span>
            </Tooltip>
          </Box>
        </Box>

        <Collapse in={collapseOpen}>
          <Paper variant='outlined' sx={{ padding: '16px 32px', marginTop: '16px', borderRadius: '12px' }}>
            {customerSegment && (
              <>
                {customerSegment.description && (
                  <>
                    <Typography fontSize='14px' marginTop='4px' color={(theme) => theme.palette.grey[600]}>
                      {customerSegment.description}
                    </Typography>
                    <Divider sx={{ marginY: '16px' }} />
                  </>
                )}
                <QuerySet querySet={convertQuerySetToForm(convertStringToApiQuerySetState(customerSegment.querySet))} />
              </>
            )}
          </Paper>
        </Collapse>
      </Box>

      <TabContext value={tabIndex}>
        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
          <TabList onChange={(_, v) => setTabIndex(v)} sx={{ minHeight: '40px' }}>
            <Tab
              label={t('features.customerSegments.detail.tabIndexMetrics')}
              icon={<ShowChartOutlinedIcon sx={{ fontSize: '15px' }} />}
              iconPosition='start'
              component={Link}
              to=''
              value={TabIndex.metrics}
              state={location.state ? { from: location.state.from } : null}
              sx={{ minHeight: 0, borderRadius: '4px', '&:hover': { backgroundColor: (theme) => theme.palette.action.hover } }}
            />
            <Tab
              label={t('features.customerSegments.detail.tabIndexActivities')}
              icon={<TextSnippetOutlinedIcon sx={{ fontSize: '15px' }} />}
              iconPosition='start'
              component={Link}
              to={`#${TabIndex.activities}`}
              value={TabIndex.activities}
              state={location.state ? { from: location.state.from } : null}
              sx={{ minHeight: 0, borderRadius: '4px', '&:hover': { backgroundColor: (theme) => theme.palette.action.hover } }}
            />
            <Tab
              label={t('features.customerSegments.detail.tabIndexInsights')}
              icon={<EmojiObjectsOutlinedIcon sx={{ fontSize: '15px' }} />}
              iconPosition='start'
              component={Link}
              to={`#${TabIndex.insights}`}
              value={TabIndex.insights}
              state={location.state ? { from: location.state.from } : null}
              sx={{ minHeight: 0, borderRadius: '4px', '&:hover': { backgroundColor: (theme) => theme.palette.action.hover } }}
            />
            <Tab
              label={t('features.customerSegments.detail.tabIndexCustomers')}
              icon={<Person2OutlinedIcon sx={{ fontSize: '15px' }} />}
              iconPosition='start'
              component={Link}
              to={`#${TabIndex.customers}`}
              value={TabIndex.customers}
              state={location.state ? { from: location.state.from } : null}
              sx={{ minHeight: 0, borderRadius: '4px', '&:hover': { backgroundColor: (theme) => theme.palette.action.hover } }}
            />
            <Tab
              label={t('features.customerSegments.detail.tabIndexSync')}
              icon={<LocalOfferOutlinedIcon sx={{ fontSize: '15px' }} />}
              iconPosition='start'
              component={Link}
              to={`#${TabIndex.sync}`}
              value={TabIndex.sync}
              state={location.state ? { from: location.state.from } : null}
              sx={{ minHeight: 0, borderRadius: '4px', '&:hover': { backgroundColor: (theme) => theme.palette.action.hover } }}
            />
          </TabList>
        </Box>
        <TabPanel value={TabIndex.metrics} sx={{ padding: '24px 12px' }}>
          <Metrics
            customerSegment={customerSegment}
            metricsHistory={metricsHistory}
            loading={loadingMetricsHistory}
            handleTimeRangeChange={(startDate, endDate) => {
              setMetricsStartDate(startDate)
              setMetricsEndDate(endDate)
            }}
          />
        </TabPanel>
        <TabPanel value={TabIndex.activities} sx={{ padding: '24px 12px' }}>
          <Activities
            customerSegment={customerSegment}
            activities={activities}
            loading={loadingActivities}
            handleTimeRangeChange={(startDate, endDate) => {
              setActivitiesStartDate(startDate)
              setActivitiesEndDate(endDate)
            }}
          />
        </TabPanel>
        <TabPanel value={TabIndex.insights} sx={{ padding: '24px 12px' }}>
          <Insights
            customerSegment={customerSegment}
            productInsightValues={productInsightValues}
            customerTagInsightValues={customerTagInsightValues}
            recencyInsightValue={recencyInsightValue}
            overlapCustomerSegments={overlapCustomerSegments}
            loading={loadingInsights}
          />
        </TabPanel>
        <TabPanel value={TabIndex.customers} sx={{ padding: '24px 12px' }}>
          <Customers customerSegment={customerSegment} customers={customers} loading={loadingCustomers} />
        </TabPanel>
        <TabPanel value={TabIndex.sync} sx={{ padding: '24px 12px' }}>
          <Sync customerSegment={customerSegment} currentTagRunning={currentTagRunning} otherTagsRunning={otherTagsRunning} />
        </TabPanel>
      </TabContext>

      {customerSegment && (
        <SuggestInsightDialog
          open={suggestInsightDialogOpen}
          handleClose={() => setSuggestInsightDialogOpen(false)}
          segmentId={customerSegment.id}
          segmentName={customerSegment.name}
        />
      )}

      {customerSegment && (
        <DetailSegmentMenu
          open={menuOpen}
          handleClose={() => setMenuOpen(false)}
          anchorEl={menuAnchorRef.current}
          customerSegment={customerSegment}
        />
      )}

      {upgradeRecommendDialogOpen && (
        <UpgradeRecommendDialog
          open={upgradeRecommendDialogOpen}
          handleClose={() => setUpgradeRecommendDialogOpen(false)}
          referrer='customerSegmentOverview'
        />
      )}
    </>
  )
}
