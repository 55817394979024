import dayjs from 'dayjs'
import { getIdToken } from 'firebase/auth'
import { CustomerSegmentService } from 'gen/proto/customer_segment/customer_segment_connectweb'
import { InsightService } from 'gen/proto/insight/insight_connectweb'
import { useAuthUser } from 'hooks/useAuthUser'
import { useCustomSnackbar } from 'hooks/useCustomSnackbar'
import { useGrpcClient } from 'hooks/useGrpcClient'
import { useSentryNotifier } from 'hooks/useSentryNotifier'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { formatDate } from 'utils/timeUtil'
import { PopularityInsightValue, HistogramInsightValue, OverlapCustomerSegment } from '../types/types'

export const useInsights = (customerSegmentId: string | undefined) => {
  const { t } = useTranslation()
  const authUser = useAuthUser()
  const { enqueueSnackbar } = useCustomSnackbar()
  const { notifySentry } = useSentryNotifier()

  const insightService = useGrpcClient(InsightService)
  const customerSegmentService = useGrpcClient(CustomerSegmentService)

  const [productInsightValues, setProductInsightValues] = useState<PopularityInsightValue[]>()
  const [customerTagInsightValues, setCustomerTagInsightValues] = useState<PopularityInsightValue[]>()
  const [recencyInsightValue, setRecencyInsightValue] = useState<HistogramInsightValue>()
  const [overlapCustomerSegments, setOverlapCustomerSegments] = useState<OverlapCustomerSegment[]>()

  const [loading, setLoading] = useState<boolean>(false)

  const addIndexToName = (name: string, index: number) => {
    return index === 0 ? `🥇 ${name}` : index === 1 ? `🥈 ${name}` : index === 2 ? `🥉 ${name}` : name
  }

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true)
      if (!customerSegmentId) return
      try {
        const token = await getIdToken(authUser!)

        const [productResp, customerTagResp, recencyResp, overlapResp] = await Promise.all([
          insightService.getPopularityReport(
            {
              customerSegmentId,
              dimension: 'product',
              startDate: formatDate(dayjs().subtract(12, 'months')), // 1 year
              endDate: formatDate(dayjs()),
            },
            { headers: { Authorization: `Bearer ${token}` } }
          ),
          insightService.getPopularityReport(
            {
              customerSegmentId,
              dimension: 'customer_tag',
              startDate: formatDate(dayjs().subtract(12, 'months')), // 1 year
              endDate: formatDate(dayjs()),
            },
            { headers: { Authorization: `Bearer ${token}` } }
          ),
          insightService.getHistogramReport(
            {
              customerSegmentId,
              dimension: 'recency',
              startDate: formatDate(dayjs().subtract(24, 'months')), // 2 years
              endDate: formatDate(dayjs()),
            },
            { headers: { Authorization: `Bearer ${token}` } }
          ),
          customerSegmentService.getOverlapCustomerSegments({ customerSegmentId }, { headers: { Authorization: `Bearer ${token}` } }),
        ])

        setProductInsightValues(
          productResp.values.slice(0, 3).map((v, i) => ({
            name: addIndexToName(v.name, i),
            customerCount: Number(v.customerCount),
            ratio: Number((v.ratio * 100).toFixed(1)),
          }))
        )

        setCustomerTagInsightValues(
          customerTagResp.values.slice(0, 3).map((v, i) => ({
            name: addIndexToName(v.name, i),
            customerCount: Number(v.customerCount),
            ratio: Number((v.ratio * 100).toFixed(1)),
          }))
        )

        setRecencyInsightValue({
          values: recencyResp.values.map((v) => ({
            min: Number(v.min),
            max: Number(v.max),
            customerCount: Number(v.customerCount),
          })),
          mean: Number(recencyResp.mean),
          median: Number(recencyResp.median),
          max: Number(recencyResp.max),
        })

        setOverlapCustomerSegments(
          overlapResp.customerSegments.slice(0, 6).map((v) => ({
            id: v.id,
            name: v.name,
            customerCount: Number(v.customerCount),
            overlapRatio: Number((v.overlapRatio * 100).toFixed(1)),
          }))
        )
      } catch (err) {
        enqueueSnackbar(t('common.messageDataFetchError'), { severity: 'error' })
        notifySentry(err)
      } finally {
        setLoading(false)
      }
    }
    fetchData()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [customerSegmentId])

  return { productInsightValues, customerTagInsightValues, recencyInsightValue, overlapCustomerSegments, loading }
}
